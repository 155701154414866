/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useEffect } from 'react';

import { FiCheckSquare } from 'react-icons/fi';

import { useToast } from '../../../hooks/toast';

import api from '../../../services/api';
import { ButtonGroup } from '../../../styles/customButtonGroup';

import Modal from '../../Modal';

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MaskedInput } from '../../Material/MaskedInput';
import MuiSelect from '../../Material/Select';
import { SaldoProps } from '../../../pages/PainelSaldos';
import { VinculosPedidosProps } from '../../../pages/PainelVinculoPedidos';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  onClose: () => void;
}

interface IFormData {
  pedido: number;
  transportadora: string;
  tipo: string;
  desvincular: string;
  volume: number;
  ativo: boolean;
}

type VinculoPedido = {
  pedido: number;
  transportadora: string;
  tipo: string;
  volume: number;
  ativo: boolean;
};

const ModalVinculoPedidosComVolume = React.forwardRef<
  HTMLInputElement,
  IModalProps
>(({ isOpen, setIsOpen, onClose }, ref) => {
  const { addToast } = useToast();

  const theme = useTheme();
  const navigate = useNavigate();

  const [saldoPedidos, setSaldoPedidos] = useState<SaldoProps[]>([]);
  const [vinculosPedido, setVinculoPedidos] = useState<VinculosPedidosProps>();

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormData>();

  const selectTipoDocumento = ['1 - Pedido', '2 - F.A'];

  const [selectedOption, setSelectedOption] = useState(selectTipoDocumento[0]);

  const [shouldDeleteVinculo, setShouldDeleteVinculo] =
    useState<boolean>(false);

  function handlechange() {
    if (shouldDeleteVinculo === false) {
      setShouldDeleteVinculo(true);
      setValue('desvincular', 'true');
    }

    if (shouldDeleteVinculo === true) {
      setShouldDeleteVinculo(false);
      setValue('desvincular', 'false');
    }
  }

  useEffect(() => {
    async function loadSaldoPedidos(): Promise<void> {
      await api
        .get('/agendamentos/saldoAllPedidos')
        .then(response => {
          setSaldoPedidos(response.data);
        })
        .catch((error: any) => {
          addToast({
            type: 'error',
            title: 'Erro get saldoAllPedidos.',
            description: `ModalVinculoPedidosComVolume - ${error}`,
          });
        });
    }

    loadSaldoPedidos();
  }, []);

  const filterSaldoPedido = async (pedido: number) => {
    const saldoPedido = saldoPedidos.filter(
      saldo => saldo.pedido === Number(pedido),
    );

    if (saldoPedido.length === 0) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Pedido não encontrado ou sem saldo.',
      });
      setIsOpen();
      return;
    }

    await api
      .get('/vinculopedidos', {
        params: {
          pedido: pedido,
        },
      })
      .then(response => {
        const volumeVinculado = response.data.reduce((total, item) => {
          return total + item.volume;
        }, 0);

        // const saldoCalculado = saldoPedido[0].saldoCalculado;
        const saldoOrder = saldoPedido[0].saldoOrder;

        const volumeAVincular = saldoPedido[0].quantidade - volumeVinculado;

        const vinculoPedido = {
          pedido: pedido,
          saldoPedido: saldoOrder,
          volumeVinculado: Number(volumeVinculado),
          volumeVincular: volumeAVincular,
          vinculos: [...response.data] as VinculoPedido[],
        };

        setVinculoPedidos(vinculoPedido as VinculosPedidosProps);
      })
      .catch((error: any) => {
        addToast({
          type: 'error',
          title: 'Erro get vinculoPedidos.',
          description: error,
        });
      });

    return saldoPedido;
  };

  const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
    try {
      if (vinculosPedido && data.volume > vinculosPedido?.volumeVincular) {
        addToast({
          type: 'error',
          title: 'Erro ao vincular Pedido',
          description: 'Volume maximo permitido ultrapassado',
        });
        return;
      }

      const trimmedTransportadora = data.transportadora.trim();
      // POST PARA INSERIR VINCULO PEDIDO COM TRANSPORTADORA
      if (data.desvincular === 'false' || data.desvincular === undefined) {
        await api.post('/vinculopedidos', {
          pedido: data.pedido,
          transportadora: trimmedTransportadora,
          tipo: data.tipo === '1 - Pedido' ? 'P' : 'F',
          volume: data.volume,
          ativo: true,
        });

        // Close the modal
        setIsOpen();

        // Change selectedOption to 0
        setSelectedOption(selectedOption[0]);
        navigate('/vinculopedidos');

        // Callback to refresh vinculos
        onClose();

        // Show Toast for success
        addToast({
          type: 'success',
          title: 'Pedido/F.A vinculado com sucesso.',
          description: ` ${
            data.tipo === '1 - Pedido' ? 'O pedido' : ' A F.A'
          } ${data.pedido} foi vinculado a transportadora ${
            data.transportadora
          }`,
        });
      }

      if (data.desvincular === 'true') {
        // DELETE VINCULO PEDIDO TRANSPORTADORA
        await api.delete('/vinculopedidos', {
          params: {
            transportadora: trimmedTransportadora,
            documento: data.pedido,
          },
        });

        // Close the modal
        setIsOpen();
        setShouldDeleteVinculo(false);

        // Change selectedOption to 0
        setSelectedOption(selectedOption[0]);

        // Show Toast for success
        addToast({
          type: 'success',
          title: 'Pedido/F.A desvinculado com sucesso.',
          description: ` O documento ${data.pedido} foi desvinculado da transportadora ${data.transportadora}`,
        });

        // reset form
        reset();

        navigate('/vinculopedidos');
        return;
      }
      // reset form
      reset();
    } catch (err: any) {
      // Close the modal
      setIsOpen();

      // Show Toast for failure
      addToast({
        type: 'error',
        title: 'Erro ao vincular Pedido',
        description: `${err.response.data.message}`,
      });

      // Return Select to Zero position
      setSelectedOption[0];

      // reset form
      reset();
    }
  };

  const handleCloseModal = () => {
    // Reset form
    reset();

    // Set vinculos pedidos to empty
    setVinculoPedidos({} as VinculosPedidosProps);
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onCloseModal={() => handleCloseModal()}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
          gap: '1rem',
        }}
      >
        <h1
          style={{
            fontWeight: '500',
            fontSize: '36px',
            lineHeight: '36px',
            marginBottom: '40px',
            color: '#00a859',
            textAlign: 'center',
          }}
        >
          Gerenciar Vinculo Pedidos
        </h1>

        <MaskedInput
          {...register('pedido', { required: 'Pedido obrigatório.' })}
          control={control}
          type="none"
          name="pedido"
          label="Pedido / F.A"
          onBlur={e => filterSaldoPedido(e.currentTarget.value)}
          error={!!errors.pedido}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MaskedInput
          {...register('transportadora', {
            required: 'Transportadora obrigatória.',
          })}
          control={control}
          type="cnpjcpf"
          name="transportadora"
          label="CNPJ/CPF"
          onBlur={() => {}}
          error={!!errors.transportadora}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MaskedInput
          {...register('volume', {
            required: 'Volume obrigatório.',
          })}
          control={control}
          type="volume"
          name="volume"
          label="Volume Max."
          onBlur={() => {}}
          error={!!errors.volume}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        {vinculosPedido && vinculosPedido.volumeVincular >= 0 && (
          <Box
            sx={{
              display: 'flex',
              width: '237px',
              marginTop: -2,
              paddingTop: 0,
              justifyContent: 'space-between',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <Typography
              sx={{ color: theme.palette.primary.main, fontSize: 14 }}
            >
              Volume max disponível:
            </Typography>
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: 14,
              }}
            >
              {new Intl.NumberFormat('pt-BR').format(
                vinculosPedido?.volumeVincular || 0,
              )}
            </Typography>
          </Box>
        )}

        <MuiSelect
          {...register(
            'tipo',
            shouldDeleteVinculo
              ? { required: false }
              : {
                  required: 'Tipo obrigatório.',
                },
          )}
          control={control}
          label="Tipo Documento"
          name="tipo"
          options={selectTipoDocumento}
          variant="outlined"
          sx={{ width: '237px' }}
          ref={ref}
          error={!!errors.tipo}
          disabled={shouldDeleteVinculo}
        />

        <Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  value={shouldDeleteVinculo}
                  checked={shouldDeleteVinculo}
                  onChange={handlechange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{
                    '&.Mui-checked': {
                      color: theme.palette.accents.red.main,
                    },
                  }}
                />
              }
              label="Desvincular Pedido?"
            />
          </FormGroup>
        </Box>

        <MaskedInput
          {...register('desvincular')}
          control={control}
          type="none"
          name="desvincular"
          label=""
          onBlur={() => {}}
          error={!!errors.desvincular}
          sx={{ display: 'none' }}
          value={shouldDeleteVinculo ? 'true' : 'false'}
        />

        <ButtonGroup>
          <button type="submit" name="SaveButton">
            <div className="text">Salvar</div>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>
        </ButtonGroup>
      </form>
    </Modal>
  );
});

ModalVinculoPedidosComVolume.displayName = 'ModalVinculoPedidosComVolume';

export default ModalVinculoPedidosComVolume;
