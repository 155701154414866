/* eslint-disable no-nested-ternary */
import React, { ReactElement, useEffect, useState } from 'react';

import format from 'date-fns/format';
import { parseISO, isAfter, isBefore } from 'date-fns';
import { Container, TableContainer } from './styles';

import api from '../../services/api';
// import formatDate from '../../utils/formatDate';
import moment from 'moment';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

interface Fila {
  filaid: number;
  produto: number;
  data: Date;
  placa: string;
  placa2: string;
  cpf: string;
  motorista: string;
  volume: number;
  status: string;
  spot: number;
  agendamentoid: number;
}

function Fila(): ReactElement {
  const [filaEtanol, setFilaEtanol] = useState<Fila[]>([]);

  const filaDate = new Date(Date.now());
  const formattedDate = moment.utc(Date.now()).format('YYYY-MM-DD');

  const horaInicioEtanol = parseISO(`${formattedDate} 07:15:00`);
  const horaInicioEtanolFormatted = parseISO(
    format(horaInicioEtanol, 'yyyy-MM-dd HH:mm:ss'),
  );
  const horaFimEtanol = parseISO(`${formattedDate} 14:30:00`);
  const horaFimEtanolFormatted = parseISO(
    format(horaFimEtanol, 'yyyy-MM-dd HH:mm:ss'),
  );
  const horaAtual = parseISO(format(filaDate, 'yyyy-MM-dd HH:mm:ss'));

  const group = 'Etanol';

  const [timeLeft, setTimeLeft] = useState(25);

  useEffect(() => {
    async function loadFila(): Promise<void> {
      await api.get(`/fila/byprodutogroup/${group}`).then(response => {
        setFilaEtanol(response.data);
      });
    }
    loadFila();
  }, [formattedDate]);

  // FILA BAGAÇO
  // eslint-disable-next-line func-names
  // setInterval(function () {
  //   // window.location.replace('/filaoutros');
  //   window.location.replace('/filavhp');
  // }, 25000);

  const etanolCarregando = filaEtanol.filter(
    veiculosInFila =>
      veiculosInFila.status === 'PCAR' || veiculosInFila.status === 'ELIB',
  );

  // eslint-disable-next-line no-console
  // console.log(
  //   'Total Etanol em processo de carregamento =',
  //   etanolCarregando.length,
  // );

  // Verifica quantos veiculos estão no carregamento de Etanol
  if (
    isAfter(horaAtual, horaInicioEtanolFormatted) === true &&
    isBefore(horaAtual, horaFimEtanolFormatted) === true &&
    etanolCarregando.length < 5
  ) {
    const minPosition = Math.min(...filaEtanol.map(filaItem => filaItem.spot));
    const [getNextInLineEtanol] = filaEtanol.filter(
      nextInLine =>
        nextInLine.status === 'AENT' && nextInLine.spot >= minPosition,
    );

    if (getNextInLineEtanol) {
      api.post('fila/status', {
        data: getNextInLineEtanol.data,
        agendamentoid: getNextInLineEtanol.agendamentoid,
        placa: getNextInLineEtanol.placa,
        statusnew: 'ELIB',
        motivo: 'Liberação de entrada Etanol, pelo número de vagas < 5.',
      });
    }
  }

  const sortedFilaEtanol = filaEtanol
    .filter(
      produto =>
        produto.produto === 1 || produto.produto === 2 || produto.produto === 5,
    )
    .sort((a, b) => {
      const formatDateA = moment(a.data).format('YYYY-MM-DD');
      const formatDateB = moment(b.data).format('YYYY-MM-DD');
      // First, compare the dates
      if (formatDateA > formatDateB) {
        return 1;
      } else if (formatDateA < formatDateB) {
        return -1;
      } else {
        // If dates are the same, compare the spots
        // if (a.spot > b.spot) {
        //   return 1;
        // } else if (a.spot < b.spot) {
        //   return -1;
        // } else {
        //   return 0;
        // }

        // If dates are the same, compare the filaid
        if (a.filaid > b.filaid) {
          return 1;
        } else if (a.filaid < b.filaid) {
          return -1;
        } else {
          return 1;
        }
      }
    });

  useEffect(() => {
    // Set timeout based on the length of sortedFilaEtanol
    let timeout: NodeJS.Timeout;

    if (sortedFilaEtanol === undefined || sortedFilaEtanol.length === 0) {
      setTimeLeft(5); // Shorter timeout if there are no items in the queue
      timeout = setTimeout(() => {
        window.location.replace('/filavhp');
      }, 5000); // 5 seconds timeout
    } else {
      setTimeLeft(25); // Default timeout if there are items
      timeout = setTimeout(() => {
        window.location.replace('/filavhp');
      }, 25000); // 25 seconds timeout
    }

    // Cleanup the timeout when the component unmounts or filaOutros changes
    return () => clearTimeout(timeout);
  }, [sortedFilaEtanol]);

  return (
    <Container>
      <header>
        <h1>Fila Carregamento - Etanol</h1>
      </header>

      {/* Table */}
      <TableContainer>
        <table id="tableDataRegistries">
          {/* This header are being used for displaying header on the report */}
          <thead className="headTable">
            <tr>
              {/* <th>Fila</th> */}
              <th>Fila Id</th>
              <th>Agend/Certif</th>
              <th>Produto</th>
              <th>Data</th>
              <th>Placa Carreta</th>
              <th>Placa Carreta 2</th>
              <th>Nome Motorista</th>
              <th>Volume Total</th>
              <th>Status</th>
            </tr>
          </thead>

          {sortedFilaEtanol.map(filaData => (
            <tbody key={filaData.filaid}>
              <tr>
                {/* Spot */}
                {/* <td>{filaData.spot}</td> */}
                {/* Fila Id */}
                <td>{filaData.filaid}</td>
                {/* Agendamento id */}
                <td>{filaData.agendamentoid}</td>
                <td>
                  {filaData.produto === 1
                    ? 'Hidratado'
                    : filaData.produto === 2
                    ? 'Etanol - Outros Fins'
                    : filaData.produto === 3
                    ? 'Bagaço'
                    : filaData.produto === 4
                    ? 'Açúcar VHP'
                    : filaData.produto === 5
                    ? 'Anidro'
                    : 'Error'}
                </td>
                {/* <td>{formatDate(new Date(filaData.data))}</td> */}
                <td>{moment.utc(filaData.data).format('DD/MM/YYYY')}</td>
                {/* <td>{moment(formattedDate).format('DD/MM/YYYY')}</td> */}
                <td>{filaData.placa}</td>
                <td>{filaData.placa2}</td>
                <td>{filaData.motorista}</td>
                <td>{filaData.volume}</td>
                <td
                  style={
                    filaData.status === 'AGPE'
                      ? { color: 'red' }
                      : filaData.status === 'APPE'
                      ? { color: 'red' }
                      : { color: '#171412' }
                  }
                >
                  {filaData.status === 'AGPE'
                    ? 'Aguardando Pedido'
                    : filaData.status === 'APPE'
                    ? 'Aguardando Pag. Pedido'
                    : filaData.status === 'ACKL'
                    ? 'Aguardando Checklist'
                    : filaData.status === 'AENT'
                    ? 'Aguardando Liberação Entrada'
                    : filaData.status === 'ELIB'
                    ? 'Pesagem Liberada'
                    : filaData.status === 'PCAR'
                    ? 'Processo Carregamento'
                    : filaData.status === 'AFAT'
                    ? 'Aguard. Faturamento'
                    : filaData.status === 'ADOC'
                    ? 'Aguard. Docs Transportadora'
                    : filaData.status === 'NLIB'
                    ? 'Nota Fiscal Liberada Coleta'
                    : filaData.status === 'CAFI'
                    ? 'Carregamento Finalizado'
                    : 'Erro'}
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </TableContainer>
      <div className="autoplay-progress" slot="container-end">
        <CountdownCircleTimer
          isPlaying
          duration={timeLeft}
          colors={['#00a859', '#F7B801', '#A30000', '#A30000']}
          colorsTime={[timeLeft, 5, 2, 0]}
          size={48}
          strokeWidth={5}
          trailStrokeWidth={8}
        >
          {({ remainingTime }) => remainingTime}
        </CountdownCircleTimer>
      </div>
    </Container>
  );
}

export default Fila;
