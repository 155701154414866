import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import signInBackgroundImg from '../../assets/bsa10.jpg';

export const Container = styled.div`
  height: 100vh;
  background: #171412;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 700px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromLeft} 1s;

  #backToHome {
    color: #00a859;
    display: block;
    margin-top: 80px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${shade(0.5, '#00a859')};
    }
  }

  img {
    background: transparent;
    width: 300px;
    height: 240px;
    margin-top: -40px;
  }

  form {
    margin-top: 40px;
    width: 340px;
    text-align: center;

    /* The input on the form */
    div {
      align-items: center;
    }

    h1 {
      margin-bottom: 24px;
      color: #00a859;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signInBackgroundImg}) no-repeat center #fff;
  background-size: cover;

  a {
    color: #666360;
    font-size: 9px;
  }

  span {
    color: #666360;
    font-size: 9px;
    margin-left: 2px;
  }
`;
