/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { ReactElement, useEffect, useState } from 'react';

import format from 'date-fns/format';
import { Container, ContainerHolder, TableContainer } from './styles';

import api from '../../services/api';
import { formatDateBr } from '../../utils/formatDate';
import moment from 'moment';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

interface Fila {
  filaid: number;
  produto: number;
  data: Date;
  placa: string;
  placa2: string;
  cpf: string;
  motorista: string;
  volume: number;
  status: string;
  spot: number;
  agendamentoid: number;
}

function FilaVhp(): ReactElement {
  const [filaOutros, setFilaOutros] = useState<Fila[]>([]);

  const filaDate = new Date(Date.now());
  const formattedDate = format(new Date(filaDate), 'yyyy-MM-dd');
  const group = 'Outros';

  const [timeLeft, setTimeLeft] = useState(25);

  useEffect(() => {
    async function loadFila(): Promise<void> {
      await api.get(`/fila/byprodutogroup/${group}`).then(response => {
        setFilaOutros(response.data);
      });
    }
    loadFila();
  }, [formattedDate]);

  const sortedFilaVhp = filaOutros
    .filter(produto => produto.produto === 4)
    .sort((a, b) => {
      const formatDateA = moment(a.data).format('YYYY-MM-DD');
      const formatDateB = moment(b.data).format('YYYY-MM-DD');

      // First, compare the dates
      if (formatDateA > formatDateB) {
        return 1;
      } else if (formatDateA < formatDateB) {
        return -1;
      } else {
        // If dates are the same, compare the spots
        // if (a.spot > b.spot) {
        //   return 1;
        // } else if (a.spot < b.spot) {
        //   return -1;
        // } else {
        //   return 1;
        // }

        // If dates are the same, compare the filaid
        if (a.filaid > b.filaid) {
          return 1;
        } else if (a.filaid < b.filaid) {
          return -1;
        } else {
          return 1;
        }
      }
    });

  useEffect(() => {
    // Set timeout based on the length of sortedFilaVhp
    let timeout: NodeJS.Timeout;

    if (sortedFilaVhp === undefined || sortedFilaVhp.length === 0) {
      setTimeLeft(5); // Shorter timeout if there are no items in the queue
      timeout = setTimeout(() => {
        window.location.replace('/filabagaco');
      }, 5000); // 5 seconds timeout
    } else {
      setTimeLeft(25); // Default timeout if there are items
      timeout = setTimeout(() => {
        window.location.replace('/filabagaco');
      }, 25000); // 25 seconds timeout
    }

    // Cleanup the timeout when the component unmounts or filaOutros changes
    return () => clearTimeout(timeout);
  }, [sortedFilaVhp]);

  const vhpCarregando = filaOutros.filter(
    veiculosInFila =>
      (veiculosInFila.status === 'PCAR' || veiculosInFila.status === 'ELIB') &&
      veiculosInFila.produto === 4,
  );

  // console.log('VHP - carregando', vhpCarregando.length);

  // Verificar quantos veículos estão no carregamento de VHP
  if (vhpCarregando.length < 3) {
    const getNextInLineVhp = filaOutros.find(
      nextInLine => nextInLine.status === 'AENT' && nextInLine.produto === 4,
    );

    if (getNextInLineVhp) {
      api.post('fila/status', {
        data: getNextInLineVhp.data,
        agendamentoid: getNextInLineVhp.agendamentoid,
        placa: getNextInLineVhp.placa,
        statusnew: 'ELIB',
        motivo: 'Liberação de entrada VHP pelo número de vagas < 3.',
      });
    }
  }

  return (
    <ContainerHolder>
      <Container>
        <header>
          <h1>Fila Carregamento - VHP</h1>
        </header>

        {/* Table */}
        <TableContainer>
          <table id="tableDataRegistries">
            {/* This header are being used for displaying header on the report */}
            <thead className="headTable">
              <tr>
                <th>Fila ID</th>
                <th>Agend/Certif</th>
                <th>Prod.</th>
                <th>Data</th>
                <th>Placa Carreta</th>
                <th>Nome Motorista</th>
                <th>Status</th>
              </tr>
            </thead>

            {sortedFilaVhp
              .filter(fila => fila.produto === 4)
              .map(filaData => (
                <tbody key={filaData.filaid}>
                  <tr>
                    <td>{filaData.filaid}</td>
                    <td>{filaData.agendamentoid}</td>
                    <td>
                      {filaData.produto === 3
                        ? 'Bagaço'
                        : filaData.produto === 4
                        ? 'VHP'
                        : 'Error'}
                    </td>
                    <td>{formatDateBr(filaData.data)}</td>
                    <td>{filaData.placa}</td>
                    <td>{filaData.motorista}</td>
                    <td
                      style={
                        filaData.status === 'AGPE'
                          ? { color: 'red' }
                          : filaData.status === 'APPE'
                          ? { color: 'red' }
                          : { color: '#171412' }
                      }
                    >
                      {filaData.status === 'AGPE'
                        ? 'Aguardando Pedido'
                        : filaData.status === 'APPE'
                        ? 'Aguardando Pag. Pedido'
                        : filaData.status === 'ACKL'
                        ? 'Aguardando Checklist'
                        : filaData.status === 'AENT'
                        ? 'Aguardando Liberação Entrada'
                        : filaData.status === 'ELIB'
                        ? 'Pesagem Liberada'
                        : filaData.status === 'PCAR'
                        ? 'Processo Carregamento'
                        : filaData.status === 'AFAT'
                        ? 'Aguard. Faturamento'
                        : filaData.status === 'ADOC'
                        ? 'Aguard. Docs Transportadora'
                        : filaData.status === 'NLIB'
                        ? 'Nota Fiscal Liberada Coleta'
                        : filaData.status === 'CAFI'
                        ? 'Carregamento Finalizado'
                        : 'Erro'}
                    </td>
                  </tr>
                </tbody>
              ))}
          </table>
        </TableContainer>
      </Container>
      <div className="autoplay-progress" slot="container-end">
        <CountdownCircleTimer
          isPlaying
          duration={timeLeft}
          colors={['#00a859', '#F7B801', '#A30000', '#A30000']}
          colorsTime={[timeLeft, 5, 2, 0]}
          size={48}
          strokeWidth={5}
          trailStrokeWidth={8}
        >
          {({ remainingTime }) => remainingTime}
        </CountdownCircleTimer>
      </div>
    </ContainerHolder>
  );
}

export default FilaVhp;
