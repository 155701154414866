import React, { ReactElement } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Container } from './styles';

import logotamboril from '../../assets/logotamboril.jpg';
import logo200 from '../../assets/logo200.png';

function AboutUs(): ReactElement {
  const navigate = useNavigate();

  return (
    <Container>
      <header>
        <h1>Bom Sucesso Agroindústria S.A</h1>

        <nav>
          <button type="submit" onClick={() => navigate(-1)}>
            Página Inicial
          </button>
        </nav>
      </header>
      <h2 id="pageTitle">Quem Somos</h2>
      <div className="aboutUsText">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>

        <p>
          At vero eos et accusamus et iusto odio dignissimos ducimus qui
          blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
          et quas molestias excepturi sint occaecati cupiditate non provident,
          similique sunt in culpa qui officia deserunt mollitia animi, id est
          laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita
          distinctio. Nam libero tempore, cum soluta nobis est eligendi optio
          cumque nihil impedit quo minus id quod maxime placeat facere possimus,
          omnis voluptas assumenda est, omnis dolor repellendus. Temporibus
          autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe
          eveniet ut et voluptates repudiandae sint et molestiae non recusandae.
          Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis
          voluptatibus maiores alias consequatur aut perferendis doloribus
          asperiores repellat.
        </p>
      </div>
      <div className="logosHolder">
        <img src={logo200} alt="Bom Successo" />
        <img src={logotamboril} alt="Tamboril" />
      </div>
      <div className="companyValues">
        <div className="card" data-aos="zoom-in">
          <div className="cardItems">
            <p id="type">
              <b>Nossa Missão</b>
            </p>
            <p id="description">
              Investir na expansão tecnológica agrícola visando um crescimento
              na produtividade e manejo.
            </p>
          </div>
        </div>

        <div className="card" data-aos="zoom-in" data-aos-delay={400}>
          <div className="cardItems">
            <p id="type">
              <b>Nossos Valores</b>
            </p>
            <p id="description">
              Investir na expansão tecnológica agrícola visando um crescimento
              na produtividade e manejo.
            </p>
          </div>
        </div>

        <div className="card" data-aos="zoom-in" data-aos-delay={800}>
          <div className="cardItems">
            <p id="type">
              <b>Meta</b>
            </p>
            <p id="description">
              Investir na expansão tecnológica agrícola visando um crescimento
              na produtividade e manejo.
            </p>
          </div>
        </div>
      </div>{' '}
      {/* End Company Values */}
      <div className="workWithUs">
        <h2>Faça parte da nossa equipe</h2>

        <div className="buttonHolder">
          {/* <button type="submit" onClick={() => moveTo(1, 4)}>
            Verificar Vagas em Aberto
          </button> */}
          <Link to="/#sectionJobs">Verificar Vagas em Aberto</Link>
        </div>
      </div>
    </Container>
  );
}

// AOS.init({
//   duration: 1200,
// });

export default AboutUs;
