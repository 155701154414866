import React, { ReactElement, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { FaTruck, FaUsers } from 'react-icons/fa';
import { PageTitle, CardContainer, Card } from './styles';
import Modal from '../Modal';
import { RiForbid2Line, RiUserAddLine } from 'react-icons/ri';
import ModalCarregamentoFds from '../ModalCarregamentoFds';
import ModalInsertTransportadora from '../ModalInsertTransportadoras';
import ModalInsertUsers from '../ModalInsertUsers';
import { AiOutlineOrderedList } from 'react-icons/ai';
import ModalCadastraSpots from '../ModalCadastraSpots';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

function ModalCadastros({ isOpen, setIsOpen }: IModalProps): ReactElement {
  const navigate = useNavigate();
  const [carregamentoFdsModalOpen, setCarregamentoFdsModalOpen] =
    useState(false);
  const [insertTransportadorasModalOpen, setInsertTransportadorasModalOpen] =
    useState(false);
  const [insertUsersModalOpen, setInsertUsersModalOpen] = useState(false);
  const [insertVagasModalOpen, setInsertVagasModalOpen] = useState(false);

  // Toogle modal Insert Carregamento Fds
  function toogleCarregamentoFds(): void {
    setCarregamentoFdsModalOpen(!carregamentoFdsModalOpen);
  }

  // Toogle modal Insert Transportadoras
  function toogleInsertTransportadoras(): void {
    setInsertTransportadorasModalOpen(!insertTransportadorasModalOpen);
  }

  // Toogle modal insert User
  function toogleInsertUsersModal(): void {
    setInsertUsersModalOpen(!insertUsersModalOpen);
  }

  // Toogle modal Insert Vagas Carregamento
  function toogleInsertVagasModal(): void {
    setInsertVagasModalOpen(!insertVagasModalOpen);
  }

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <ModalInsertUsers
        isOpen={insertUsersModalOpen}
        setIsOpen={() => toogleInsertUsersModal()}
      />

      <ModalInsertTransportadora
        isOpen={insertTransportadorasModalOpen}
        setIsOpen={() => toogleInsertTransportadoras()}
      />

      <ModalCarregamentoFds
        isOpen={carregamentoFdsModalOpen}
        setIsOpen={() => toogleCarregamentoFds()}
      />

      <ModalCadastraSpots
        isOpen={insertVagasModalOpen}
        setIsOpen={() => toogleInsertVagasModal()}
      />

      <PageTitle>Cadastros</PageTitle>

      <CardContainer>
        {/* Cadastro Usuarios */}
        <Card onClick={() => toogleInsertUsersModal()}>
          <h1
            className="createUser"
            id="cadCreateUser"
            data-testid="createUser"
          >
            Usuários
          </h1>
          <RiUserAddLine size={42} color="#fff" />
        </Card>

        {/* Cadastro Transportadora */}
        <Card onClick={() => toogleInsertTransportadoras()}>
          <h1
            className="createTransportadora"
            id="cadTransportadora"
            data-testid="createTransportadora"
          >
            Transportadora
          </h1>
          <FaTruck size={38} color="#fff" />
        </Card>

        {/* Cadastro Motoristas */}
        <Card onClick={() => navigate('/motoristas')}>
          <h1
            className="goMotoristas"
            id="goMotoristas"
            data-testid="goMotoristas"
          >
            Motoristas
          </h1>
          <FaUsers size={38} color="#fff" />
        </Card>

        {/* Cadastro Veiculos */}
        <Card onClick={() => navigate('/veiculos')}>
          <h1 className="goVeiculos" id="goVeiculos" data-testid="goVeiculos">
            Veículos
          </h1>
          <FaTruck size={38} color="#fff" />
        </Card>

        {/* Cadastro Carregamento Fds */}
        <Card onClick={() => toogleCarregamentoFds()}>
          <h1
            className="createcarregamentofds"
            id="cadCarregamentoFds"
            data-testid="cadCarregamentoFds"
          >
            Carregamento FDS Etanol
          </h1>
          <RiForbid2Line size={42} color="#fff" />
        </Card>

        {/* Cadastro Vagas Carregamento */}
        <Card onClick={() => toogleInsertVagasModal()}>
          <h1
            className="createvagascarregamento"
            id="cadVagasCarregamento"
            data-testid="cadVagasCarregamento"
          >
            Vagas
          </h1>
          <AiOutlineOrderedList size={42} color="#fff" />
        </Card>
      </CardContainer>
    </Modal>
  );
}

export default ModalCadastros;
