import React, { ReactElement, useEffect, useState } from 'react';
import { Container, TableContainer } from './styles';

import api from '../../services/api';
import moment from 'moment';

import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from '@mui/material';

interface MonitorCarregamentoFila {
  data: Date;
  filaid: number;
  produto: number;
  placa: string;
  compartimento: number;
  motorista: string;
  senhacarregamento: number;
  volumecarregar: number;
  tara: number;
}

function FilaMonitorCarregamento(): ReactElement {
  let produto = '';
  const [loading, setLoading] = useState<boolean>(false);
  const [filaData, setFilaData] = useState<MonitorCarregamentoFila[]>([]);
  const [countdown, setCountdown] = useState<number>(0);
  const pollingInterval = 60000; // 60 seconds

  const path = window.location.pathname;

  if (path === '/monitorcarregamento-etanol') {
    produto = 'etanol';
  } else if (path === '/monitorcarregamento-vhp') {
    produto = 'vhp';
  } else if (path === '/monitorcarregamento-bagaco') {
    produto = 'bagaco';
  }

  useEffect(() => {
    setLoading(true);

    async function loadFila(): Promise<void> {
      await api
        .get(`/fila/monitor-carregamento/${produto}`)
        .then(response => {
          console.log('resp MonitCarregamento', response.data);
          setFilaData(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error filamonitorcarregamento:', error);
          setLoading(false);
        });
    }
    loadFila();

    // Start countdown timer
    setCountdown(pollingInterval / 1000);

    // Set up polling to refresh the data every 10 seconds
    const intervalId = setInterval(() => {
      loadFila();
      setCountdown(pollingInterval / 1000); // Reset countdown
    }, pollingInterval);

    // Countdown logic
    const countdownInterval = setInterval(() => {
      setCountdown(prevCountdown =>
        prevCountdown > 0 ? prevCountdown - 1 : prevCountdown,
      );
    }, 1000);

    // Cleanup intervals on component unmount
    return () => {
      clearInterval(intervalId);
      clearInterval(countdownInterval);
    };
  }, [produto]);

  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    // MIN = Minimum expected value
    // MAX = Maximum expected value
    // Function to normalise the values (MIN / MAX could be integrated)
    const normalise = value => ((value - 0) * 100) / (60 - 0);

    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          variant="determinate"
          {...props}
          value={normalise(props.value)}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${props.value}s`}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Container>
      <header
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <h1>Carregamento - {produto?.toUpperCase()} </h1>
        {/* Countdown Progress Bar */}
        <div
          style={{
            display: 'flex',
            right: '2rem',
            position: 'absolute',
          }}
        >
          <CircularProgressWithLabel value={countdown} />
        </div>
      </header>

      {/* Table */}
      <TableContainer>
        <table>
          {/* This header are being used for displaying header on the report */}
          <thead>
            <tr>
              <th>Data</th>
              <th>Fila Id</th>
              <th>Produto</th>
              <th>Placa Carreta</th>
              <th>Compartimento</th>
              <th>Nome Motorista</th>
              <th>Senha Carregamento</th>
              <th>Volume Carregar</th>
              <th>Tara</th>
            </tr>
          </thead>

          {!loading &&
            filaData &&
            filaData.map((filaData, index) => (
              <tbody key={index}>
                <tr>
                  <td>{moment.utc(filaData.data).format('DD/MM/YYYY')}</td>
                  <td>{filaData.filaid}</td>
                  <td>
                    {filaData.produto === 1
                      ? 'Hidratado'
                      : filaData.produto === 2
                      ? 'Etanol - Outros Fins'
                      : filaData.produto === 3
                      ? 'Bagaço'
                      : filaData.produto === 4
                      ? 'Açúcar VHP'
                      : filaData.produto === 5
                      ? 'Anidro'
                      : 'Error'}
                  </td>
                  <td>{filaData.placa}</td>
                  <td>{filaData.compartimento}</td>
                  <td>{filaData.motorista}</td>
                  <td>{filaData.senhacarregamento}</td>
                  <td>{filaData.volumecarregar}</td>
                  <td>{filaData.tara}</td>
                </tr>
              </tbody>
            ))}
        </table>
      </TableContainer>
    </Container>
  );
}

export default FilaMonitorCarregamento;
