export const formatFilaStatus = (status: string) => {
  switch (status) {
    case 'AGPE':
      return 'Aguardando Pedido';
    case 'APPE':
      return 'Aguardando Pag. Pedido';
    case 'ACKL':
      return 'Aguardando Checklist';
    case 'AENT':
      return 'Aguardando Liberação Entrada';
    case 'ELIB':
      return 'Pesagem Liberada';
    case 'PCAR':
      return 'Processo Carregamento';
    case 'AFAT':
      return 'Aguard. Faturamento';
    case 'ADOC':
      return 'Aguard. Docs Transportadora';
    case 'NLIB':
      return 'Nota Fiscal Liberada Coleta';
    case 'CAFI':
      return 'Carregamento Finalizado';
    default:
      return 'Erro';
  }
};
