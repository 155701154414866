/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useCallback, ReactElement } from 'react';

import { FormHandles } from '@unform/core';

import { Form } from './styles';
import Modal from '../Modal';
import Input from '../Input';
import TextArea from '../TextArea';

interface IJobsData {
  jobid: number;
  jobtitle: string;
  shift: string;
  education: string;
  contract: string;
  description: string;
  open: boolean;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleUpdateJobs: (registries: IJobsData) => void;
  editingJobs: IViewJobsData;
}

interface IViewJobsData {
  jobid: number;
  jobtitle: string;
  shift: string;
  education: string;
  contract: string;
  description: string;
  open: boolean;
}

function ModalViewJobs({
  isOpen,
  setIsOpen,
  editingJobs,
  handleUpdateJobs,
}: IModalProps): ReactElement {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: IViewJobsData) => {
      handleUpdateJobs(data);
      setIsOpen();
    },
    [handleUpdateJobs, setIsOpen],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={editingJobs}>
        <h1>Informações da Vaga</h1>

        <label htmlFor="jobId">ID</label>
        <Input name="jobId" placeholder="ID da Vaga" />

        <label htmlFor="jobTitle">Título</label>
        <Input name="jobTitle" placeholder="Name" />

        <label htmlFor="shift">Turno</label>
        <Input name="shift" placeholder="Turno (Adm/5x1/6x1)" />

        <label htmlFor="education">Formação Acadêmica</label>
        <Input name="education" placeholder="Formação" />

        <label htmlFor="contract">Tipo Contrato</label>
        <Input name="contract" placeholder="CLT / Safra" />

        <label htmlFor="description">Descrição</label>
        <TextArea
          name="description"
          placeholder="Descrição da Vaga"
          cols={10}
          rows={5}
        />
      </Form>

      <h5
        style={{
          color: '#fff',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
        }}
      >
        Para fechar click fora do formulário.
      </h5>
    </Modal>
  );
}

export default ModalViewJobs;
