import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 10px;
    z-index: 99;
    position: relative;

    h1 {
      color: #00a859;
      font-weight: 600;
    }

    nav {
      button {
        border: 1px solid #00a859;
        background-color: transparent;
        border-radius: 4px;
        color: #171412;
        font-weight: 600;
        font-family: 'Poppins';
        height: 40px;
        padding-left: 8px;
        padding-right: 8px;
      }

      > button {
        margin-left: 10px;
      }

      & :hover {
        cursor: pointer;
        color: #00a859;
      }
    }
  } /* END HEADER */

  #buttonReportHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
  }

  #pageTitle {
    justify-content: center;
    text-align: center;
    color: #00a859;
    font-weight: 600;
  }

  /* titulo saldo pedidos - Table */
  h4 {
    display: flex;
    justify-content: center;
    color: #00a859;
    font-weight: 600;
    width: 100%;
    margin-top: 50px;
  }
`;

export const TableContainer = styled.section`
  max-height: calc(100vh - 240px) !important;
  padding-bottom: 3rem;
  margin-top: 1.5rem;
  width: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;

  padding-left: 20px;
  padding-right: 20px;

  table {
    width: 100%;
    height: 100%;
    border-spacing: 0 8px;
    white-space: nowrap;
    justify-content: center;
    border-collapse: collapse;

    thead th {
      height: 30px;
      top: 0;
      position: sticky;
      background: #fff;
    }

    #nullPedidos {
      border: 1px solid red;
    }

    th {
      text-align: center;
    }

    td {
      padding: 8px 0px;
      border: 0;
      background: #fff;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      color: #969cb3;
      border-bottom: 1px solid #969cb3;

      &.cnDtEmissao {
        width: 90px;
      }

      &.cnPedBsa {
        width: 90px;
      }

      &.cnPesos {
        width: 110px;
      }

      &.cnMotorista {
        width: 290px;
      }

      &.cnStatus {
        width: 250px;
      }

      &.cnPlacas {
        width: 100px;
      }

      &.cnVolume {
        width: 160px;
      }
    }

    td:first-child {
      border-radius: 8px 0 0 8px;
    }

    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  }

  /* *********************Media 1400*********************************** */
  @media (max-width: 1400px) {
    margin-top: 20px;
    padding-bottom: 1rem;

    table {
      width: 100%;
      height: 100%;
      border-spacing: 0 8px;
      white-space: nowrap;
      justify-content: center;
      border-collapse: collapse;

      thead th {
        height: 30px;
        top: 0;
        position: sticky;
        background: #fff;
      }

      #nullPedidos {
        border: 1px solid red;
      }

      th {
        text-align: center;
        font-size: 12px;
      }

      td {
        padding: 8px 0px;
        border: 0;
        background: #fff;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        color: #969cb3;
        border-bottom: 1px solid #969cb3;

        &.cnDtEmissao {
          width: 90px;
        }

        &.cnPedBsa {
          width: 90px;
        }

        &.cnPesos {
          width: 110px;
        }

        &.cnMotorista {
          width: 290px;
        }

        &.cnStatus {
          width: 250px;
        }

        &.cnPlacas {
          width: 100px;
        }

        &.cnVolume {
          width: 160px;
        }
      }

      td:first-child {
        border-radius: 8px 0 0 8px;
      }

      td:last-child {
        border-radius: 0 8px 8px 0;
      }
    }
  }
  /* ***************************************************************** */
`;

export const Calendar = styled.aside`
  right: 10px;
  align-items: center;

  .MuiStack-root {
    padding: 0;
  }

  .DayPickerInput {
    width: 120px;
    border-radius: 4px;
    padding-left: 33%;

    input {
      width: 120px;
      border-radius: 4px;
      text-align: center;
      margin-bottom: 5px;
    }
  }

  .DayPicker {
    background: #272727;
    border-radius: 10px;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
    padding-top: 0;
  }

  .DayPicker,
  .DayPicker-Month {
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 2px;
    margin: 2px;
    color: #00a859;
  }

  .DayPicker-Day {
    width: 8px;
    height: 8px;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #666360;
    border-radius: 10px;
    color: #fff;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--selected
    ):not(.DayPicker-Day--outside):hover {
    background: ${shade(0.2, '#666360')};
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: #666360 !important;
    background: transparent !important;
  }

  .DayPicker-Day--selected {
    background: #00a859 !important;
    border-radius: 10px;
  }
`;
