/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { FiCheckSquare } from 'react-icons/fi';

import { Container } from './styles';
import { ButtonGroup } from '../../styles/customButtonGroup';
import api from '../../services/api';

// Components
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Box, Stack } from '@mui/material';
import MuiSelect from '../../components/Material/Select';
import { MaskedInput } from '../../components/Material/MaskedInput';
import { InputWithIcon } from '../../components/Material/Input';

interface IFormData {
  tipoveiculo: string;
  placa: string;
  uf: string;
  compartimentos: number;
  tipoCarreta: string;
  veiculocompartimento;
  setas;
  volumeCompart1: number;
  volumeCompart2: number;
  volumeCompart3: number;
  volumeCompart4: number;
  volumeCompart5: number;
  volumeCompart6: number;
  volumeCompart7: number;
  volumeCompart8: number;
  compart1seta1: number | string;
  compart1seta2: number | string;
  compart1seta3: number | string;
  compart1seta4: number | string;
  compart1seta5: number | string;
  compart2seta1: number | string;
  compart2seta2: number | string;
  compart2seta3: number | string;
  compart2seta4: number | string;
  compart2seta5: number | string;
  compart3seta1: number | string;
  compart3seta2: number | string;
  compart3seta3: number | string;
  compart3seta4: number | string;
  compart3seta5: number | string;
  compart4seta1: number | string;
  compart4seta2: number | string;
  compart4seta3: number | string;
  compart4seta4: number | string;
  compart4seta5: number | string;
  compart5seta1: number | string;
  compart5seta2: number | string;
  compart5seta3: number | string;
  compart5seta4: number | string;
  compart5seta5: number | string;
  compart6seta1: number | string;
  compart6seta2: number | string;
  compart6seta3: number | string;
  compart6seta4: number | string;
  compart6seta5: number | string;
  compart7seta1: number | string;
  compart7seta2: number | string;
  compart7seta3: number | string;
  compart7seta4: number | string;
  compart7seta5: number | string;
  compart8seta1: number | string;
  compart8seta2: number | string;
  compart8seta3: number | string;
  compart8seta4: number | string;
  compart8seta5: number | string;
}

function CadastroVeiculos(): ReactElement {
  const { signOut } = useAuth();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    resetField,
    formState: { errors },
  } = useForm<IFormData>({
    defaultValues: {
      placa: '',
      compart1seta1: 0,
      compart1seta2: 0,
      compart1seta3: 0,
      compart1seta4: 0,
      compart1seta5: 0,
      compart2seta1: 0,
      compart2seta2: 0,
      compart2seta3: 0,
      compart2seta4: 0,
      compart2seta5: 0,
      compart3seta1: 0,
      compart3seta2: 0,
      compart3seta3: 0,
      compart3seta4: 0,
      compart3seta5: 0,
      compart4seta1: 0,
      compart4seta2: 0,
      compart4seta3: 0,
      compart4seta4: 0,
      compart4seta5: 0,
      compart5seta1: 0,
      compart5seta2: 0,
      compart5seta3: 0,
      compart5seta4: 0,
      compart5seta5: 0,
      compart6seta1: 0,
      compart6seta2: 0,
      compart6seta3: 0,
      compart6seta4: 0,
      compart6seta5: 0,
      compart7seta1: 0,
      compart7seta2: 0,
      compart7seta3: 0,
      compart7seta4: 0,
      compart7seta5: 0,
      compart8seta1: 0,
      compart8seta2: 0,
      compart8seta3: 0,
      compart8seta4: 0,
      compart8seta5: 0,
    },
  });

  const selectOptions = [
    '1 - Cavalo',
    '2 - Carreta',
    '3 - Cavalo Mercosul',
    '4 - Carreta Mercosul',
  ];

  const ufOptions = [
    'AC',
    'AL',
    'AM',
    'AP',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MG',
    'MS',
    'MT',
    'PA',
    'PB',
    'PE',
    'PI',
    'PR',
    'RJ',
    'RN',
    'RO',
    'RR',
    'RS',
    'SC',
    'SE',
    'SP',
    'TO',
  ];

  const tipoCarretaOptions = ['Tanque - TQ', 'Outros - OT'];

  const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
    try {
      if (
        data.tipoveiculo === '1 - Cavalo' ||
        data.tipoveiculo === '3 - Cavalo Mercosul'
      ) {
        await api.post('/veiculos', {
          tipoveiculo: data.tipoveiculo.charAt(0),
          tipocarreta: 'CV',
          placa: data.placa.toUpperCase(),
          uf: data.uf.toUpperCase(),
          compartimentos: 0,
          capacidadetotal: 0,
        });
      }

      if (
        data.tipoveiculo === '2 - Carreta' ||
        data.tipoveiculo === '4 - Carreta Mercosul'
      ) {
        let volumeTotal = 0;
        for (let x = 0; x < data.compartimentos; x++) {
          volumeTotal += Number(data[`volumeCompart${x + 1}`]);
        }

        await api.post('/veiculos', {
          tipoveiculo: data.tipoveiculo.charAt(0),
          placa: data.placa.toUpperCase(),
          uf: data.uf.toUpperCase(),
          compartimentos: data.compartimentos,
          capacidadetotal: volumeTotal,
        });

        Array.from({ length: data.compartimentos }, (_, i: number) => {
          api.post('/compartimentos', {
            placa: data.placa.toUpperCase(),
            compartimento: i + 1,
            volume: data[`volumeCompart${i + 1}`],
            seta1: data[`compart${i + 1}seta1`] ?? 0,
            seta2: data[`compart${i + 1}seta2`] ?? 0,
            seta3: data[`compart${i + 1}seta3`] ?? 0,
            seta4: data[`compart${i + 1}seta4`] ?? 0,
            seta5: data[`compart${i + 1}seta5`] ?? 0,
          });
        });
      }

      // SHOW TOAST FOR SUCCESS
      addToast({
        type: 'success',
        title: 'Cadastro Efetuado',
        description: 'Veículo Cadastrado com sucesso!',
      });

      reset();
      resetField('placa', { defaultValue: undefined });
    } catch (error: any) {
      // SHOW TOAST IF ANY ERROR
      addToast({
        type: 'error',
        title: 'Erro no cadastro.',
        description: `${error.response.data.message}`,
      });

      // Reset form
      reset();
    }
  };

  return (
    <Container>
      <header>
        <h1>Portal Transportadoras - Bom Sucesso Agroindústria</h1>

        <nav>
          <button type="submit" onClick={() => navigate('/')}>
            Página Inicial
          </button>

          <button type="submit" onClick={() => navigate('/portal')}>
            Voltar
          </button>

          <button type="submit" onClick={signOut}>
            Logout
          </button>
        </nav>
      </header>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <h2 id="pageTitle">Cadastro de Veículos</h2>
        <Stack gap={2} padding="1 2" mt={2}>
          <Box
            display="flex"
            maxWidth="960px"
            width={'100% !important'}
            gap={1}
          >
            <MuiSelect
              {...register('tipoveiculo', {
                required: 'Tipo Veículo obrigatório.',
              })}
              control={control}
              label="Tipo Veículo"
              name="tipoveiculo"
              options={selectOptions}
              variant="outlined"
              sx={{ width: '250px !important' }}
            />

            {watch('tipoveiculo') === '2 - Carreta' ||
            watch('tipoveiculo') === '4 - Carreta Mercosul' ? (
              <MuiSelect
                {...register('tipoCarreta', {
                  required: 'Tipo carreta obrigatório.',
                })}
                control={control}
                label="Tipo Carreta"
                name="tipoCarreta"
                options={tipoCarretaOptions}
                variant="outlined"
                sx={{ width: '178px' }}
              />
            ) : null}

            <MaskedInput
              {...register('placa', { required: 'Placa obrigatória.' })}
              control={control}
              type="placa"
              name="placa"
              label="Placa"
              onBlur={() => {}}
              error={!!errors.placa}
              autoComplete={'off'}
              sx={{ width: '120px' }}
              inputProps={{ textTransform: 'uppercase' }}
            />

            <MuiSelect
              {...register('uf', {
                required: 'UF obrigatória.',
              })}
              control={control}
              label="UF Placa"
              name="uf"
              options={ufOptions}
              variant="outlined"
              sx={{ width: '120px' }}
            />

            {watch('tipoveiculo') === '2 - Carreta' ||
            watch('tipoveiculo') === '4 - Carreta Mercosul' ? (
              <MaskedInput
                {...register('compartimentos', {
                  required: 'Número Compartimentos obrigatório.',
                })}
                control={control}
                type="numbers"
                name="compartimentos"
                label="Número Compartimentos"
                onBlur={() => {}}
                error={!!errors.compartimentos}
                autoComplete={'off'}
                inputProps={{ min: 0, max: 8 }}
                sx={{ width: '248px' }}
              />
            ) : null}
          </Box>

          <Stack>
            {/* Compartimentos entre 1 e 8 */}
            {(watch('tipoveiculo') === '2 - Carreta' ||
              watch('tipoveiculo') === '4 - Carreta Mercosul') &&
            watch('compartimentos') > 0 &&
            watch('compartimentos') <= 8
              ? Array.from(
                  { length: watch('compartimentos') },
                  (_, i: number) => (
                    <Box key={i} display="flex" gap={1} mb={2}>
                      <InputWithIcon
                        name="compartimentoNumero"
                        label="Compartimento"
                        icon={<></>}
                        control={control}
                        type={'text'}
                        inputProps={{ readOnly: true }}
                        value={i + 1}
                        sx={{
                          width: '150px',
                        }}
                      />
                      <MaskedInput
                        {...register(
                          `volumeCompart${i + 1}` as keyof IFormData,
                          {
                            required: 'Capacidade obrigatória.',
                          },
                        )}
                        control={control}
                        type="numbers"
                        name={`volumeCompart${i + 1}`}
                        label="Capacidade"
                        error={!!errors.compartimentos}
                        autoComplete={'off'}
                        onBlur={e =>
                          setValue(
                            `compart${i + 1}seta1` as keyof IFormData,
                            e.currentTarget.value,
                            { shouldTouch: true },
                          )
                        }
                        inputProps={
                          `volumeCompart${i + 1}` === 'volumeCompart1'
                            ? { autoFocus: true }
                            : { autoFocus: false }
                        }
                        sx={{ width: '150px' }}
                      />

                      {watch('tipoCarreta') === 'Tanque - TQ' &&
                        Array.from({ length: 5 }, (_, y: number) => (
                          <MaskedInput
                            {...register(
                              `compart${i + 1}seta${y + 1}` as keyof IFormData,
                              {
                                required: 'Número Compartimentos obrigatória.',
                              },
                            )}
                            key={y}
                            control={control}
                            type="numbers"
                            name={`compart${i + 1}seta${y + 1}`}
                            label={`Seta ${y + 1}`}
                            onBlur={() => {}}
                            error={!!errors[`compart${i + 1}seta${y + 1}`]}
                            autoComplete={'off'}
                            inputProps={
                              y + 1 === 1
                                ? { readOnly: true }
                                : { readOnly: false }
                            }
                            sx={{ width: '120px' }}
                          />
                        ))}
                    </Box>
                  ),
                )
              : null}
          </Stack>

          <ButtonGroup>
            <button type="submit" name="SaveButton">
              <div className="text">Cadastrar</div>
              <div className="icon">
                <FiCheckSquare size={26} />
              </div>
            </button>
          </ButtonGroup>
        </Stack>
      </form>
    </Container>
  );
}

export default CadastroVeiculos;
