import moment from 'moment-timezone';

/**
 *
 * @param date string | Date
 * @returns Date in format 'YYYY-MM-DD HH:mm:ss'
 */
export const convertDateToDateTimeBr = (date: string | Date) => {
  return moment(date).tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss');
};

/**
 *
 * @param date string | Date
 * @returns String in format 'YYYY-MM-DD'
 */
export const convertDateToBr = (date: string | Date) => {
  return moment(date).tz('America/Sao_Paulo').format('YYYY-MM-DD');
};

/**
 *
 * @param date string | Date
 * @returns Date in format 'YYYY-MM-DD'
 */
export const convertDateToBrDate = (date: string | Date) => {
  const formattedDate = moment(date)
    .tz('America/Sao_Paulo')
    .format('YYYY-MM-DD');
  return new Date(formattedDate);
};
