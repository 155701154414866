/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectProps } from '@mui/material/Select';
import { Controller } from 'react-hook-form';
import { FormHelperText } from '@mui/material';

export type IMuiSelectProps = SelectProps & {
  label: string;
  variant: string;
  options: string[] | number[];
  name: string;
  disabled?: boolean;
  control: any;
  sx?: any;
};

export default function MuiSelect({
  label,
  options, // ...selectProps
  name,
  control,
  disabled,
  sx,
  ...selectProps
}: IMuiSelectProps) {
  const selectStyles = {
    formControl: {
      // minWidth: '200px',
      // width: selectProps ?? '100%',
      display: 'flex',
      padding: 0,
      margin: 0,
    },
    selectEmpty: {},
  };

  const generateSingleOptions = () => {
    return options.map((option: any) => {
      return (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      );
    });
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={''}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl sx={selectStyles.formControl} error={!!error}>
          <InputLabel
            id="mui-select-input-label"
            sx={disabled ? { opacity: 0.3 } : { opacity: 1 }}
          >
            {label}
          </InputLabel>
          <Select
            {...selectProps}
            label={label}
            id="mui-select"
            value={value}
            onChange={onChange}
            error={!!error}
            disabled={disabled}
            sx={{
              ...sx,
              color: error ? '#bf1319' : '#272727',
              width: sx ?? '100%',
              '.MuiSelect-select': {
                fontSize: '1rem',
              },
              '&.Mui-disabled': {
                opacity: 0.3,
              },
            }}
          >
            {generateSingleOptions()}
          </Select>
          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
