import styled from 'styled-components';

export const Container = styled.div`
  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 10px;
    z-index: 99;
    position: relative;

    h1 {
      color: #00a859;
      font-weight: 600;
    }

    nav {
      button {
        border: 1px solid #00a859;
        background-color: transparent;
        border-radius: 4px;
        color: #171412;
        font-weight: 600;
        font-family: 'Poppins';
        height: 40px;
        padding-left: 8px;
        padding-right: 8px;
      }

      > button {
        margin-left: 10px;
      }

      & :hover {
        cursor: pointer;
        color: #00a859;
      }
    }
  } /* END HEADER */

  #pageTitle {
    justify-content: center;
    text-align: center;
    color: #00a859;
    font-weight: 600;
    margin: 0 auto;

    :after {
      display: block;
      text-align: center;
      margin: 0 auto;
      content: '';
      width: 40%;
      border-bottom: 3px solid #00a859;
      animation: 1s ease-in 0s 1 slideInFromLeft;
    }

    @keyframes slideInFromLeft {
      0% {
        transform: translateX(-40%);
      }
      100% {
        transform: translateX(0);
      }
    }
  }

  /* titulo saldo pedidos - Table */
  h4 {
    display: flex;
    justify-content: center;
    color: #00a859;
    font-weight: 600;
    width: 100%;
    margin-top: 50px;
  }
`;

export const TableContainer = styled.section`
  overflow-y: visible;
  overflow-x: hidden;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 0;

  h4 {
    display: flex;
    justify-content: center;
    color: #00a859;
    font-weight: 600;
  }

  table {
    width: 100%;
    border-spacing: 0 8px;
    white-space: nowrap;
    justify-content: center;
    margin-top: 1rem;
    position: relative;
    border-collapse: collapse;

    th {
      color: #363f5f;
      font-weight: bold;
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      position: sticky;
      top: 0;
      background: white;
    }

    td {
      border: 0;
      background: #fff;
      text-align: center;
      font-size: 14px;
      font-weight: normal;
      color: #969cb3;

      /* Edit and Delete Button */
      button {
        border: transparent;
        background: none;
        margin-right: 5px;

        svg {
          display: flex;
        }
      }
    }

    td:first-child {
      border-radius: 8px 0 0 8px;
    }

    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;
