import React, { ReactElement, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { FaExchangeAlt, FaList, FaTruck, FaBoxOpen } from 'react-icons/fa';
import { GoChecklist } from 'react-icons/go';

import { Container, CardContainer, Card } from './styles';

import { useAuth } from '../../hooks/auth';
import ModalChangeStatusFila from '../../components/ModalChangeStatusFila';
import { RiGuideLine, RiUserFollowLine } from 'react-icons/ri';
import ModalChegadaMotoristas from '../../components/ModalChegadaMotoristas';
import api from '../../services/api';
import ModalCheckListPanel from '../../components/ModalCheckListPanel';

function PainelLogistica(): ReactElement {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const [changeStatusModalOpen, setChangeStatusModalOpen] = useState(false);
  const [chegadaMotoristaModalOpen, setChegadaMotoristaModalOpen] =
    useState(false);

  const [panelChecklistModalOpen, setPanelChecklistModalOpen] = useState(false);

  const [filaCarregamento, setFilaCarregamento] = useState([]);

  // Toogle modal change status
  function toogleChangeStatusModal(): void {
    setChangeStatusModalOpen(!changeStatusModalOpen);
  }

  // Toogle modal Chegada Motorista,
  function toogleInsertChegadaMotorista(): void {
    setChegadaMotoristaModalOpen(!chegadaMotoristaModalOpen);
  }

  // Toogle Panel Checklist Modal
  async function tooglePanelChecklistModal(): Promise<void> {
    // Find fila carregamento
    const foundFilaCarregamento = await api.get('/fila/').then(response => {
      const filteredFila = response.data.filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (fila: any) => fila.status === 'ACKL',
      );
      return filteredFila;
    });
    setFilaCarregamento(foundFilaCarregamento);
    setPanelChecklistModalOpen(!panelChecklistModalOpen);
  }

  let user = localStorage.getItem('@Bsa:user');
  if (!user) {
    user = '';
  }
  const userObj = JSON.parse(user);

  const isPortariaUser =
    userObj.logistica === true &&
    (userObj.avatar === 'portaria' ||
      userObj.login === 'portaria@bsabioenergia.com.br');

  return (
    <Container>
      <ModalChangeStatusFila
        isOpen={changeStatusModalOpen}
        setIsOpen={() => toogleChangeStatusModal()}
      />

      <ModalChegadaMotoristas
        isOpen={chegadaMotoristaModalOpen}
        setIsOpen={() => toogleInsertChegadaMotorista()}
      />

      <ModalCheckListPanel
        isOpen={panelChecklistModalOpen}
        setIsOpen={() => tooglePanelChecklistModal()}
        filaData={filaCarregamento}
      />

      <header>
        <h1>Bom Sucesso Agroindústria S.A</h1>

        <nav>
          <button type="submit" onClick={() => navigate('/')}>
            Página Inicial
          </button>

          <button type="submit" onClick={() => navigate('/admin')}>
            Voltar
          </button>

          <button type="submit" onClick={signOut}>
            Logout
          </button>
        </nav>
      </header>
      <h2 id="pageTitle">Painel Logística</h2>

      <h2 id="selectOption">Selecione uma opção</h2>

      <CardContainer style={{ display: isPortariaUser ? 'flex' : 'grid' }}>
        {isPortariaUser ? (
          // Confirmar Motorista
          <Card onClick={() => toogleInsertChegadaMotorista()}>
            <h1
              className="cadConfirmaMotorista"
              id="cadConfirmaMotorista"
              data-testid="cadConfirmaMotorista"
            >
              Confirmar Motorista
            </h1>
            <RiUserFollowLine size={42} color="#fff" />
          </Card>
        ) : (
          <>
            {/* Aprova Checklist */}
            <Card onClick={() => tooglePanelChecklistModal()}>
              <h1 className="painelAdmin" data-testid="painelAdmin">
                Aprova Checklist
              </h1>
              <GoChecklist size={42} color="#fff" />
            </Card>

            {/* Confirmar Motorista */}
            <Card onClick={() => toogleInsertChegadaMotorista()}>
              <h1
                className="cadConfirmaMotorista"
                id="cadConfirmaMotorista"
                data-testid="cadConfirmaMotorista"
              >
                Confirmar Motorista
              </h1>
              <RiUserFollowLine size={42} color="#fff" />
            </Card>

            {/* Change Status */}
            <Card onClick={() => toogleChangeStatusModal()}>
              <h1 className="painelLogistica" data-testid="painelLogistica">
                Altera Status Fila
              </h1>
              <FaExchangeAlt size={42} color="#fff" />
            </Card>

            {/* Agendamentos Card - only accessible to user.logistica */}
            <Card onClick={() => navigate('/painelagendamentos')}>
              <h1
                className="painelAgendamentos"
                data-testid="painelAgendamentos"
              >
                Agendamentos
              </h1>
              <FaList size={42} color="#fff" />
            </Card>

            {/* Carregamentos Card - only accessible to user.logistica */}
            <Card onClick={() => navigate('/painelcarregamentos')}>
              <h1
                className="painelCarregamentos"
                data-testid="painelCarregamentos"
              >
                Carregamentos
              </h1>
              <FaTruck size={42} color="#fff" />
            </Card>

            {/* Carregamentos Overview Card - only accessible to user.logistica */}
            <Card onClick={() => navigate('/carregamentosoverview')}>
              <h1
                className="carregamentosOverview"
                data-testid="carregamentosOverview"
              >
                Overview Carregamentos
              </h1>
              <FaBoxOpen size={42} color="#fff" />
            </Card>

            {/* Saldo ordens SAP */}
            <Card onClick={() => navigate('/saldoordenssap')}>
              <h1
                className="saldoOrdensSap"
                id="saldoOrdensSap"
                data-testid="saldoOrdensSap"
              >
                Saldo Ordens SAP
              </h1>
              <RiGuideLine size={42} color="#fff" />
            </Card>
          </>
        )}
      </CardContainer>
    </Container>
  );
}

export default PainelLogistica;
