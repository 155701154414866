import axios from 'axios';

const api = axios.create({
  // Local env
  // baseURL: 'http://localhost:3333',
  // Production env
  baseURL: 'https://api.bsabioenergia.com.br',
  // SAP new server env
  // baseURL: 'https://192.168.1.152:3333',
  // Outros produtos serve env
  // baseURL: 'https://192.168.1.153:3333',
});

export default api;
