/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FormControl, TextField, TextFieldProps } from '@mui/material';
import { Controller } from 'react-hook-form';

export type InputProps = TextFieldProps & {
  name: string;
  label: string;
  control: any;
};

export function MuiInput({
  name,
  label,
  // sx,
  inputProps,
  control,
  ...textFieldProps
}: InputProps) {
  return (
    <FormControl
      sx={{ minWidth: '200px', maxWidth: '200px' }}
      variant="outlined"
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            {...textFieldProps}
            helperText={error ? error.message : null}
            size="medium"
            error={!!error}
            onChange={onChange}
            value={value}
            fullWidth
            label={label}
            variant="outlined"
            inputProps={{ ...inputProps }}
            sx={{ fontSize: '1rem', mt: 2 }}
          />
        )}
      />
    </FormControl>
  );
}
