import styled from 'styled-components';
import { Form as Unform } from '@unform/web';

export const Form = styled(Unform)`
  padding: 0px 50px;
  display: flex;
  flex-direction: column;
  text-align: center;

  > input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #f4ede8;

    background: #272727;
    border-radius: 10px;
    border: 2px solid #272727;
    padding: 16px;
    width: 100%;
    color: #fff;

    margin-top: 10px;
    margin-bottom: 10px;

    display: flex;
    align-items: center;

    &::placeholder {
      color: #666360;
    }
  }

  select {
    flex: 1;
    background: transparent;
    border: 0;
    color: #f4ede8;

    background: #272727;
    border-radius: 10px;
    border: 2px solid #272727;
    padding: 16px;
    width: 100%;
    font-size: 16px;

    margin-top: 10px;
    margin-bottom: 10px;

    display: flex;
    align-items: center;
  }

  h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 0;
    color: #00a859;
  }

  label {
    color: #00a859;
    font-size: 12px;
    margin-left: 18px;
    text-align: left;
  }

  & > label {
    margin-top: 5px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;

  > .CancelButton {
    background: #bf1319;

    > .icon {
      display: flex;
      padding: 16px 16px;
      background: #bf1319;
      border-radius: 0 8px 8px 0;
      margin: 0 auto;
    }
  }

  button {
    font-weight: 600;
    border-radius: 8px;
    border: 0;
    background: #00a859;
    color: #fff;

    display: flex;
    flex-direction: row;
    align-self: flex-end;

    margin-top: 48px;

    .text {
      padding: 16px 24px;
    }

    .icon {
      display: flex;
      padding: 16px 16px;
      background: #00a859;
      border-radius: 0 8px 8px 0;
      margin: 0 auto;
    }
  }
`;
