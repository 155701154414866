export const formatPrinterName = (printerName: string) => {
  let printer;
  switch (printerName) {
    case '1 - Logistica':
      printer = 'logistica';
      break;
    case '2 - Laboratorio':
      printer = 'laboratorio';
      break;
    case '3 - Portaria':
      printer = 'portaria';
      break;
    default:
      printer = 'logistica';
      break;
  }

  return printer;
};
