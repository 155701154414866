/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  ReactElement,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import InputMask from 'react-input-mask';

import { useField } from '@unform/core';

import { Container, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  // overwrite the requirement of having a name in input, which was not required
  name: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  containerStyle?: object;
  mask?: string;
  maskplaceholder?: string;
  // Icon was a custom prop defined in index.tsx SignIn
  icon?: React.ComponentType<IconBaseProps>;
}

function InputMaskCnpjEmail({
  name,
  containerStyle = {},
  icon: Icon,
  ...rest
}: InputProps): ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [inputmask, setInputMask] = useState(String);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(value => {
    setIsFocused(false);

    if (value.length >= 1) {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const regularExp = {
    onlyNumbers: /^[0-9]+$/,
    containsAlpha: /[a-zA-Z]/,
    containsEmailSymbols: /[@]/,
  };
  const handleChange = (value: string) => {
    const onlyNumbers = value.replace(/[^0-9a-zA-Z]+/gm, '');

    if (
      regularExp.containsAlpha.test(value) ||
      regularExp.containsEmailSymbols.test(value)
    ) {
      // Email input, no mask needed
      setInputMask('');
    } else {
      if (onlyNumbers.length <= 11) {
        // CPF input
        setInputMask('999.999.999-999');
      } else if (onlyNumbers.length > 11) {
        // CNPJ input
        setInputMask('99.999.999/9999-99');
      }
    }
  };

  return (
    <Container
      style={containerStyle}
      iserrored={!!error}
      isfilled={isFilled}
      isfocused={isFocused}
      data-testid="input-container"
    >
      {Icon && <Icon size={18} />}
      <InputMask
        onFocus={handleInputFocus}
        onBlur={e => handleInputBlur(e.currentTarget.value)}
        mask={inputmask}
        maskPlaceholder={null}
        defaultValue={defaultValue}
        onChange={e => handleChange(e.currentTarget.value)}
        {...rest}
      />
      {/* If the error exists than show the icon for the error */}
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={18} />
        </Error>
      )}
    </Container>
  );
}

export default InputMaskCnpjEmail;
