import React, { ReactElement, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { GoChecklist } from 'react-icons/go';

import {
  RiArrowLeftRightFill,
  RiCalendarCheckLine,
  RiDatabase2Line,
  RiDeleteBin5Line,
  RiGuideLine,
  RiOrderPlayLine,
  RiPrinterLine,
  RiTruckFill,
  RiUserFollowLine,
} from 'react-icons/ri';
import { Container, CardContainer, Card } from './styles';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import ModalChegadaMotoristas from '../../components/ModalChegadaMotoristas';
import ModalCadastros from '../../components/ModalCadastros';
import ModalChangeDate from '../../components/Modals/ModalChangeDate';
import ModalDeleteProcess from '../../components/Modals/ModalDeleteProcess';
import ModalPrintQrCode from '../../components/Modals/ModalPrintQrCode';
import ModalTransportadoraProfile from '../../components/Modals/ModalTransportadoraProfile';
import ModalCheckListPanel from '../../components/ModalCheckListPanel';
import ModalOrdemContingencia from '../../components/Modals/ModalOrdemContingencia';

import api from '../../services/api';
import ModalOrdemVendaSync from '../../components/Modals/ModalOrdemVendaSync';

function PainelAdmin(): ReactElement {
  const { signOut, user } = useAuth();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const [chegadaMotoristaModalOpen, setChegadaMotoristaModalOpen] =
    useState(false);

  const [cadastrosModalOpen, setCadastrosModalOpen] = useState(false);

  const [changeDateModalOpen, setChangeDateModalOpen] = useState(false);

  const [deleteProcessModalOpen, setDeleteProcessModalOpen] = useState(false);

  const [printQrCodeModalOpen, setPrintQrCodeModalOpen] = useState(false);

  const [transportadoraProfileModalOpen, setTransportadoraProfileModalOpen] =
    useState(false);

  const [panelChecklistModalOpen, setPanelChecklistModalOpen] = useState(false);

  const [ordemContingenciaModalOpen, setOrdemContingenciaModalOpen] =
    useState(false);

  const [ordemVendaSyncModalOpen, setOrdemVendaSyncModalOpen] = useState(false);

  const [filaCarregamento, setFilaCarregamento] = useState([]);
  const [ordensContingencia, setOrdensContingencia] = useState([]);

  if (user.admin === false) {
    addToast({
      type: 'error',
      title: 'Acesso Restrito.',
      description: 'Disponível apenas para usuários Admin da Bom Sucesso.',
    });

    navigate('/portal');
  }

  // Toogle modal Chegada Motorista,
  function toogleInsertChegadaMotorista(): void {
    setChegadaMotoristaModalOpen(!chegadaMotoristaModalOpen);
  }

  // Toogle Panel Checklist Modal
  async function tooglePanelChecklistModal(): Promise<void> {
    // Find fila carregamento
    const foundFilaCarregamento = await api.get('/fila/').then(response => {
      const filteredFila = response.data.filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (fila: any) => fila.status === 'ACKL',
      );
      return filteredFila;
    });
    setFilaCarregamento(foundFilaCarregamento);
    setPanelChecklistModalOpen(!panelChecklistModalOpen);
  }

  // Toogle modal Cadastros
  function toogleCadastrosModal(): void {
    setCadastrosModalOpen(!cadastrosModalOpen);
  }

  // Toogle modal ChangeDate
  function toogleChangeDateModal(): void {
    setChangeDateModalOpen(!changeDateModalOpen);
  }

  // Toogle modal Delete Processo
  function toogleDeleteProcess(): void {
    setDeleteProcessModalOpen(!deleteProcessModalOpen);
  }

  // Toogle modal Delete Processo
  function tooglePrintQrCode(): void {
    setPrintQrCodeModalOpen(!printQrCodeModalOpen);
  }

  // Toogle modal Transportadora Profile
  function toogleTransportadoraProfile(): void {
    setTransportadoraProfileModalOpen(!transportadoraProfileModalOpen);
  }

  // Toogle modal Ordem Contingencia
  async function toogleOrdemContingencia(): Promise<void> {
    // Find ordem contingencia
    const foundOrdensContingencia = await api
      .get('/ordenscontingencia/')
      .then(response => {
        return response.data;
      });
    setOrdensContingencia(foundOrdensContingencia);
    setOrdemContingenciaModalOpen(!ordemContingenciaModalOpen);
  }

  function toogleOrdemVendaSync(): void {
    setOrdemVendaSyncModalOpen(!ordemVendaSyncModalOpen);
  }

  return (
    <Container>
      <ModalChegadaMotoristas
        isOpen={chegadaMotoristaModalOpen}
        setIsOpen={() => toogleInsertChegadaMotorista()}
      />

      <ModalCadastros
        isOpen={cadastrosModalOpen}
        setIsOpen={() => toogleCadastrosModal()}
      />

      <ModalChangeDate
        isOpen={changeDateModalOpen}
        setIsOpen={() => toogleChangeDateModal()}
      />

      <ModalDeleteProcess
        isOpen={deleteProcessModalOpen}
        setIsOpen={() => toogleDeleteProcess()}
      />

      <ModalPrintQrCode
        isOpen={printQrCodeModalOpen}
        setIsOpen={() => tooglePrintQrCode()}
      />

      <ModalTransportadoraProfile
        isOpen={transportadoraProfileModalOpen}
        setIsOpen={() => toogleTransportadoraProfile()}
      />

      <ModalCheckListPanel
        isOpen={panelChecklistModalOpen}
        setIsOpen={() => tooglePanelChecklistModal()}
        filaData={filaCarregamento}
      />

      <ModalOrdemContingencia
        isOpen={ordemContingenciaModalOpen}
        setIsOpen={() => toogleOrdemContingencia()}
        ordensData={ordensContingencia}
      />

      <ModalOrdemVendaSync
        isOpen={ordemVendaSyncModalOpen}
        setIsOpen={() => toogleOrdemVendaSync()}
      />

      <header>
        <h1>Bom Sucesso Agroindústria S.A</h1>

        <nav>
          <button type="submit" onClick={() => navigate('/')}>
            Página Inicial
          </button>

          <button type="submit" onClick={() => navigate('/admin')}>
            Voltar
          </button>

          <button type="submit" onClick={signOut}>
            Logout
          </button>
        </nav>
      </header>
      <h2 id="pageTitle">Painel Admin</h2>

      <h2 id="selectOption">Selecione uma opção</h2>

      <CardContainer>
        {/* Admin Card - only accessible to user.admin */}

        {/* Cadastros */}
        <Card onClick={() => toogleCadastrosModal()}>
          <h1 className="cadastros" id="cadastros" data-testid="cadastros">
            Cadastros
          </h1>
          <RiDatabase2Line size={42} color="#fff" />
        </Card>

        {/* Confirmar Motorista */}
        <Card onClick={() => toogleInsertChegadaMotorista()}>
          <h1
            className="cadConfirmaMotorista"
            id="cadConfirmaMotorista"
            data-testid="cadConfirmaMotorista"
          >
            Confirmar Motorista
          </h1>
          <RiUserFollowLine size={42} color="#fff" />
        </Card>

        {/* Admin Card - only accessible to user.admin */}
        <Card onClick={() => tooglePanelChecklistModal()}>
          <h1
            className="aprovaChecklist"
            id="cadAprovaChecklist"
            data-testid="aprovaChecklist"
          >
            Aprovar Checklist
          </h1>
          <GoChecklist size={42} color="#fff" />
        </Card>

        {/* Vinculo Pedido Transportadora */}
        <Card onClick={() => navigate('/vinculopedidos')}>
          <h1
            className="vinculopedido"
            id="cadVinculoPedido"
            data-testid="vinculopedido"
          >
            Vinculo Pedido/F.A
          </h1>
          <RiOrderPlayLine size={42} color="#fff" />
        </Card>

        {/* Mudar Data Processo Carregamento */}
        <Card onClick={() => toogleChangeDateModal()}>
          <h1 className="changeDate" id="changeDate" data-testid="changeDate">
            Alterar Data
          </h1>
          <RiCalendarCheckLine size={42} color="#fff" />
        </Card>

        {/* DeletarProcesso Carregamento */}
        <Card onClick={() => toogleDeleteProcess()}>
          <h1
            className="deleteprocess"
            id="deleteprocess"
            data-testid="deleteprocess"
          >
            Excluir Processo
          </h1>
          <RiDeleteBin5Line size={42} color="#fff" />
        </Card>

        {/* Reimpressão de QrCode Checklist */}
        <Card onClick={() => tooglePrintQrCode()}>
          <h1
            className="printQrCode"
            id="printQrCode"
            data-testid="printQrCode"
          >
            Impressão QRCode
          </h1>
          <RiPrinterLine size={42} color="#fff" />
        </Card>

        {/* Update dados transportadora */}
        <Card onClick={() => toogleTransportadoraProfile()}>
          <h1
            className="transportadoraProfile"
            id="transportadoraProfile"
            data-testid="transportadoraProfile"
          >
            Transportadora Profile
          </h1>
          <RiTruckFill size={42} color="#fff" />
        </Card>

        {/* Ordem Contingencia */}
        <Card onClick={() => toogleOrdemContingencia()}>
          <h1
            className="ordemContingencia"
            id="ordemContingencia"
            data-testid="ordemContingencia"
          >
            Ordem Contingencia
          </h1>
          <RiGuideLine size={42} color="#fff" />
        </Card>

        {/* Ordem Venda */}
        <Card onClick={() => toogleOrdemVendaSync()}>
          <h1
            className="ordemVendaSync"
            id="ordemVendaSync"
            data-testid="ordemVendaSync"
          >
            Sinc. Fat. SAP
          </h1>
          <RiArrowLeftRightFill size={42} color="#fff" />
        </Card>
      </CardContainer>
    </Container>
  );
}

export default PainelAdmin;
