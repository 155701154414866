/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';

import { ButtonGroup } from '../../../styles/customButtonGroup';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import Modal from '../../Modal';
import { MaskedInput } from '../../Material/MaskedInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import { RiPrinterLine } from 'react-icons/ri';
import MuiSelect from '../../Material/Select';
import { formatPrinterName } from '../../../utils/formatPrinterName';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

interface IFormData {
  data: string;
  agendamentoid: number;
  printer: string;
}

const ModalPrintQrCode = React.forwardRef<HTMLInputElement, IModalProps>(
  ({ isOpen, setIsOpen }, ref) => {
    const { addToast } = useToast();

    const {
      register,
      handleSubmit,
      control,
      reset,
      setValue,
      formState: { errors },
    } = useForm<IFormData>();

    const printerOptions = ['1 - Logistica', '2 - Laboratorio', '3 - Portaria'];

    // Set Default Printer
    setValue('printer', '1 - Logistica');

    const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
      // EXTRACT DATE
      const formatDataAgendamento = String(data.data);
      const day = formatDataAgendamento.substring(0, 2);
      const month = formatDataAgendamento.substring(3, 5);
      const year = formatDataAgendamento.substring(6, 10);

      const printer = formatPrinterName(data.printer);

      try {
        await api.post('/fila/printqrcode', {
          data: `${year}-${month}-${day}`,
          agendamentoid: data.agendamentoid,
          printerName: printer,
        });

        // Close the modal
        setIsOpen();

        // Show Toast for success
        addToast({
          type: 'success',
          title: 'Sucesso.',
          description: `Reimpressão QRCode realizada com sucesso!`,
        });

        // reset form
        reset();
      } catch (err: any) {
        // Close the modal
        setIsOpen();

        // Show Toast for failure
        addToast({
          type: 'error',
          title: 'Erro ao executar reimpressão.',
          description: `${err.response.data.message}`,
        });

        // reset form
        reset();
      }
    };

    return (
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '1rem',
            gap: '1rem',
          }}
        >
          <h1
            style={{
              fontWeight: '500',
              fontSize: '36px',
              lineHeight: '36px',
              marginBottom: '40px',
              color: '#00a859',
              textAlign: 'center',
            }}
          >
            Reimprimir QR Code
          </h1>

          <MaskedInput
            {...register('data', { required: 'Data obrigatória.' })}
            control={control}
            type="date"
            name="data"
            label="Data"
            onBlur={() => {}}
            error={!!errors.data}
            autoComplete="off"
            sx={{ width: '237px' }}
          />

          <MaskedInput
            {...register('agendamentoid', {
              required: 'Agendamento ID obrigatório.',
            })}
            control={control}
            type="none"
            name="agendamentoid"
            label="Agendamento ID"
            onBlur={() => {}}
            error={!!errors.agendamentoid}
            autoComplete="off"
            sx={{ width: '237px' }}
          />

          <MuiSelect
            {...register('printer', {
              required: false,
            })}
            control={control}
            name="printer"
            label="Printer"
            variant="outlined"
            options={printerOptions}
            error={Boolean(errors.printer)}
            ref={ref}
            sx={{ width: '237px' }}
          />

          <ButtonGroup>
            <button type="submit" name="SaveButton">
              <div className="text">Imprimir</div>
              <div className="icon">
                <RiPrinterLine size={24} />
              </div>
            </button>
          </ButtonGroup>
        </form>
      </Modal>
    );
  },
);

ModalPrintQrCode.displayName = 'ModalPrintQrCode';

export default ModalPrintQrCode;
