import styled from 'styled-components';

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  > .CancelButton {
    background: #bf1319;

    > .icon {
      display: flex;
      padding: 16px 16px;
      background: #bf1319;
      border-radius: 0 8px 8px 0;
      margin: 0 auto;
    }
  }

  button {
    font-weight: 600;
    border-radius: 8px;
    border: 0;
    background: #00a859;
    color: #fff;
    display: flex;
    flex-direction: row;
    margin-top: 48px;

    .text {
      padding: 16px 24px;
    }

    .icon {
      display: flex;
      padding: 16px 16px;
      background: #00a859;
      border-radius: 0 8px 8px 0;
      margin: 0 auto;
    }
  }
`;
