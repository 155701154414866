import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FaWhatsapp, FaPhoneAlt } from 'react-icons/fa';

import { Container } from './styles';

import api from '../../services/api';

interface Jobs {
  id: string;
  jobId: number;
  jobTitle: string;
  shift: string;
  education: string;
  contract: string;
  description: string;
  open: boolean;
}

// interface ILocation {
//   id: string;
// }

function JobsPage(): ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const [jobsEdit, setJobsEdit] = useState<Jobs[]>([]);

  const id = location.state;

  useEffect(() => {
    async function loadJobs(): Promise<void> {
      // Querying api and setting Registries
      await api.get(`/jobs/byid/${id}`).then(response => {
        setJobsEdit([response.data]);
      });
    }

    loadJobs();
  }, [id]);

  return (
    <Container>
      <header>
        <h1>Bom Sucesso Agroindústria S.A</h1>

        <nav>
          <button type="submit" onClick={() => navigate(-1)}>
            Página Inicial
          </button>
        </nav>
      </header>

      {jobsEdit &&
        jobsEdit.map(item => (
          <div className="jobWrapper" key={item.id}>
            <div className="jobContainer">
              <p id="jobTitle">{item.jobTitle}</p>
              <p id="jobDescription">{item.description}</p>

              <p id="jobShiftLabel">
                <strong>Turno</strong>
              </p>
              <p id="jobShift">{item.shift}</p>

              <p id="jobFormationLabel">
                <strong>Formação</strong>
              </p>
              <p id="jobFormation">{item.education}</p>

              <p id="jobOtherLabel">
                <strong>Requisitos</strong>
              </p>
              <p id="jobCity">Disponibilidade para residir em: Goiatuba/GO</p>
              <p id="jobOther">
                Ter experiência em Análise de Sistemas, PL/SQL, Oracle, PIMS,
                Senior, Mega
              </p>

              <p id="jobBenefitsLabel">Benefícios</p>
              <p id="jobBenefits">
                Convênio Médico e Odontológico, Seguro de Vida em Grupo,
                Transporte e Vale Alimentação
              </p>

              <footer>
                <div id="separator" />
                <h3>
                  Interessados enviar curriculum para:{' '}
                  <a
                    id="emailRecruter"
                    href="mailto:recrutamento@bsabioenergia.com.br"
                  >
                    recrutamento@bsabioenergia.com.br
                  </a>
                </h3>

                <h4>
                  Colocar no assunto do e-mail, o nome da vaga de interesse.
                </h4>

                <h5>Informações:</h5>
                <div className="contactHolder">
                  <p>
                    <FaWhatsapp size={26} color="#00a859" /> (62) 99455-6945
                  </p>
                  <p>
                    <FaPhoneAlt size={24} color="#00a859" /> (64) 3608-6600
                  </p>
                </div>
              </footer>
            </div>
          </div>
        ))}
    </Container>
  );
}

export default JobsPage;
