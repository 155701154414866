import React, { ReactElement } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  FaFlask,
  FaMoneyCheckAlt,
  FaTruck,
  FaUserLock,
  FaUserTie,
} from 'react-icons/fa';

import { Container, CardContainer, Card } from './styles';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

function AdminCli(): ReactElement {
  const { signOut, user } = useAuth();
  const { addToast } = useToast();
  const navigate = useNavigate();

  if (user.transportadora === true) {
    addToast({
      type: 'error',
      title: 'Acesso Restrito.',
      description: 'Disponível apenas para usuários Bom Sucesso.',
    });

    navigate('/portal');
  }

  return (
    <Container>
      <header>
        <h1>Bom Sucesso Agroindústria S.A</h1>

        <nav>
          <button type="submit" onClick={() => navigate('/')}>
            Página Inicial
          </button>

          <button type="submit" onClick={signOut}>
            Logout
          </button>
        </nav>
      </header>
      <h2 id="pageTitle">Acesso Admin</h2>

      <h2 id="selectOption">Selecione uma opção</h2>

      <CardContainer>
        {/* Admin Card - only accessible to user.admin */}
        <Card onClick={() => (user.admin ? navigate('/paineladmin') : null)}>
          <h1 className="painelAdmin" data-testid="painelAdmin">
            Painel Admin
          </h1>
          <FaUserLock size={38} color="#fff" />
        </Card>

        {/* Logistica Card - only accessible to user.logistica */}
        <Card
          onClick={() => (user.logistica ? navigate('/painellogistica') : null)}
        >
          <h1 className="painelLogistica" data-testid="painelLogistica">
            Painel Logística
          </h1>
          <FaTruck size={38} color="#fff" />
        </Card>

        {/* RH Card - Only Accessible for user.rh */}
        <Card onClick={() => (user.rh ? navigate('/painelrh') : null)}>
          <h1 className="painelRH" data-testid="painelRh">
            Painel RH
          </h1>
          <FaUserTie size={38} color="#fff" />
        </Card>

        {/* Faturamento Card - Only Accessible for user.faturamento */}
        <Card
          onClick={() =>
            user.faturamento ? navigate('/painelfaturamento') : null
          }
        >
          <h1 className="painelFaturamento" data-testid="painelFaturamento">
            Faturamento
          </h1>
          <FaMoneyCheckAlt size={38} color="#fff" />
        </Card>

        {/* Laboratorio Card - Only Accessible for user.laboratorio */}
        <Card
          onClick={() =>
            user.laboratorio ? navigate('/painellaboratorio') : null
          }
        >
          <h1 className="painelLaboratorio" data-testid="painelLaboratorio">
            Laboratorio
          </h1>
          <FaFlask size={38} color="#fff" />
        </Card>
      </CardContainer>
    </Container>
  );
}

export default AdminCli;
