/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';

import { RiDeleteBin5Line } from 'react-icons/ri';

import { ButtonGroup } from '../../../styles/customButtonGroup';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import Modal from '../../Modal';
import { useForm } from 'react-hook-form';
import { MaskedInput } from '../../Material/MaskedInput';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

interface IFormData {
  faturamentoid: number;
}

function ModalOrdemVendaSync({ isOpen, setIsOpen }: IModalProps): ReactElement {
  const { addToast } = useToast();

  const {
    register,
    control,
    reset,
    handleSubmit,
    // getValues,
    formState: { errors },
  } = useForm<IFormData>();

  const onSubmit = async (data: IFormData) => {
    if (!data || data === undefined) {
      return;
    }

    try {
      const ordensVenda = await api
        .get('/ordensvenda/byfaturamentoid', {
          params: {
            faturamentoid: data.faturamentoid,
          },
        })
        .then(response => {
          return response.data;
        })
        .catch((err: any) => {
          return err;
        });

      if (ordensVenda) {
        addToast({
          type: 'error',
          title: 'Erro.',
          description: `Faturamento id existente.`,
        });
        return;
      }

      // Save faturamentoID to sync with SAP
      await api
        .post('ordensvenda', {
          faturamentoid: data.faturamentoid,
          carregamentoid: 0,
          agendamentoid: 0,
          pedido: 0,
          tipopedido: 'P',
          volume: 0,
        })
        .then(() => {
          // Close the modal
          setIsOpen();

          // Show Toast for success
          addToast({
            type: 'success',
            title: 'Sucesso.',
            description: `Sync ordem venda criada com sucesso!`,
          });
        })
        .catch((err: any) => {
          throw new Error('Não foi possível criar ordem venda', err);
        });

      // reset form
      reset();
    } catch (err: any) {
      // Show Toast for failure
      addToast({
        type: 'error',
        title: 'Erro ao criar sync ordem venda.',
        description: `${err.response.data.message}`,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} onCloseModal={() => reset()}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
          gap: '1rem',
        }}
      >
        <h1
          style={{
            fontWeight: '500',
            fontSize: '36px',
            lineHeight: '36px',
            marginBottom: '40px',
            color: '#00a859',
            textAlign: 'center',
          }}
        >
          Sincronizar faturamento id com SAP
        </h1>

        <MaskedInput
          {...register('faturamentoid', {
            required: 'Faturamento Id obrigatório.',
          })}
          control={control}
          type="numbers"
          name="faturamentoid"
          label="Faturamento ID"
          onBlur={() => {}}
          error={!!errors.faturamentoid}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <ButtonGroup>
          <button type="submit" name="SaveButton">
            <div className="text">Salvar</div>
            <div className="icon">
              <RiDeleteBin5Line size={24} />
            </div>
          </button>
        </ButtonGroup>
      </form>
    </Modal>
  );
}

export default ModalOrdemVendaSync;
