import React, { ReactElement, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { FaList } from 'react-icons/fa';

import { Container, CardContainer, Card } from './styles';

import { useAuth } from '../../hooks/auth';
import { Box, CircularProgress } from '@mui/material';
import ModalCertificadoQualidade from '../../components/Modals/ModalCertificadoQualidade';

function PainelLaboratorio(): ReactElement {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const [certificadoQualidadeModalOpen, setCertificadoQualidadeModalOpen] =
    useState(false);

  const [loadingCertificadoQualidade, setLoadingCertificadoQualidade] =
    useState(false);

  async function toogleCertificadoQualidadeModal(): Promise<void> {
    setLoadingCertificadoQualidade(true);

    setCertificadoQualidadeModalOpen(!certificadoQualidadeModalOpen);
    setLoadingCertificadoQualidade(false);
  }

  return (
    <Container>
      <ModalCertificadoQualidade
        isOpen={certificadoQualidadeModalOpen}
        setIsOpen={() => toogleCertificadoQualidadeModal()}
      />

      <header>
        <h1>Bom Sucesso Agroindústria S.A</h1>

        <nav>
          <button type="submit" onClick={() => navigate('/')}>
            Página Inicial
          </button>

          <button type="submit" onClick={() => navigate('/admin')}>
            Voltar
          </button>

          <button type="submit" onClick={signOut}>
            Logout
          </button>
        </nav>
      </header>
      <h2 id="pageTitle">Painel Laboratório</h2>

      <h2 id="selectOption">Selecione uma opção</h2>

      <CardContainer>
        <Card
          style={{ width: '344px' }}
          onClick={() => toogleCertificadoQualidadeModal()}
        >
          <h1>Certificado Qual. Etanol</h1>
          {loadingCertificadoQualidade ? (
            <Box width="100%" display="flex" justifyContent="center">
              <CircularProgress size={38} sx={{ color: '#fff' }} />
            </Box>
          ) : (
            <FaList size={38} color="#fff" />
          )}
        </Card>
      </CardContainer>
    </Container>
  );
}

export default PainelLaboratorio;
