/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';

import { FiCheckSquare } from 'react-icons/fi';

import { ButtonGroup } from '../../styles/customButtonGroup';
import Modal from '../Modal';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

import { SubmitHandler, useForm } from 'react-hook-form';
import { MaskedInput } from '../Material/MaskedInput';
import MuiSelect from '../Material/Select';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}
interface IFormData {
  data: Date;
  agendamentoid: number;
  placa: string;
  statusnew: string;
  motivo: string;
}

function ModalChangeStatusFila({
  isOpen,
  setIsOpen,
}: IModalProps): ReactElement {
  const { addToast } = useToast();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormData>();

  const selectOptions = [
    '1 - Aguardando Pedido',
    '2 - Aguardando Pagamento Pedido',
  ];

  const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
    // Formatting the data
    const toFormatString = String(data.data);
    const day = toFormatString.substring(0, 2);
    const month = toFormatString.substring(3, 5);
    const year = toFormatString.substring(6, 10);

    try {
      await api.post('/fila/status', {
        data: `${year}-${month}-${day}`,
        agendamentoid: data.agendamentoid,
        placa: data.placa.toUpperCase(),
        statusnew:
          data.statusnew === '1 - Aguardando Pedido'
            ? 'AGPE'
            : data.statusnew === '2 - Aguardando Pagamento Pedido'
            ? 'APPE'
            : null,
        motivo: data.motivo,
      });

      // Close the modal
      setIsOpen();

      // Show Toast for success
      addToast({
        type: 'success',
        title: 'Status Atualizado',
        description: `O registro foi inserido com sucesso!`,
      });
      // resetForm
      reset();
    } catch (err: any) {
      // Close the modal
      setIsOpen();

      // Show Toast for failure
      addToast({
        type: 'error',
        title: 'Erro ao saldo status.',
        // description: 'Verifique se os dados informados estão corretos.',
        description: `${err.response.data.message}`,
      });

      // resetForm
      reset();
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
          gap: '1rem',
        }}
      >
        <h1
          style={{
            fontWeight: '500',
            fontSize: '36px',
            lineHeight: '36px',
            marginBottom: '40px',
            color: '#00a859',
            textAlign: 'center',
          }}
        >
          Alterar Status Fila
        </h1>

        <MaskedInput
          {...register('data', { required: 'Data obrigatória.' })}
          control={control}
          type="date"
          name="data"
          label="Data"
          onBlur={() => {}}
          error={!!errors.data}
          autoComplete="off"
          sx={{ width: '340px' }}
        />

        <MaskedInput
          {...register('agendamentoid', {
            required: 'Agendamento id obrigatório.',
          })}
          control={control}
          type="none"
          name="agendamentoid"
          label="Agendamento ID"
          onBlur={() => {}}
          error={!!errors.agendamentoid}
          autoComplete="off"
          sx={{ width: '340px' }}
        />

        <MaskedInput
          {...register('placa', { required: 'Placa obrigatória.' })}
          control={control}
          type="placa"
          name="placa"
          label="Placa"
          onBlur={() => {}}
          error={!!errors.placa}
          autoComplete="off"
          sx={{ width: '340px' }}
        />

        <MuiSelect
          {...register(
            'statusnew',

            { required: 'Novo status obrigatório.' },
          )}
          control={control}
          label="Novo Status"
          name="statusnew"
          options={selectOptions}
          variant="outlined"
          sx={{ width: '340px' }}
          error={!!errors.statusnew}
        />

        <MaskedInput
          {...register('motivo', {
            required: 'Motivo obrigatório.',
          })}
          control={control}
          type="none"
          name="motivo"
          label="Motivo"
          onBlur={() => {}}
          error={!!errors.motivo}
          autoComplete="off"
          sx={{ width: '340px', height: '80px' }}
          inputProps={{ maxLength: 300 }}
          multiline={true}
        />

        <ButtonGroup>
          <button type="submit" name="SaveButton">
            <div className="text">Salvar</div>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>
        </ButtonGroup>
      </form>
    </Modal>
  );
}

export default ModalChangeStatusFila;
