import styled from 'styled-components';
import { Form as Unform } from '@unform/web';

export const Form = styled(Unform)`
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    align-self: flex-start;
    margin-top: 2px;
  }

  > input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #f4ede8;

    background: #272727;
    border-radius: 10px;
    border: 2px solid #272727;
    padding: 16px;
    width: 100%;
    color: #fff;

    margin-top: 10px;
    margin-bottom: 10px;

    display: flex;
    align-items: center;

    &::placeholder {
      color: #666360;
    }
  }

  /* title of modal */
  h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 40px;
    color: #00a859;
  }

  select {
    background: transparent;
    text-decoration: none;
    outline: none;
    border: transparent;
    color: #666360;

    background: #272727;
    border-radius: 10px;
    border: 2px solid #272727;
    width: 50%;
    height: 28px;
    font-size: 16px;
    font-family: 'Poppins';

    margin-top: 5px;
    margin-bottom: 10px;

    display: flex;
    align-items: center;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;

  button {
    font-weight: 600;
    border-radius: 8px;
    border: 0;
    background: #00a859;
    color: #fff;

    display: flex;
    flex-direction: row;

    margin-top: 48px;

    .text {
      padding: 16px 24px;
    }

    .icon {
      display: flex;
      padding: 16px 16px;
      background: #00a859;
      border-radius: 0 8px 8px 0;
      margin: 0 auto;
    }
  }
`;
