import styled from 'styled-components';
import { Form as Unform } from '@unform/web';

export const Form = styled(Unform)`
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  text-align: center;

  > input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #f4ede8;

    background: #171412;
    border-radius: 10px;
    border: 2px solid #171412;
    padding: 16px;
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;

    &::placeholder {
      color: #666360;
    }
  }

  select {
    flex: 1;
    background: transparent;
    border: transparent;
    color: #f4ede8;
    outline: none;

    background: #272727;
    border-radius: 10px;
    border: 2px solid #272727;
    padding: 16px;
    width: 100%;
    height: 99%;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;

    display: flex;
    align-items: center;
  }

  h1 {
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 0;
    font-weight: 600;
    color: #00a859;
  }

  label {
    color: #00a859;
    font-size: 12px;
    margin-left: 18px;
    text-align: left;
  }

  & > label {
    margin-top: 5px;
  }
`;
