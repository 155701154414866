/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { FiEdit, FiTrash2 } from 'react-icons/fi';

import { useAuth } from '../../hooks/auth';

import { Container, TableContainer } from './styles';

import ModalEditMotoristas from '../../components/ModalEditMotoristas';

import api from '../../services/api';

interface IMotoristasData {
  motoristaid: number;
  cnh: number;
  nome: string;
  cpf: string;
  ativo: boolean;
  blacklist: boolean;
  createdat: Date;
  updatedat: Date;
}

interface IDeleteMotorista {
  nome: string;
  cpf: string;
}

function ManutencaoMotoristas(): ReactElement {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  // const [rowsPerPage, setRowsPerPage] = useState(5);

  const [motoristas, setMotoristas] = useState<IMotoristasData[]>([]);
  const [reloadMotoristasData, setReloadMotoristasData] = useState(false);

  // MODAL STATES
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingMotorista, setEditingMotorista] = useState<IMotoristasData>(
    {} as IMotoristasData,
  );

  const [motoristaToDelete, setMotoristaToDelete] = useState<IDeleteMotorista>(
    {} as IDeleteMotorista,
  );
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openCantDeleteMotorista, setOpenCantDeleteMotorista] = useState(false);

  const screenHeight = `${window.innerHeight - 150}px `;
  const screenWidth = `${window.innerWidth}px`;

  // Toogle modal edit
  function toggleEditModal(): void {
    setEditModalOpen(!editModalOpen);

    if (!editModalOpen === false) {
      setReloadMotoristasData(true);
    }
  }

  // Load Motoristas Information
  useEffect(() => {
    async function loadMotoristas(): Promise<void> {
      await api.get(`/motoristas`).then(response => {
        setMotoristas(response.data);
      });
    }
    loadMotoristas();

    if (reloadMotoristasData) {
      loadMotoristas();
      setReloadMotoristasData(false);
    }
  }, [reloadMotoristasData]);

  // Handle the sending data to edit registries
  async function handleEditMotoristas(
    motoristaData: IMotoristasData,
  ): Promise<void> {
    const motoristaToEdit = await api.get(`/motoristas/${motoristaData.cpf}`);

    setEditingMotorista(motoristaToEdit.data);
    toggleEditModal();
  }

  async function handleConfirmationDeleteMotoristas(
    nome: string,
    cpf: string,
  ): Promise<void> {
    const foundMotorista = await api.get(`/motoristas/${cpf}`);

    if (!foundMotorista) {
      throw new Error('Motorista Não encontrado');
    }

    const foundAgendamentos = await api.get(
      `/agendamentos/bycpfmotorista/${cpf}`,
    );

    // IN CASE OF SCHEDULE FOUND THEN INFORM BY DIALOG
    if (foundAgendamentos.data.length > 0) {
      setMotoristaToDelete({ nome, cpf });
      setOpenCantDeleteMotorista(true); // OPEN DIALOG CONFIRMATION
    }

    // DIRECT DELETE IN CASE NO SCHEDULE FOUND
    if (foundAgendamentos.data.length === 0) {
      setMotoristaToDelete({ nome, cpf });
      setOpenConfirmDelete(true); // OPEN DIALOG CONFIRMATION
    }
  }

  const handleConfirmDeleteClose = () => {
    setOpenConfirmDelete(false);
  };

  const handleCantDeleteMotoristaClose = () => {
    setOpenCantDeleteMotorista(false);
  };

  async function handleDeleteMotorista(cpf: string) {
    setOpenConfirmDelete(false);
    await api.delete(`/motoristas/${cpf}`);
    window.location.reload();
  }

  return (
    <Container>
      {editModalOpen ? (
        <ModalEditMotoristas
          isOpen={editModalOpen}
          setIsOpen={toggleEditModal}
          editingMotorista={editingMotorista}
        />
      ) : null}

      {/* CONFIRM DELETE MOTORISTA */}
      <Dialog
        open={openConfirmDelete}
        onClose={handleConfirmDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirmar exclusão de Motorista?
        </DialogTitle>{' '}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja confirmar a exclusão do Motorista
            <strong style={{ color: 'red' }}>
              {' '}
              {motoristaToDelete.nome} - {motoristaToDelete.cpf}
            </strong>
            ? Após a exclusão deste cadastro não será possível realizar
            agendamentos/carregamentos para este motorista.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteClose}>Cancelar</Button>
          <Button
            style={{ color: 'red', fontWeight: '600' }}
            onClick={() => handleDeleteMotorista(motoristaToDelete.cpf)}
            autoFocus
          >
            Deletar
          </Button>
        </DialogActions>
      </Dialog>

      {/* DIALOG CANT DELETE MOTORISTA */}
      <Dialog
        open={openCantDeleteMotorista}
        onClose={handleCantDeleteMotoristaClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <strong style={{ color: 'red' }}>
            Impossível Excluir Motorista !
          </strong>
        </DialogTitle>{' '}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Encontramos agendamentos realizados para o motorista{' '}
            <strong style={{ color: 'black' }}>
              {' '}
              {motoristaToDelete.nome} - {motoristaToDelete.cpf}
            </strong>
            , a exclusão deste cadastro poderá gerar problemas em registros
            futuros. Sendo assim{' '}
            <strong style={{ color: 'red' }}>não permitiremos</strong> a
            exclusão deste cadastro.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCantDeleteMotoristaClose}>Cancelar</Button>
        </DialogActions>
      </Dialog>

      <header>
        <h1>Bom Sucesso Agroindústria S.A</h1>

        <nav>
          <button type="submit" onClick={() => navigate('/')}>
            Página Inicial
          </button>

          <button type="submit" onClick={() => navigate('/paineladmin')}>
            Voltar
          </button>

          <button type="submit" onClick={signOut}>
            Logout
          </button>
        </nav>
      </header>
      <h2 id="pageTitle">Manutenção Motoristas</h2>

      {/* Table */}
      <TableContainer
        style={{ maxHeight: screenHeight, maxWidth: screenWidth }}
      >
        <table className="table table-hover table-borderless">
          <thead>
            <tr>
              <th>Motorista Id</th>
              <th>Nome</th>
              <th>CNH</th>
              <th>CPF</th>
              <th>Ativo</th>
              <th>Blacklist</th>
              <th>Ações</th>
            </tr>
          </thead>

          {motoristas.map(motoristasData => (
            <tbody key={motoristasData.motoristaid}>
              <tr>
                <td>{motoristasData.motoristaid}</td>
                <td>{motoristasData.nome}</td>
                <td>{motoristasData.cnh}</td>
                <td>{motoristasData.cpf}</td>
                <td>{motoristasData.ativo ? 'Sim' : 'Não'}</td>
                <td>{motoristasData.blacklist ? 'Sim' : 'Não'}</td>

                <td className="actionButtons">
                  {/* Edit registry button */}
                  <button
                    type="button"
                    onClick={() => handleEditMotoristas(motoristasData)}
                  >
                    <FiEdit size={20} color="#08a818" />{' '}
                  </button>

                  {/* Delete veículos button */}
                  <button
                    type="button"
                    onClick={() =>
                      handleConfirmationDeleteMotoristas(
                        motoristasData.nome,
                        motoristasData.cpf,
                      )
                    }
                  >
                    <FiTrash2 size={20} color="#c2180c" />{' '}
                  </button>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </TableContainer>
    </Container>
  );
}

export default ManutencaoMotoristas;
