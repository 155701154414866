import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: block;
  width: 100vw !important;
  height: 100vmin !important;
  background: #fff;
  position: absolute;

  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 10px;
    z-index: 99;
    position: relative;

    h1 {
      color: #00a859;
      font-weight: 600;
    }

    nav {
      button {
        border: 1px solid #00a859;
        background-color: transparent;
        border-radius: 4px;
        color: #171412;
        font-weight: 600;
        font-family: 'Poppins';
        height: 40px;
        padding-left: 8px;
        padding-right: 8px;
      }

      > button {
        margin-left: 10px;
      }

      & :hover {
        cursor: pointer;
        color: #00a859;
      }
    }
  } /* END HEADER */

  #pageTitle {
    justify-content: center;
    text-align: center;
    color: #00a859;
    font-weight: 600;
    margin: 0 auto;

    :after {
      display: block;
      text-align: center;
      margin: 0 auto;
      content: '';
      width: 40%;
      border-bottom: 3px solid #00a859;
      animation: 1s ease-in 0s 1 slideInFromLeft;
    }

    @keyframes slideInFromLeft {
      0% {
        transform: translateX(-40%);
      }
      100% {
        transform: translateX(0);
      }
    }
  }

  #formContainer {
    height: 180px;
    display: grid;
    grid-template-columns: repeat(4, 220px);
    align-items: center;
    justify-content: center;

    #inputData {
      background: #272727;
      border-radius: 10px;
      border: 2px solid #272727;
      padding: 16px;
      width: 100%;
      color: #666360;

      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;

      /* Every input that is followed by another input will have */
      & + div {
        margin-top: 10px;
      }

      color: #f4ede8;
      outline: none;

      &::placeholder {
        color: #666360;
      }
    }

    @media (max-width: 1440px) {
      margin-right: 6rem;
    }
  }

  /* titulo saldo pedidos - Table */
  #saldoPedidosTitle,
  #saldoNaoEncontradoTitle {
    display: flex;
    justify-content: center;
    color: #00a859;
    font-weight: 600;
    width: 100%;
    position: relative;
    /* top: 45%; */
  }

  /* ***************************************************************** */
  @media (max-height: 825px) {
    /* titulo saldo pedidos - Table */
    #saldoPedidosTitle,
    #saldoNaoEncontradoTitle {
      display: flex;
      justify-content: center;
      color: #00a859;
      font-weight: 600;
      width: 100%;
      position: absolute;
      top: 53%;
    }
  }
  /* ***************************************************************** */
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;

  button {
    font-weight: 600;
    border-radius: 8px;
    border: 0;
    background: #00a859;
    color: #fff;

    display: flex;
    flex-direction: row;

    .text {
      padding: 16px 24px;
    }

    .icon {
      display: flex;
      padding: 16px 16px;
      background: #00a859;
      border-radius: 0 8px 8px 0;
      margin: 0 auto;
    }
  }

  /* Volume Total */
  #volumeTotal {
    font-weight: 600;
    display: flex;
    align-self: center;
    margin-left: 30%;
    margin-top: 1rem;
    position: absolute;
    font-size: 1.4rem;
    color: #00a859;
  }
`;

export const TableContainer = styled.section`
  /* max-height: 350px !important; */
  width: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;

  padding-left: 20px;
  padding-right: 20px;

  position: relative;
  /* top: 50%; */

  /* ***************************************************************** */
  @media (max-height: 825px) {
    max-height: 350px !important;
    width: 100% !important;
    overflow-y: auto;
    overflow-x: hidden;

    padding-left: 20px;
    padding-right: 20px;

    position: absolute;
    top: 58%;
  }
  /* ***************************************************************** */

  table {
    width: 100%;
    height: 100%;
    border-spacing: 0 8px;
    white-space: nowrap;
    justify-content: center;
    border-collapse: collapse;

    thead th {
      height: 30px;
      top: 0;
      position: sticky;
      z-index: 2;
      background: #fff;
    }

    #nullPedidos {
      border: 1px solid red;
    }

    th {
      text-align: center;
    }

    td {
      padding: 8px 0px;
      border: 0;
      background: #fff;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      color: #969cb3;
      border-bottom: 1px solid #969cb3;

      &.cnDtEmissao {
        width: 108px;
      }

      &.cnPedSca {
        width: 100px !important;
      }

      &.cnPedBsa {
        width: 100px;
      }

      &.cnCliente {
        width: 360px !important;
        text-align: center;
      }

      &.cnUf {
        width: 50px;
      }

      &.cnProduto {
        width: 150px;
      }

      &.cnStatus {
        width: 130px;
      }

      &.cnUnidade {
        width: 100px;
      }

      &.cnSaldo {
        width: 130px;
      }

      /* Edit and Delete Button */
      button {
        border: transparent;
        background: none;
        margin-right: 10px;

        svg {
          display: flex;
        }
      }
    }

    td:first-child {
      border-radius: 8px 0 0 8px;
    }

    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(9, auto);
  padding-right: 20px;
  padding-left: 20px;
  overflow-x: hidden;

  bottom: 340px;
  position: absolute;
  width: 100% !important;
  max-height: 350px !important;

  table {
    width: 100%;
    height: 100%;
    border-spacing: 0 8px;
    white-space: nowrap;
    justify-content: center;

    thead {
      height: 0px;
      margin-top: 0px;
    }

    th {
      text-align: center;
    }

    td {
      padding: 8px 0px;
      border: 0;
      background: #fff;
      text-align: center;
      font-size: 14px;
      font-weight: 600;

      &.dataEmissao {
        width: 138px !important;
      }

      &.pedidoSca {
        width: 179px;
      }

      &.pedidoBsa {
        width: 128px;
      }

      &.cliente {
        width: 600px;
        text-align: center;
      }

      &.uf {
        width: 50px;
      }

      &.produto {
        width: 150px;
      }

      &.status {
        width: 130px;
      }

      &.unidade {
        width: 100px;
      }

      &.saldoAberto {
        width: 130px;
      }

      /* Edit and Delete Button */
      button {
        border: transparent;
        background: none;
        margin-right: 10px;

        svg {
          display: flex;
        }
      }
    }

    td:first-child {
      border-radius: 8px 0 0 8px;
    }

    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;

export const Calendar = styled.aside`
  position: absolute;
  right: 5px;
  top: 4rem;

  .DayPicker {
    background: #272727;
    border-radius: 10px;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
    padding-top: 0;
  }

  .DayPicker,
  .DayPicker-Month {
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 2px;
    color: #00a859;
  }

  .DayPicker-Day {
    width: 10px;
    height: 10px;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #666360;
    border-radius: 10px;
    color: #fff;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--selected
    ):not(.DayPicker-Day--outside):hover {
    background: ${shade(0.2, '#666360')};
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: #666360 !important;
    background: transparent !important;
  }

  .DayPicker-Day--selected {
    background: #00a859 !important;
    border-radius: 10px;
  }
`;
