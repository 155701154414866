export const cnpjJustNumbers = (cnpj: string): string => {
  const formattedCnpj = cnpj.replace(/[^0-9a-zA-Z]+/gm, '');

  return formattedCnpj;
};

export const formatCnpjWithChars = (cnpj: string): string => {
  const formattedCnpj = cnpj.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '$1.$2.$3/$4-$5',
  );
  return formattedCnpj;
};
