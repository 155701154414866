/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export type InputProps = OutlinedInputProps & {
  name: string;
  label: string;
  icon?: ReactElement;
  control?: any;
  errorMessage?: string;
  shouldShrink?: boolean;
};

export function InputWithIcon({
  name,
  label,
  sx,
  icon,
  inputProps,
  errorMessage,
  shouldShrink,
  ...outlineInputProps
}: InputProps) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleTypes = (type: string) => {
    if (type === 'password') {
      if (showPassword) {
        return 'text';
      } else {
        return 'password';
      }
    } else {
      return type;
    }
  };

  return (
    <FormControl
      variant="outlined"
      error={!!outlineInputProps.error}
      size={outlineInputProps.size}
      sx={{
        zIndex: 0,
      }}
    >
      <InputLabel
        htmlFor={`inputWithIcon-${name}`}
        sx={{
          ...(outlineInputProps.disabled && {
            opacity: 0.3,
          }),
        }}
        {...(shouldShrink && { shrink: true })}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        {...outlineInputProps}
        type={
          outlineInputProps.type === undefined
            ? 'text'
            : handleTypes(outlineInputProps.type)
        }
        endAdornment={
          outlineInputProps.type === 'password' ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ) : (
            <InputAdornment
              position="end"
              sx={{ alignSelf: 'center', height: 1 }}
            >
              {icon}
            </InputAdornment>
          )
        }
        name={name}
        label={label}
        error={outlineInputProps.error}
        inputProps={{
          ...inputProps,
          style: {
            textTransform: inputProps?.textTransform || 'none',
          },
        }}
        sx={{
          ...sx,
          // minWidth: '200px',
          // maxWidth: '200px',
          fontSize: '1rem',
          '&.Mui-disabled': {
            opacity: 0.3,
          },
        }}
      />
      {outlineInputProps.error && (
        <FormHelperText>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
}
