/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { useRef, useCallback, ReactElement } from 'react';
import { FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';

import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Button from '../../components/Button';
import logo200 from '../../assets/logobsawhite.png';

import { Container, Content, AnimationContainer, Background } from './styles';

import api from '../../services/api';

interface ResetPasswordFormData {
  password: string;
  passwordConfirmation: string;
}

function ResetPassword(): ReactElement {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string().required('Password is required'),
          passwordConfirmation: Yup.string().oneOf(
            [Yup.ref('password'), undefined],
            'Incorrect Password',
          ),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const { password, passwordConfirmation } = data;

        const token = window.localStorage.getItem('@Bsa:refreshToken');

        await api.post('/password/reset', {
          password,
          passwordConfirmation,
          token,
        });

        addToast({
          type: 'success',
          title: 'Alteração de Senha',
          description: 'Senha alterada com sucesso.',
        });

        navigate('/');
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Reset Password error',
          description: `${err.response.data.message}`,
        });
      }
    },
    [addToast, navigate],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logo200} alt="bsalogo" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Alterar Senha</h1>

            <Input
              name="password"
              icon={FiLock}
              type="password"
              placeholder="Nova Senha"
            />

            <Input
              name="passwordConfirmation"
              icon={FiLock}
              type="password"
              placeholder="Confirmar Senha"
            />

            <Button type="submit">Salvar</Button>
          </Form>

          <Link to="/">Retornar a página inicial</Link>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
}
export default ResetPassword;
