import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: block;
  width: 100vw !important;
  height: 100vmin !important;
  background: #fff;
  position: absolute;

  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 10px;
    z-index: 99;
    position: relative;

    h1 {
      color: #00a859;
      font-weight: 600;
    }

    nav {
      button {
        border: 1px solid #00a859;
        background-color: transparent;
        border-radius: 4px;
        color: #171412;
        font-weight: 600;
        font-family: 'Poppins';
        height: 40px;
        padding-left: 8px;
        padding-right: 8px;
      }

      > button {
        margin-left: 10px;
      }

      & :hover {
        cursor: pointer;
        color: #00a859;
      }
    }
  } /* END HEADER */

  #newJobButton {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    position: relative;

    border: none;
    border-radius: 4px;
    background: #00a859;
    color: #fff;
    width: 250px;
    font-weight: 600;
    outline: none;

    &:hover {
      background: ${shade(0.2, '#00A859')};
    }

    svg {
      margin-right: 20px;
    }
  }

  #pageTitle {
    justify-content: center;
    text-align: center;
    color: #00a859;
    font-weight: 600;
    margin: 0 auto;

    :after {
      display: block;
      text-align: center;
      margin: 0 auto;
      content: '';
      width: 40%;
      border-bottom: 3px solid #00a859;
      animation: 1s ease-in 0s 1 slideInFromLeft;
    }

    @keyframes slideInFromLeft {
      0% {
        transform: translateX(-40%);
      }
      100% {
        transform: translateX(0);
      }
    }
  }

  #formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const FormContainer = styled.div`
  display: flex !important;
  border: 1px solid brown;

  align-items: center !important;
  justify-content: flex-end !important;
  padding-right: 2rem;

  form {
    display: block;
    padding-top: 2rem;
    padding-bottom: 2rem;
    align-items: center;
    justify-content: center;

    border: 1px solid red;

    svg {
      align-self: flex-start;
    }
  }
`;

export const TableContainer = styled.section`
  max-height: 100%;
  overflow-y: visible;
  overflow-x: hidden;
  margin-top: 0px;

  padding-left: 20px;
  padding-right: 20px;

  table {
    width: 100%;
    border-spacing: 0 8px;
    white-space: nowrap;
    justify-content: center;
    margin-top: 30px;

    thead {
      height: 0px;
      margin-top: 0px;
    }

    th {
      text-align: center;
    }

    td {
      padding: 8px 0px;
      border: 0;

      background: #fff;
      text-align: center;
      font-size: 14px;
      font-weight: normal;
      color: #969cb3;

      &.jobId {
        width: 40px;
      }

      &.jobTitle {
        width: 240px;
        max-width: 300px;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &.jobDescription {
        width: 400px;
        max-width: 450px;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &.registryDate {
        width: 130px;
      }

      &.Buttons {
        width: 90px;
        padding-left: 10px;
      }

      /* Edit and Delete Button */
      button {
        border: transparent;
        background: none;
        margin-right: 10px;

        svg {
          display: flex;
        }
      }
    }

    td:first-child {
      border-radius: 8px 0 0 8px;
    }

    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;
