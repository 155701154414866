/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ReactElement } from 'react';

import { FiCheckSquare } from 'react-icons/fi';

import { ButtonGroup } from '../../styles/customButtonGroup';
import Modal from '../Modal';
import { useToast } from '../../hooks/toast';

import api from '../../services/api';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MaskedInput } from '../Material/MaskedInput';
import MuiSelect from '../Material/Select';

interface IEditingMotoristaData {
  motoristaid: number;
  nome: string;
  cnh: number;
  cpf: string;
  ativo: boolean;
  blacklist: boolean;
}

interface IFormData {
  motoristaid: number;
  nome: string;
  cnh: number;
  cpf: string;
  ativo: boolean | string;
  blacklist: boolean | string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  editingMotorista: IEditingMotoristaData;
}

function ModalEditMotoristas({
  isOpen,
  setIsOpen,
  editingMotorista,
}: IModalProps): ReactElement {
  const { addToast } = useToast();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormData>({
    values: {
      ...editingMotorista,
      ativo: editingMotorista.ativo ? 'Ativo' : 'Inativo',
      blacklist: editingMotorista.blacklist ? 'Sim' : 'Não',
    },
  });

  const selectOptionsAtivo = ['Ativo', 'Inativo'];
  const selectOptionsBlacklist = ['Sim', 'Não'];

  const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
    try {
      if (!data) {
        throw new Error('Dados do motorista não encontrados');
      }

      const motoristaAtivo = data.ativo === 'Ativo' ? true : false;
      const motoristaBlacklist = data.blacklist === 'Sim' ? true : false;

      // UPDATE MOTORISTA
      await api.put(`/motoristas/${editingMotorista.cpf}`, {
        nome: data.nome,
        cnh: data.cnh,
        cpf: data.cpf,
        ativo: motoristaAtivo,
        blacklist: motoristaBlacklist,
      });
      setIsOpen();
      // Show Toast for success
      addToast({
        type: 'success',
        title: 'Status Atualizado',
        description: `O registro foi inserido com sucesso!`,
      });

      // reset form
      reset();
    } catch (err: any) {
      addToast({
        type: 'error',
        title: 'Erro update motoristas',
        description: err.response.data.message,
      });
      setIsOpen();

      // reset form
      reset();
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
          gap: '1rem',
        }}
      >
        <h1>Editar Motorista</h1>

        <MaskedInput
          {...register('motoristaid', {
            required: 'Motorista ID obrigatório.',
          })}
          control={control}
          type="numbers"
          name="motoristaid"
          label="Motorista ID"
          onBlur={() => {}}
          error={!!errors.motoristaid}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MaskedInput
          {...register('nome', { required: 'Nome obrigatório.' })}
          control={control}
          type="none"
          name="nome"
          label="Nome"
          onBlur={() => {}}
          error={!!errors.nome}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MaskedInput
          {...register('cnh', { required: 'CNH obrigatória.' })}
          control={control}
          type="none"
          name="cnh"
          label="CNH"
          onBlur={() => {}}
          error={!!errors.cnh}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MaskedInput
          {...register('cpf', { required: 'CPF obrigatório.' })}
          control={control}
          type="cpf"
          name="cpf"
          label="CPF"
          onBlur={() => {}}
          error={!!errors.cpf}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MuiSelect
          {...register(
            'ativo',

            { required: 'Status obrigatório.' },
          )}
          control={control}
          label="Motorista Ativo"
          name="ativo"
          options={selectOptionsAtivo}
          variant="outlined"
          sx={{ width: '237px' }}
          error={!!errors.ativo}
        />

        <MuiSelect
          {...register(
            'blacklist',

            { required: 'Blacklist obrigatório.' },
          )}
          control={control}
          label="Blacklist"
          name="blacklist"
          options={selectOptionsBlacklist}
          variant="outlined"
          sx={{ width: '237px' }}
          error={!!errors.blacklist}
        />

        <ButtonGroup
          style={{ justifyContent: 'space-around', paddingBottom: '5px' }}
        >
          <button type="submit" name="SaveButton">
            <div className="text">Salvar</div>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>
        </ButtonGroup>
      </form>
    </Modal>
  );
}

export default ModalEditMotoristas;
