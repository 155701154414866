import React, { ReactElement } from 'react';

import { FiCheckSquare } from 'react-icons/fi';

import { ButtonGroup } from '../../styles/customButtonGroup';
import Modal from '../Modal';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MaskedInput } from '../Material/MaskedInput';
import dayjs from 'dayjs';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleUpdateAgendamentos: (
    agendamentos: IFormData,
    olddata: IFormData,
  ) => void;
  editingAgendamentos: IFormData;
}

interface IFormData {
  id: string;
  agendamentoid: number;
  data: Date | string;
  pedido: number;
  pedido2: number;
  cpfmotorista: string;
  placa: string;
  placa2: string;
  volume: number;
  volume2: number;
}

function ModalEditAgendamentos({
  isOpen,
  setIsOpen,
  editingAgendamentos,
  handleUpdateAgendamentos,
}: IModalProps): ReactElement {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormData>({
    values: {
      ...editingAgendamentos,
      data: dayjs(editingAgendamentos.data).add(1, 'D').format('DD/MM/YYYY'),
    },
  });

  const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
    handleUpdateAgendamentos(data, editingAgendamentos);
    setIsOpen();
    // reset form
    reset();
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
          gap: '1rem',
        }}
      >
        <h1
          style={{
            fontWeight: '500',
            fontSize: '36px',
            lineHeight: '36px',
            marginBottom: '40px',
            color: '#00a859',
            textAlign: 'center',
          }}
        >
          Editar Agendamento
        </h1>

        <MaskedInput
          {...register('data', { required: 'Data obrigatória.' })}
          control={control}
          type="date"
          name="data"
          label="Data"
          onBlur={() => {}}
          error={!!errors.data}
          autoComplete="off"
          sx={{ width: '400px' }}
        />

        <MaskedInput
          {...register('pedido', { required: 'Pedido obrigatório.' })}
          control={control}
          type="numbers"
          name="pedido"
          label="Pedido BSA"
          onBlur={() => {}}
          error={!!errors.pedido}
          autoComplete="off"
          sx={{ width: '400px' }}
        />

        <MaskedInput
          {...register('pedido2', { required: 'Pedido 2 obrigatório.' })}
          control={control}
          type="numbers"
          name="pedido2"
          label="Pedido 2 BSA"
          onBlur={() => {}}
          error={!!errors.pedido2}
          autoComplete="off"
          sx={{ width: '400px' }}
        />

        <MaskedInput
          {...register('cpfmotorista', { required: 'CPF obrigatório.' })}
          control={control}
          type="cpf"
          name="cpfmotorista"
          label="CPF Motorista"
          onBlur={() => {}}
          error={!!errors.cpfmotorista}
          autoComplete="off"
          sx={{ width: '400px' }}
        />

        <MaskedInput
          {...register('placa', { required: 'Placa carreta 1 obrigatória.' })}
          control={control}
          type="placa"
          name="placa"
          label="Placa carreta 1"
          onBlur={() => {}}
          error={!!errors.placa}
          autoComplete="off"
          sx={{ width: '400px' }}
        />

        <MaskedInput
          {...register('placa2', { required: false })}
          control={control}
          type="placa"
          name="placa2"
          label="Placa carreta 2"
          onBlur={() => {}}
          error={!!errors.placa2}
          autoComplete="off"
          sx={{ width: '400px' }}
        />

        <MaskedInput
          {...register('volume', { required: 'Volume carreta 1 obrigatório.' })}
          control={control}
          type="numbers"
          name="volume"
          label="Volume carreta 1"
          onBlur={() => {}}
          error={!!errors.volume}
          autoComplete="off"
          sx={{ width: '400px' }}
        />

        <MaskedInput
          {...register('volume2', {
            required: 'Volume carreta 2 obrigatório.',
          })}
          control={control}
          type="numbers"
          name="volume2"
          label="Volume carreta 2"
          onBlur={() => {}}
          error={!!errors.volume2}
          autoComplete="off"
          sx={{ width: '400px' }}
        />

        <ButtonGroup>
          <button type="submit" name="SaveButton">
            <div className="text">Salvar</div>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>
        </ButtonGroup>
      </form>
    </Modal>
  );
}

export default ModalEditAgendamentos;
