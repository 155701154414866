/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';

import { FiCheckSquare } from 'react-icons/fi';

import { ButtonGroup } from '../../styles/customButtonGroup';

import Modal from '../Modal';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import { SubmitHandler, useForm } from 'react-hook-form';
import { MaskedInput } from '../Material/MaskedInput';
import MuiSelect from '../Material/Select';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

interface IFormData {
  name: string;
  login: string;
  password: string;
  role: string;
  transportadora: boolean;
  admin: boolean;
  rh: boolean;
  logistica: boolean;
  faturamento: boolean;
  laboratorio: boolean;
  portaria: boolean;
}

function ModalInsertUsers({ isOpen, setIsOpen }: IModalProps): ReactElement {
  const { addToast } = useToast();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormData>();

  // Select options

  const roleOptions = [
    'Admin',
    'Transportadora',
    'Rh',
    'Logistica',
    'Faturamento',
    'Laboratorio',
    'Portaria',
  ].sort();

  const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
    try {
      await api.post('/users', {
        name: data.name.toUpperCase(),
        login: data.login,
        password: data.password,
        transportadora: data.role === 'Transportadora',
        admin: data.role === 'Admin',
        rh: data.role === 'Rh',
        logistica: data.role === 'Logistica',
        faturamento: data.role === 'Faturamento',
        laboratorio: data.role === 'Laboratorio',
        portaria: data.role === 'Portaria',
      });

      // Close the modal
      setIsOpen();

      // Show Toast for success
      addToast({
        type: 'success',
        title: 'Usuário Cadastrado',
        description: 'Usuário Cadastrado com Sucesso!',
      });

      // reset form
      reset();
    } catch (err: any) {
      // Close the modal
      setIsOpen();

      // Show Toast for failure
      addToast({
        type: 'error',
        title: 'Erro ao cadastrar usuário.',
        description: `${err.response.data.message}`,
      });

      // reset form
      reset();
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
          gap: '1rem',
        }}
      >
        <h1>Cadastro de Usuários</h1>

        <MaskedInput
          {...register('name', { required: 'Nome obrigatório.' })}
          control={control}
          type="none"
          name="name"
          label="Nome"
          onBlur={() => {}}
          error={!!errors.name}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MaskedInput
          {...register('login', { required: 'Login obrigatório.' })}
          control={control}
          type="none"
          name="login"
          label="Login"
          onBlur={() => {}}
          error={!!errors.login}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MaskedInput
          {...register('password', { required: 'Password obrigatório.' })}
          control={control}
          type="password"
          name="password"
          label="Senha"
          onBlur={() => {}}
          error={!!errors.password}
          autoComplete="off"
        />

        <MuiSelect
          {...register('role', {
            required: 'Tipo acesso obrigatório.',
          })}
          control={control}
          label="Tipo Acesso"
          name="role"
          options={roleOptions}
          variant="outlined"
          sx={{ width: '237px' }}
          error={!!errors.role}
        />

        <ButtonGroup>
          <button type="submit" name="SaveButton">
            <div className="text">Salvar</div>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>
        </ButtonGroup>
      </form>
    </Modal>
  );
}

export default ModalInsertUsers;
