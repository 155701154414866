/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useRef } from 'react';
import InputMask, { ReactInputMask } from 'react-input-mask';
import { FormControl, InputBaseProps, OutlinedInputProps } from '@mui/material';

import { Controller } from 'react-hook-form';
import { InputWithIcon } from '../Input';

export type IMaskedInputProps = OutlinedInputProps & {
  type:
    | 'cpf'
    | 'placa'
    | 'volume'
    | 'date'
    | 'numbers'
    | 'cnpj'
    | 'email'
    | 'password'
    | 'cnpjcpf'
    | 'none';
  name: string;
  label: string;
  icon?: ReactElement;
  control?: any;
  onBlur?: (e: any) => void;
  disabled?: boolean;
  inputProps?: OutlinedInputProps;
  sx?: any;
  size?: InputBaseProps;
  shouldShrink?: boolean;
  readOnly?: boolean;
};

export function MaskedInput({
  type,
  name,
  label,
  icon,
  control,
  onBlur,
  disabled,
  inputProps,
  shouldShrink,
  size,
  readOnly,
  sx,
}: IMaskedInputProps) {
  const inputRef = useRef<ReactInputMask>(null);

  let defaultMask = '';
  let placeholder = '';

  switch (type) {
    case 'placa':
      defaultMask = '***-****';
      placeholder = '___-____';
      break;
    case 'cpf':
      defaultMask = '999.999.999-99';
      placeholder = '___.___.___-__';
      break;
    case 'volume':
      defaultMask = '99999999999';
      break;
    case 'date':
      defaultMask = '99/99/9999';
      placeholder = '__/__/____';
      break;
    case 'cnpj':
      defaultMask = '99.999.999/9999-99';
      placeholder = '__.___.___/____-__';
      break;
    case 'email':
      defaultMask = '';
      placeholder = '';
      break;
    case 'numbers':
      defaultMask = '';
      placeholder = '';
      break;
    case 'cnpjcpf':
      defaultMask = '999.999.999-99';
      placeholder = '___.___.___-__';
      break;
    case 'none':
      defaultMask = '';
      placeholder = '';
      break;
    default:
      break;
  }

  const handleInputtypes = (type: string) => {
    if (type === 'numbers') {
      return 'number';
    }

    if (type === 'password') {
      return 'password';
    }
    return 'text';
  };

  const handleMasks = (value: string) => {
    if (type === 'cnpjcpf' && value !== undefined) {
      const onlyNumbers = value.replace(/[^0-9]+/gm, '');
      if (onlyNumbers.length > 11) {
        // cnpj mask
        return '99.999.999/9999-99';
      } else {
        return '999.999.999-999';
      }
    }

    return defaultMask;
  };

  const handlePlaceholders = (value: string) => {
    if (type === 'cnpjcpf' && value !== undefined) {
      const onlyNumbers = value.replace(/[^0-9]+/gm, '');
      if (onlyNumbers.length > 11) {
        // cnpj placeholder
        return '__.___.___/____-__';
      } else {
        return '___.___.___-__ ';
      }
    }

    return placeholder;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={props => (
        <FormControl error={!!props.fieldState.error}>
          <InputMask
            mask={handleMasks(props.field.value)}
            maskPlaceholder={handlePlaceholders(props.field.value)}
            value={props.field.value}
            disabled={disabled}
            onChange={props.field.onChange}
            onBlur={onBlur}
            ref={inputRef}
            type={handleInputtypes(type)}
            readOnly={readOnly}
            autoComplete="off"
            style={{
              width: '100% !important',
            }}
          >
            <InputWithIcon
              name={name}
              label={label}
              icon={icon}
              control={control}
              type={handleInputtypes(type)}
              error={!!props.fieldState.error}
              errorMessage={props.fieldState.error?.message}
              readOnly={readOnly}
              inputProps={{
                ...inputProps,
              }}
              sx={sx}
              size={size}
              shouldShrink={shouldShrink}
            />
          </InputMask>
        </FormControl>
      )}
    />
  );
}
