import styled from 'styled-components';
import { shade } from 'polished';

export const MaintenanceH1 = styled.h1`
  background-color: transparent;

  position: absolute;
  z-index: 100;
  width: 100%;
  text-align: center;
  font-size: 80px;
  color: white;

  top: 10vh;
  bottom: 50vh;
`;

export const Container = styled.div`
  display: block;
  width: 100%;
  max-width: 100vw;
  position: relative;

  /* SWIPER */
  .swiperInitialPage {
    display: flex;
    width: 100%;
    height: calc(100vh);
  }

  .swiper-wrapper {
    display: flex;
    max-width: 100vw;
    height: 100vh !important;
  }

  .swiper-slide {
    img {
      max-width: 100vw;
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }

  .swiper-button-next {
    color: #00a859;
  }

  .swiper-button-prev {
    color: #00a859;
  }

  .autoplay-progress {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 999;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #00a859;
  }

  .autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 999;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: #00a859;
    fill: none;
    stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
  }

  /* END SWIPER */

  /* Navigation arrows */
  /* .fp-controlArrow.fp-next {
    border-color: transparent transparent transparent #fff;
    right: 80px;
    border-width: 20px 0 20px 20px;
  } */

  /* .fp-controlArrow.fp-prev {
    border-color: transparent #fff transparent transparent;
    left: 80px;
    border-width: 20px 20px 20px 0;
  } */

  /* ***************************************************************** */
  @media (max-width: 800px) {
    /* Navigation arrows */
    /* .fp-controlArrow.fp-next {
      border-color: transparent transparent transparent #00a859 !important;
      right: 160px !important;
      border-width: 20px 0 20px 20px;
    } */
    /* ß .fp-controlArrow.fp-prev {
      border-color: transparent #00a859 transparent transparent !important;
      left: 160px !important;
      border-width: 20px 20px 20px 0;
    } */
  }
  /* ***************************************************************** */

  /* SECTION 1 - HOME */
  #section1 {
    display: block;
    background: #171412;
    width: 100%;
    max-width: 100vw;
    height: 100%;

    header {
      display: flex;
      width: 100%;
      height: 100px;
      z-index: 99;
      position: absolute;
      justify-content: space-around;
      background-color: rgba(23, 20, 18, 0.4);
      /* background-color: transparent; */
      opacity: 5;
      align-items: center;
      padding-top: auto;
      top: 0 !important;

      h1 {
        font-size: 2.3rem;
        color: #fff;
        justify-content: left;

        img {
          width: 90px;
          display: inline-block;
          margin-top: 4px;
        }

        & > img {
          margin-right: 15px;
        }
      }

      nav {
        align-items: center;
        justify-content: center;
        display: flex;

        a {
          border: 1px solid #00a859;
          background-color: transparent;
          border-radius: 4px;
          color: #fff;
          font-weight: 600;
          font-family: 'Poppins';
          height: 3rem !important;
          padding: 8px;
          text-decoration: none;
          text-align: center;
          outline: none;

          justify-content: center;
          align-items: center;
          align-self: center;
          align-content: center;
          display: flex;

          :hover {
            cursor: pointer;
            color: #00a859;
          }
        }

        > a {
          margin-left: 10px;
        }

        button {
          border: 1px solid #00a859;
          background-color: transparent;
          border-radius: 4px;
          color: #fff;
          font-weight: 600;
          font-family: 'Poppins';
          max-height: 3rem;
          height: 3rem !important;
          padding: 8px;
          text-decoration: none;
          outline: none;
          width: auto;
          margin: 0 auto;

          justify-content: center;
          align-items: center;
          align-self: center;
          align-content: center;
          display: flex;

          :hover {
            cursor: pointer;
            color: #00a859;
          }
        }
        > button {
          margin-left: 10px;
        }
      }
    }

    .fp-slidesNav {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .carousel-indicators {
      display: none;
    }

    .carouselHolder {
      max-width: 100vw;
      height: 100vh;
      z-index: 1;

      .carousel-inner {
        width: 100vw;
        height: 100%;

        display: flex;
        justify-content: flex-start;
        align-items: top;
        position: absolute;
        top: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        opacity: 1;
        align-items: center;
        justify-content: center;
        display: flex;
        filter: contrast();
      }
    }

    /* ***************************************************************** */
    @media (max-width: 5000px) {
      header {
        #navsmallscreens {
          visibility: hidden;
        }
      }
    }

    /* ***************************************************************** */
    @media (max-width: 1500px) {
      header {
        h1 {
          font-size: 2rem;
        }

        #navsmallscreens {
          visibility: hidden;
        }
      }
    }

    /* ***************************************************************** */
    @media (max-width: 1250px) {
      header {
        h1 {
          font-size: 1.6rem;
        }

        #navsmallscreens {
          visibility: hidden;
        }

        nav {
          a,
          button {
            font-size: 0.9rem !important;
          }
        }
      }
    }

    /* ***************************************************************** */
    @media (max-width: 1150px) {
      header {
        h1 {
          font-size: 1.3rem;
        }

        #navsmallscreens {
          visibility: hidden;
        }
      }
    }

    /* ***************************************************************** */
    @media (max-width: 1055px) {
      header {
        h1 {
          font-size: 1.4rem;
        }

        #navsmallscreens {
          visibility: hidden;
        }

        nav {
          a,
          button {
            font-size: 0.9rem !important;
          }
        }
      }
    }

    /* ***************************************************************** */
    @media (max-width: 1005px) {
      header {
        h1 {
          font-size: 1.5rem;
        }

        #navsmallscreens {
          visibility: hidden;
        }

        nav {
          display: flex;
          margin-right: 2px;
          a,
          button {
            font-size: 0.8rem !important;
            display: flex;
            align-self: center;
            height: 100%;
            font-weight: 600;
          }
        }
      }
    }

    /* ***************************************************************** */
    @media (max-width: 800px) {
      /* Navigation arrows */
      .fp-controlArrow.fp-next {
        border-color: transparent transparent transparent #00a859 !important;
        right: 160px !important;
        border-width: 20px 0 20px 20px;
      }

      .fp-controlArrow.fp-prev {
        border-color: transparent #00a859 transparent transparent !important;
        left: 160px !important;
        border-width: 20px 20px 20px 0;
      }

      header {
        display: flex;
        z-index: -1;
        align-items: center;

        h1 {
          display: block;
          font-size: 1.6vmax;
          font-weight: 600;
        }

        #navsmallscreens {
          visibility: visible;
          justify-content: center;
        }

        #navnormal {
          visibility: hidden;
          width: 0 !important;
          height: 0 !important;
        }

        nav {
          display: block;
          a,
          button {
            font-size: 0.7rem !important;
            display: flex;
            align-self: center;
            height: 100%;
            font-weight: 600;
          }
        }
      }

      .carouselHolder {
        width: 100vw;
        max-width: 100vw;
        height: 100vh;

        .carousel-inner {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: top;
          position: absolute;
          top: 0;
        }

        img {
          width: 100vw;
          height: 100%;
          object-fit: contain;
          opacity: 1;
          align-items: center;
          justify-content: center;
          display: flex;
          filter: contrast();
        }
      }
    }
  }

  /* SECTION 2 - ABOUT */
  #section2 {
    display: flex;
    flex-direction: column;
    background: #171412;
    height: 100vh;
    width: 100%;
    max-width: 100vw;

    .slide {
      display: flex;
      height: 100vh;
      width: 100%;
      align-items: center;

      .menuHolder {
        padding: 20px 0;
        margin: 0 5rem;
        display: block;
        max-width: 400px;
        max-height: 200px;

        h2 {
          padding: 20px 10px;
          display: flex;
          font-size: 1.5rem;
          font-weight: 600;
          border-radius: 4px;
          width: 100%;
          text-align: center;
          color: #fff;
        }

        button {
          display: flex;
          margin: 0 auto;
          padding: 10px 10px;
          width: 70%;
          justify-content: center;
          align-items: center;
          align-self: auto;
          background: #00a859;
          color: #fff;
          font-weight: 600;
          font-size: 1.5rem;
          border: transparent;
          border-radius: 4px;
          outline: none;
        }

        a {
          display: flex;
          margin: 0 auto;
          padding: 10px 10px;
          width: 70%;
          justify-content: center;
          align-items: center;
          align-self: auto;
          background: #00a859;
          color: #fff;
          font-weight: 600;
          font-size: 1.5rem;
          border: transparent;
          border-radius: 4px;
          outline: none;
          text-decoration: none;

          &:hover {
            background: ${shade(0.2, '#00A859')};
          }
        }
      }

      .imgHolder {
        display: flex;
        height: 100%;
        padding: 0;
        margin: 0;
        width: 100%;

        img {
          display: flex;
          width: 100%;
          max-width: 100vw;
          opacity: 0.8;
          margin: 0 auto;
          padding: 0;
          float: right;
          justify-content: flex-end;
          align-items: flex-end;
        }
      }
    }
  }

  /* SECTION 3 - JOBS */
  #section3 {
    display: block;
    position: relative;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    max-width: 100vw;

    #jobsHeader {
      display: block;
      position: absolute;
      top: 2.5rem;
      width: 100%;
      text-align: center;

      .separator {
        border: 1px solid #00a859;
        width: 80%;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-top: 5px;
        display: block;
        border-top-width: 1px;
      }

      h1 {
        color: #00a859;
        padding-bottom: 10px;
        align-items: center;

        :after {
          display: block;
          text-align: center;
          padding-top: 5px;
          margin: 0 auto;
          content: '';
          width: 50%;
          border-bottom: 3px solid #00a859;
          animation: 1s ease-in 0s 1 slideInFromLeft;
        }

        @keyframes slideInFromLeft {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(0);
          }
        }
      }

      h3 {
        margin-top: 20px;
      }

      p {
        margin-top: 20px;
      }

      #jobsTitle {
      }
    }

    .logoHolder {
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 55%;
      width: 100%;
      position: absolute;

      img {
      }
    }

    #notificationContainer {
      display: block;
      position: absolute;
      bottom: 38%;
      width: 100%;
      text-align: center;

      #jobsTitle {
        font-weight: 600;
        font-size: 1.5rem;
        justify-content: center;
        margin: 0 auto;
        display: flex;
      }

      #disableNotification {
        margin-top: 5px;
        margin-left: 5px;
        color: red;
      }
    }

    .wrapper {
      position: absolute;
      height: 40%;
      width: 100%;
      bottom: 10px;

      align-items: center;
      justify-content: center;
      display: flex;

      #noJobs {
        border: 1px solid #00a859;
        border-radius: 4px;
        width: 50% !important;
        text-align: center;
        font-family: 'Poppins';
      }

      .react-multi-carousel-list {
        width: 60%;
        margin: 0 auto;
      }

      .react-multi-carousel-item {
        .carouselItems {
          width: 200px !important;
          height: 190px !important;
          border-radius: 4px;
          text-align: center;

          border: 1px solid #00a859;

          span {
            display: flex;
            max-width: 200px !important;
            width: 100% !important;
            font-size: 1rem;
            background: #00a859;
            max-height: 60px;
            height: 60px;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex: 1;
            color: #fff;
            font-weight: 500;
          }

          #beneficios {
            color: #00a859;
            font-size: 0.7rem;
            position: absolute;
            bottom: 35px;
            justify-content: center;
            align-items: center;
            display: flex;
            width: 190px;
          }

          h6 {
            margin-top: 4px;
            font-size: 0.8rem;
          }

          #link {
            background: #00a859;
            color: #fff;
            border-radius: 4px;
            border: transparent;
            width: 190px;
            bottom: 10px;
            left: 5px;
            display: flex;
            position: absolute;
            justify-content: center;
            align-items: center;
            text-align: center;

            outline: none;
            height: 20px;

            text-decoration: none;
            margin: 0 auto;

            &:hover {
              color: ${shade(0.5, '#00A859')};
            }
          }
        }
      }
    }
  }

  /* SECTION 4  - CONTACT */
  #section4 {
    display: block;
    /* min-height: calc(100vh + 80px); */
    height: 100%;
    width: 100%;
    max-width: 100vw;
    position: relative;

    /* Contact Page Title */
    #contactHeader {
      display: block;
      position: relative;
      width: 100%;
      text-align: center;

      .separator {
        border: 1px solid #00a859;
        width: 80%;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-top: 5px;
        display: block;
        border-top-width: 1px;
      }

      h1 {
        color: #00a859;
        padding-bottom: 10px;
        align-items: center;

        :after {
          display: block;
          text-align: center;
          padding-top: 5px;
          margin: 0 auto;
          content: '';
          width: 50%;
          border-bottom: 3px solid #00a859;
          animation: 1s ease-in 0s 1 slideInFromLeft;
        }

        @keyframes slideInFromLeft {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(0);
          }
        }
      }
    }

    #containerWrapper {
      width: 100%;
      position: relative;

      .mapHolder {
        display: block;
        position: relative;
        width: 100%;
        max-width: 100vw;
        height: 10rem;
        margin-bottom: 1rem;
        /* margin-left: auto; */
        /* margin-right: auto; */
        height: 15rem;
        border-radius: 5px;
        bottom: 0 !important;
        padding: 0 2.5rem;

        iframe {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          border: none;
          border-radius: 5px;
          border: 1px solid #00a859;
          /* padding: 0 2.5rem; */
        }
      }
    }

    #container {
      display: flex !important;
      width: 100% !important;
      position: relative;

      #contactsContainer {
        width: 50%;
        margin-top: 1rem;
        height: 32rem;

        h2 {
          text-align: center;
          margin-bottom: 1rem;
        }

        .card {
          width: 70%;
          margin-left: auto;
          margin-right: auto;

          .cardItems {
            margin: 0 auto;
            text-align: center;

            #sector {
              color: #00a859;
              font-weight: 600;
              font-size: 1.2rem;
              padding: 0;
              margin: 0;
              height: auto;
            }

            #phone {
              color: #171412;
              font-weight: 500;
              font-size: 0.9rem;
              padding: 0;
              margin: 0;
              height: auto;
            }

            #email {
              color: #171412;
              font-weight: 500;
              font-size: 0.9rem;
              padding: 0;
              margin: 0;
              height: auto;
            }
          }
        }

        & > .card {
          margin-top: 10px;
        }
      }

      #formContainer {
        width: 50%;
        /* height: 70vh; */
        height: 32rem;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: 1rem;
        justify-content: center;
        align-items: center;
        display: inline-block;

        #form {
          width: 30%;
          position: absolute;
          justify-content: center;
          display: block;
          margin: 0 auto;
          top: 6.5rem;
        }

        h2 {
          text-align: center;
          width: 100%;
        }
      }
    }

    #footerHolder {
      width: 100%;
      max-width: 100vw;
      height: 140px;
      position: relative;
      bottom: 0;
      background: #171412 !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2.5rem;

      .footerMenuHolder {
        font-size: 1rem;
        display: block;
        z-index: 1;

        button {
          display: block;
          cursor: pointer;
          font-size: 0.9rem;
          font-weight: 600;
          color: #fff;
          border: none;
          outline: none;
          background: transparent;
          margin-top: 0.2rem;
          margin-bottom: 0.2rem;

          &:hover {
            color: ${shade(0.5, '#00A859')};
          }
        }

        & > button {
          margin-top: 0.5rem;
        }
      }

      .footerCompanyInfoHolder {
        font-size: 1rem;
        display: block;
        width: 26rem;
        color: #fff;

        .companyWrapper {
          display: flex;
          height: 100%;
          float: right;
          align-items: center;

          #imageContainer {
            height: 100%;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;

            /* Logo */
            img {
              color: #fff;
              align-items: center;
              display: flex;
              max-height: 110px;
              padding-top: 5px;
              margin: 0;
            }
          }

          #infoContainer {
            max-height: 100%;
            padding: 0;
            display: inline-block;
            p {
              padding: 0;
              margin: 0;
              font-size: 0.7rem;
            }
          }
        }
      }

      .footerMediaHolder {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        bottom: 0 !important;
        left: 0;
        right: 0;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 140px;

        #wrapper {
          display: flex;
          flex-direction: column;

          /* Title Follow Us */
          p {
            color: #fff;
            text-align: center;
            width: 100% !important;
            display: flex;
            text-align: center;
            justify-content: center;
          }

          #copyrightContainer {
            height: 16px;
            justify-content: center;
            align-items: center;
            position: relative;
            bottom: 0;

            p {
              font-size: 0.6rem;
              color: #fff;
              font-weight: 600;
            }

            svg {
              margin-left: 2px;
              margin-right: 2px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
            }
          }

          #socialMediaContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px auto;

            svg {
              cursor: pointer;
              color: #fff;

              &:hover {
                color: #00a859;
              }
            }

            & > svg {
              margin-left: 10px;
            }
          }
        }
      }
      #developedBy {
        color: #00a859;
        opacity: 0.5;
        font-size: 0.5rem;
        text-align: right;
        position: absolute;
        justify-self: right;
        right: 2.5rem;
        bottom: 0;
      }
    }

    /* ***************************************************************** */
    @media (max-width: 3400px) {
      #container {
        height: 100%;

        #contactsContainer {
          /* margin-top: 4rem; */
        }
      }

      #containerWrapper {
        .mapHolder {
          position: relative;
          height: 20rem !important;

          h2 {
          }

          iframe {
            display: flex;
            justify-content: center;
            width: 100%;
            border: none;
            border-radius: 5px;
            border: 1px solid #00a859;
          }
        }
      }
    }

    /* ***************************************************************** */
    @media (max-width: 1450px) {
      #formContainer {
        h2 {
          font-size: 1.6rem;
        }
      }

      #contactsContainer {
        h2 {
          font-size: 1.6rem;
        }
      }

      #container {
        height: 100%;
      }
      #container-wrapper {
        .mapHolder {
          height: 15rem !important;
          border-radius: 5px;

          iframe {
            display: flex;
            justify-content: center;
            width: 100%;
            border: none;
            border-radius: 5px;
            border: 1px solid #00a859;
          }
        }
      }
    }

    /* ***************************************************************** */
    @media (max-width: 1160px) {
      #formContainer {
        h2 {
          font-size: 1.2rem;
        }
      }

      #contactsContainer {
        h2 {
          font-size: 1.2rem;
        }
      }

      #container {
        height: 100%;

        #contactsContainer {
          /* margin-top: 4rem; */

          .mapHolder {
            display: block;
            /* width: 86% !important; */
            position: absolute;
            height: 15rem !important;
            border-radius: 5px;
            bottom: 13% !important;
            /* margin-left: 5rem !important; */
            /* margin-right: 5rem !important; */

            iframe {
              display: flex;
              justify-content: center;
              width: 100vw;
              border: none;
              border-radius: 5px;
              border: 1px solid #00a859;
            }
          }
        }
      }
    }

    /* ***************************************************************** */
    @media (max-height: 850px) {
      #formContainer {
        h2 {
          font-size: 1.6rem;
        }
      }

      #contactsContainer {
        h2 {
          font-size: 1.6rem;
        }
      }

      #container {
        height: 100%;

        #contactsContainer {
          /* margin-top: 4rem; */

          .mapHolder {
            display: block;
            /* width: 84.2% !important; */
            position: absolute;
            height: 10rem !important;
            border-radius: 5px;
            bottom: 15% !important;
            /* margin-left: 7.5rem !important; */
            /* margin-right: 7.1rem !important; */

            iframe {
              display: flex;
              justify-content: center;
              width: 100vw;
              border: none;
              border-radius: 5px;
              border: 1px solid #00a859;
            }
          }
        }
      }
    }

    /* ***************************************************************** */
    @media (max-height: 850px) and (max-width: 1350px) {
      #formContainer,
      #contactsContainer {
        h2 {
          font-size: 1.3rem;
        }
      }

      #container {
        height: 100%;

        #contactsContainer {
          /* margin-top: 4rem; */

          .mapHolder {
            display: block;
            /* width: 87.3% !important; */
            position: absolute;
            height: 10rem !important;
            border-radius: 5px;
            bottom: 15% !important;
            /* margin-left: 5.4rem !important; */
            /* margin-right: 5.9rem !important; */

            iframe {
              display: flex;
              justify-content: center;
              width: 100vw;
              border: none;
              border-radius: 5px;
              border: 1px solid #00a859;
            }
          }
        }
        #form {
          width: 400px !important;
        }
      }
    }

    .downloadsHolder {
      width: 100%;
      padding: 2rem 0;
      display: flex;
      flex-direction: column;
      margin: 4rem 0;

      .documentsHolder {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 4rem;
        margin: 3rem 0;
      }
    }
  }
`;

export const FormContainer = styled.div`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  form {
    width: 70%;
    display: block;
    padding-top: 2rem;
    padding-bottom: 2rem;
    align-items: center;
    justify-content: center;

    svg {
      align-self: flex-start;
    }
  }
`;
