import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 100vw !important;
  height: 100vmin !important;
  background: #fff;

  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 20px;
    z-index: 99;

    h1 {
      color: #00a859;
      font-weight: 600;
    }

    button {
      border: 1px solid #00a859;
      background-color: transparent;
      border-radius: 4px;
      color: #171412;
      font-weight: 600;
      font-family: 'Poppins';
      height: 40px;
      padding: 8px;
    }
    > button {
      margin-left: 10px;
    }

    & :hover {
      cursor: pointer;
      color: #00a859;
    }
  }

  .jobWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    position: relative;
    top: 5%;
    bottom: 5%;

    .jobContainer {
      border: 1px solid #00a859;
      border-radius: 4px;
      width: 60%;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      align-items: center;

      #jobTitle {
        color: #00a859;
        font-size: 2rem;
      }

      #jobDescription {
        color: #171412;
        font-size: 1.2rem;
        font-style: italic;
        width: 80%;
        justify-content: center;
        display: flex;
        align-items: center;
        text-align: center;
        margin: 0 auto;
      }

      #jobFormationLabel,
      #jobShiftLabel,
      #jobOtherLabel {
        color: #00a859;
        font-size: 1.5rem;
        margin-top: 15px;
        margin-bottom: 0;
      }

      #jobFormation,
      #jobShift,
      #jobCity,
      #jobBenefits,
      #jobOther {
        color: #171412;
        font-size: 1rem;
        margin: 0 auto;
        opacity: 1;
      }

      #jobBenefitsLabel {
        margin-top: 15px;
        margin-bottom: 0;
        color: #00a859;
        font-size: 1.5rem;
        font-weight: 600;
      }

      footer {
        h3 {
          color: #171412;
          font-size: 1rem;
          margin-top: 10px;
          margin-bottom: 0;
          opacity: 0.8;

          #emailRecruter {
            color: #00a859;
          }
        }

        h4 {
          color: #171412;
          font-size: 1rem;
          margin: 0 auto;
          padding-bottom: 1rem;
          opacity: 0.8;
        }

        h5 {
          margin-top: 15px;
          color: #00a859;
          font-weight: 600;
        }

        #separator {
          border: 1px solid #00a859;
          width: 80%;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          margin-top: 30px;
          display: block;
          border-top-width: 1px;
        }

        .contactHolder {
          width: 100% !important;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          display: inline-flex;

          p {
            color: #171412;
            display: flex;
            justify-content: left;
            align-items: center;
            width: 200px;
            svg {
              margin-right: 10px;
              justify-content: left;
            }
          }
        }
      }
    }
  }
`;
