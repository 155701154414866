import React, { ReactElement, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { FaExchangeAlt, FaCheck } from 'react-icons/fa';

import { Container, CardContainer, Card } from './styles';

import { useAuth } from '../../hooks/auth';
import ModalChangeStatusFaturamentoPanel from '../../components/ModalChangeStatusFaturamentoPanel';
import api from '../../services/api';
import ModalFinalizaCarregamentoPanel from '../../components/ModalFinalizaCarregamentoPanel';
import { Box, CircularProgress } from '@mui/material';

function PainelFaturamento(): ReactElement {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const [
    finalizaCarregamentoPanelModalOpen,
    setFinalizaCarregamentoPanelModalOpen,
  ] = useState(false);

  const [
    panelChangeStatusFaturamentoModalOpen,
    setPanelChangeStatusFaturamentoModalOpen,
  ] = useState(false);

  const [filaCarregamento, setFilaCarregamento] = useState([]);
  const [finalizaCarregamentoData, setFinalizaCarregamentoData] = useState([]);

  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
  const [loadingFinalizaCarregamento, setLoadingFinalizaCarregamento] =
    useState(false);

  async function toogleFinalizaCarregamentoPanelModal(): Promise<void> {
    setLoadingFinalizaCarregamento(true);
    // Find fila carregamento
    const foundFilaCarregamento = await api.get('/fila/').then(response => {
      const filteredFila = response.data.filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (fila: any) => fila.status === 'NLIB',
      );
      return filteredFila;
    });

    setFinalizaCarregamentoData(foundFilaCarregamento);
    setFinalizaCarregamentoPanelModalOpen(!finalizaCarregamentoPanelModalOpen);
    setLoadingFinalizaCarregamento(false);
  }

  // Toogle Panel Change Status Modal Modal
  async function tooglePanelChangeStatusFaturamentoModal(): Promise<void> {
    setLoadingChangeStatus(true);
    // Find fila carregamento
    const foundFilaCarregamento = await api.get('/fila/').then(response => {
      const filteredFila = response.data.filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (fila: any) => fila.status === 'AFAT' || fila.status === 'ADOC',
      );
      return filteredFila;
    });
    setFilaCarregamento(foundFilaCarregamento);
    setPanelChangeStatusFaturamentoModalOpen(
      !panelChangeStatusFaturamentoModalOpen,
    );
    setLoadingChangeStatus(false);
  }

  return (
    <Container>
      <ModalChangeStatusFaturamentoPanel
        isOpen={panelChangeStatusFaturamentoModalOpen}
        setIsOpen={() => tooglePanelChangeStatusFaturamentoModal()}
        filaData={filaCarregamento}
      />

      <ModalFinalizaCarregamentoPanel
        isOpen={finalizaCarregamentoPanelModalOpen}
        setIsOpen={() => toogleFinalizaCarregamentoPanelModal()}
        filaData={finalizaCarregamentoData}
      />

      <header>
        <h1>Bom Sucesso Agroindústria S.A</h1>

        <nav>
          <button type="submit" onClick={() => navigate('/')}>
            Página Inicial
          </button>

          <button type="submit" onClick={() => navigate('/admin')}>
            Voltar
          </button>

          <button type="submit" onClick={signOut}>
            Logout
          </button>
        </nav>
      </header>
      <h2 id="pageTitle">Painel Faturamento</h2>

      <h2 id="selectOption">Selecione uma opção</h2>

      <CardContainer>
        {/* Admin Card - only accessible to user.admin */}
        <Card
          style={{ width: '344px' }}
          onClick={() => tooglePanelChangeStatusFaturamentoModal()}
        >
          <h1>Altera Status Fila</h1>

          {loadingChangeStatus ? (
            <Box width="100%" display="flex" justifyContent="center">
              <CircularProgress size={38} sx={{ color: '#fff' }} />
            </Box>
          ) : (
            <FaExchangeAlt size={38} color="#fff" />
          )}
        </Card>

        {/* Logistica Card - only accessible to user.logistica */}
        <Card onClick={() => toogleFinalizaCarregamentoPanelModal()}>
          <h1>Finaliza Carregamento</h1>

          {loadingFinalizaCarregamento ? (
            <Box width="100%" display="flex" justifyContent="center">
              <CircularProgress size={38} sx={{ color: '#fff' }} />
            </Box>
          ) : (
            <FaCheck size={38} color="#fff" />
          )}
        </Card>
      </CardContainer>
    </Container>
  );
}

export default PainelFaturamento;
