import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 100vw !important;
  height: 100vmin !important;
  background: #fff;
  position: absolute;

  header {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 10px;
    z-index: 99;
    position: relative;

    h1 {
      color: #00a859;
      font-weight: 600;
    }
  } /* END HEADER */

  @media (max-width: 1020px) {
    h1 {
      color: #00a859;
      font-weight: 600;
      font-size: 2rem;
    }
  }
`;

export const TableContainer = styled.section`
  max-height: 100%;
  overflow-y: visible;
  overflow-x: hidden;
  margin-top: 0px;

  padding-left: 20px;
  padding-right: 20px;

  table {
    width: 100%;
    border-spacing: 0 8px;
    white-space: nowrap;
    justify-content: center;

    thead {
      height: 0px;
      margin-top: 0px;
    }

    th {
      text-align: center;
      color: #00a859;
      font-size: 1.5rem;
    }

    td {
      padding: 8px 0px;
      border: 0;

      background: #fff;
      text-align: center;
      font-size: 1rem;
      font-weight: 500;
      color: #171412;
    }

    td:first-child {
      border-radius: 8px 0 0 8px;
    }

    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  }

  @media (max-width: 1020px) {
    table {
      width: 100%;
      border-spacing: 0 8px;
      white-space: nowrap;
      justify-content: center;
      margin-top: 30px;

      thead {
        height: 0px;
        margin-top: 0px;
      }

      th {
        text-align: center;
        color: #00a859;
        font-size: 1.1rem;
      }

      td {
        padding: 8px 0px;
        border: 0;

        background: #fff;
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
        color: #171412;
      }
    }
  }
`;
