import React, { ButtonHTMLAttributes, ReactElement } from 'react';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
};

function Button({ children, loading, ...rest }: ButtonProps): ReactElement {
  return (
    <Container type="button" {...rest}>
      {loading ? 'Loading...' : children}
    </Container>
  );
}

// Button.defaultProps = {
//   loading: false,
// };

export default Button;
