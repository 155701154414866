import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isfocused: boolean;
  isfilled: boolean;
  iserrored: boolean;
  invisible?: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #272727;
  border-radius: 10px;
  border: 2px solid #272727;
  padding: 14px;
  width: 100%;
  color: #c2c0ba;

  display: flex;
  justify-content: center;

  /* Every input that is followed by another input will have */
  & + div {
    margin-top: 10px;
  }

  ${props =>
    props.invisible &&
    css`
      display: none !important;
    `}

  ${props =>
    props.iserrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isfocused &&
    css`
      color: #00a859;
      border-color: #00a859;
    `}

  ${props =>
    props.isfilled &&
    css`
      color: #00a859;
    `}


  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #fff !important;
    outline: none;
    width: 120px;
    font-size: 0.9rem;

    &::placeholder {
      color: #c2c0ba;
    }
  }

  /* auto complete background color */
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #272727 inset;
  }

  /* auto complete text color */
  input:-webkit-autofill {
    -webkit-text-fill-color: #fff !important;
  }

  svg {
    margin-right: 12px;
    text-align: center;
  }
`;

export const Error = styled(Tooltip)`
  height: 100%;
  width: 20px;
  margin-left: 16px;
  background: #272727;
  align-self: center;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
