/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useToast } from '../../hooks/toast';
import api from '../../services/api';

// Material UI
import { Button, Stack } from '@mui/material';
import { TaskAltOutlined } from '@mui/icons-material';

// Custom Components
import MuiSelect from '../Material/Select';
import { MuiInput } from '../Material/MuiInput';
import Modal from '../Modal';

// Styles
import { ButtonGroup } from '../../styles/customButtonGroup';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

type FormData = {
  spots: number;
  day: string;
  produto: string;
};

const ModalCadastraSpots = React.forwardRef<HTMLInputElement, IModalProps>(
  ({ isOpen, setIsOpen }, ref) => {
    const { addToast } = useToast();

    const {
      register,
      handleSubmit,
      control,
      reset,
      formState: { errors },
    } = useForm<FormData>();

    const onSubmit: SubmitHandler<FormData> = async data => {
      try {
        // POST PARA CONFIRMAR A CHEGADA
        await api.post('/fila/spotscarregamento', {
          spots: data.spots,
          day:
            data.day === '1 - Segunda-Quinta'
              ? 'seg-qui'
              : data.day === '2 - Sexta'
              ? 'sex'
              : data.day === '3 - Sábado'
              ? 'sab'
              : data.day === '4 - Domingo'
              ? 'dom'
              : null,
          produto:
            data.produto === 'Etanol' ? 1 : data.produto === 'Bagaço' ? 3 : 4,
        });

        // Close the modal
        setIsOpen();

        // Show Toast for success
        addToast({
          type: 'success',
          title: 'Criação de Vagas Com Sucesso.',
          description: `Número de Vagas cadastrado com sucesso`,
        });

        reset();
      } catch (err: any) {
        // Close the modal
        setIsOpen();

        // Show Toast for failure
        addToast({
          type: 'error',
          title: 'Erro ao cadastrar vagas.',
          description: `${err.response.data.message}`,
        });

        reset();
      }
    };

    const selectOptions = [
      '1 - Segunda-Quinta',
      '2 - Sexta',
      '3 - Sábado',
      '4 - Domingo',
    ];
    const selectProdutos = ['Etanol', 'Bagaço', 'VHP'];

    return (
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '1rem',
          }}
        >
          <h1>Cadastro Vagas</h1>

          <Stack gap={2} padding={2} mt={2}>
            <MuiInput
              {...register('spots', {
                required: 'Numero de vagas é obrigatório.',
              })}
              name="spots"
              label="Nr Vagas"
              control={control}
              error={Boolean(errors.spots)}
              ref={ref}
            />

            <MuiSelect
              {...register('day', {
                required: 'Dias da semana é obrigatório.',
              })}
              control={control}
              name="day"
              label="Dias Semana"
              variant="outlined"
              options={selectOptions}
              error={Boolean(errors.day)}
              ref={ref}
            />

            <MuiSelect
              {...register('produto', {
                required: 'Produto é obrigatório.',
              })}
              control={control}
              name="produto"
              label="Produto"
              variant="outlined"
              options={selectProdutos}
              error={Boolean(errors.produto)}
              ref={ref}
            />
          </Stack>

          <ButtonGroup>
            <Button
              type="submit"
              variant="outlined"
              endIcon={
                <TaskAltOutlined sx={{ width: '1.75rem', height: '1.75rem' }} />
              }
              size="large"
              sx={{ width: '156px', fontSize: '1.25rem' }}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </form>
      </Modal>
    );
  },
);

ModalCadastraSpots.displayName = 'ModalCadastraSpots';

export default ModalCadastraSpots;
