/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState } from 'react';

import Modal from '../Modal';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { formatDateBr } from '../../utils/formatDate';
import { formatFilaStatus } from '../../utils/formatFilaStatus';
import ModalAprovaCheckList from '../ModalAprovaCheckList';
import api from '../../services/api';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  filaData: any[];
}

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'left' | 'center';
  format?: (value: string) => string;
}

const columns: Column[] = [
  {
    id: 'data',
    label: 'Data',
    minWidth: 170,
    align: 'center',
    format: (value: string) => formatDateBr(value),
  },
  { id: 'filaid', label: 'Fila Id', minWidth: 100, align: 'center' },
  { id: 'spot', label: 'Spot', minWidth: 100, align: 'center' },
  {
    id: 'placa',
    label: 'Placa',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'cpf',
    label: 'CPF',
    minWidth: 140,
    align: 'center',
  },
  {
    id: 'volume',
    label: 'Volume',
    minWidth: 140,
    align: 'center',
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 170,
    align: 'center',
    format: (value: string) => formatFilaStatus(value),
  },
];

const ModalCheckListPanel = React.forwardRef<HTMLInputElement, IModalProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ isOpen, setIsOpen, filaData }, ref) => {
    const [aprovaChecklistModalOpen, setAprovaChecklistModalOpen] =
      useState(false);
    const [checklistData, setChecklistData] = useState<any>({});

    // Toogle modal aprovaChecklist
    function toogleAprovaChecklistModal(): void {
      setAprovaChecklistModalOpen(!aprovaChecklistModalOpen);
    }

    async function handleAprovaChecklist(fila: any): Promise<void> {
      const agendamento = await api.get(
        `/agendamentos/byagendamentoid/${fila.agendamentoid}`,
      );

      const filaData = {
        ...fila,
        pedido: agendamento.data.pedido,
      };

      setChecklistData(filaData);
      toogleAprovaChecklistModal();
    }

    return (
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="1200px">
        <ModalAprovaCheckList
          isOpen={aprovaChecklistModalOpen}
          setIsOpen={() => toogleAprovaChecklistModal()}
          checklistData={checklistData}
        />

        <h1
          style={{
            color: '#00a859',
            textAlign: 'center',
            marginBottom: '20px',
          }}
        >
          Lista Aprovar Checklist
        </h1>

        <h6
          style={{
            textAlign: 'center',
            marginBottom: '20px',
            opacity: 0.7,
          }}
        >
          Clique em um agendamento da tabela abaixo para iniciar o processo de
          aprovação de checklist.
        </h6>
        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: 640, padding: 2 }}>
            <Table
              sx={{ minWidth: 450 }}
              size="small"
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead sx={{ padding: 1 }}>
                <TableRow sx={{ padding: 1 }}>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ top: 57, minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filaData.length > 0 ? (
                  filaData.map(fila => {
                    return (
                      <TableRow
                        key={fila.filaid}
                        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        sx={{
                          cursor: 'pointer',
                        }}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        onClick={() => {
                          handleAprovaChecklist(fila);
                        }}
                      >
                        {columns.map(column => {
                          const value = fila[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      Nenhum agendamento encontrado.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Modal>
    );
  },
);

ModalCheckListPanel.displayName = 'ModalCheckListPanel';

export default ModalCheckListPanel;
