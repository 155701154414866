import styled from 'styled-components';

export const PageTitle = styled.h1`
  color: #00a859;
  font: 'Poppins', sans-serif;
  text-align: center;
`;

export const CardContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 280px);
  grid-gap: 1rem;
  margin: 2rem auto;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;

  border: '1px solid red';

  h1 {
    color: #00a859;
    font-weight: 600;
  }
`;

export const Card = styled.div`
  background: #00a859;
  padding: 22px 32px;
  border-radius: 5px;
  height: 158px;

  &:hover {
    cursor: pointer;
  }

  h1 {
    margin-top: 14px;
    font-size: 24px;
    font-weight: 600;
    line-height: 54px;
    text-align: center;

    color: #fff;
  }

  #cadTransportadora,
  #cadConfirmaMotorista,
  #cadCarregamentoFds {
    line-height: 28px;
  }

  svg {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-self: auto;
  }
`;
