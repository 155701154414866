/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

// Material UI
import { Button, Stack } from '@mui/material';
import { TaskAltOutlined } from '@mui/icons-material';

// Custom Components
import Modal from '../../Modal';
import { MaskedInput } from '../../Material/MaskedInput';

// Styles
import { ButtonGroup } from '../../../styles/customButtonGroup';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

type FormData = {
  cnpj: string;
  newpassword: string;
  confirmpassword: string;
};

const ModalResetPasswordTransportadora = React.forwardRef<
  HTMLInputElement,
  IModalProps
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(({ isOpen, setIsOpen }, ref) => {
  const { addToast } = useToast();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = async data => {
    try {
      // POST PARA CONFIRMAR A CHEGADA
      await api.post('/users/update-password', {
        login: data.cnpj,
        password: data.newpassword,
        confirmPassword: data.confirmpassword,
      });

      // Close the modal
      setIsOpen();

      // Show Toast for success
      addToast({
        type: 'success',
        title: 'Sucesso.',
        description: `Password atualizado com sucesso.`,
      });

      reset();
    } catch (err: any) {
      // Close the modal
      setIsOpen();

      // Show Toast for failure
      addToast({
        type: 'error',
        title: 'Erro ao atualizar password.',
        description: `${err.response.data.message}`,
      });

      reset();
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <h1>Reset Password</h1>

        <Stack gap={2} padding={2} mt={2}>
          <MaskedInput
            {...register('cnpj', {
              required: 'CNPJ/CPF obrigatório.',
            })}
            control={control}
            type="cnpjcpf"
            name="cnpj"
            label="CNPJ/CPF Transportadora"
            onBlur={() => {}}
            error={!!errors.cnpj}
          />

          <MaskedInput
            {...register('newpassword', {
              required: 'Nova senha obrigatória.',
            })}
            control={control}
            type="password"
            name="newpassword"
            label="Nova senha"
            onBlur={() => {}}
            error={!!errors.cnpj}
          />

          <MaskedInput
            {...register('confirmpassword', {
              required: 'Obrigatório confirmar a senha.',
            })}
            control={control}
            type="password"
            name="confirmpassword"
            label="Confirma senha"
            onBlur={() => {}}
            error={!!errors.cnpj}
          />
        </Stack>

        <ButtonGroup>
          <Button
            type="submit"
            variant="outlined"
            endIcon={
              <TaskAltOutlined sx={{ width: '1.75rem', height: '1.75rem' }} />
            }
            size="large"
            sx={{ width: '156px', fontSize: '1.25rem' }}
          >
            Salvar
          </Button>
        </ButtonGroup>
      </form>
    </Modal>
  );
});

ModalResetPasswordTransportadora.displayName =
  'ModalResetPasswordTransportadora';

export default ModalResetPasswordTransportadora;
