/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { FiCheckSquare } from 'react-icons/fi';

import { ButtonGroup } from '../../styles/customButtonGroup';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import Modal from '../Modal';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MaskedInput } from '../Material/MaskedInput';
import { Stack } from '@mui/material';
import moment from 'moment';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

interface IFormData {
  data: Date;
  cpf: string;
}

function ModalChegadaMotoristas({
  isOpen,
  setIsOpen,
}: IModalProps): ReactElement {
  const { addToast } = useToast();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormData>();

  /** Submiting the form */
  const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
    // Formatting the data
    const toFormatString = String(data.data);
    const day = toFormatString.substring(0, 2);
    const month = toFormatString.substring(3, 5);
    const year = toFormatString.substring(6, 10);
    const timeNow = moment().format('HH:mm:ss');

    try {
      const motoristaData = await api.get(`/motoristas/${data.cpf}`);
      const formattedDate = `${year}-${month}-${day} ${timeNow}`;

      // POST PARA CONFIRMAR A CHEGADA
      await api
        .put('/agendamentos/confirmachegada', {
          cpf: data.cpf,
          // data: dayjs(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
          data: formattedDate,
        })
        .then(response => {
          const agendamentoData = response.data;

          // Show Toast for success
          addToast({
            type: 'success',
            title: 'Confirmação realizada com sucesso.',
            description: `Olá, ${motoristaData.data.nome}.
                      Registro de presença confirmado para o carregamento do dia ${
                        // Agendamento data will be the same as the Fila
                        // It is set in the backend ChegadaMotoristaService
                        moment(agendamentoData.data).format('DD/MM/YYYY')
                      },
                      produto ${
                        agendamentoData.produto === 1
                          ? 'Hidratado'
                          : agendamentoData.produto === 2
                          ? 'Etanol - Outros Fins'
                          : agendamentoData.produto === 3
                          ? 'Bagaço'
                          : agendamentoData.produto === 4
                          ? 'Açúcar VHP'
                          : agendamentoData.produto === 5
                          ? 'Anidro'
                          : null
                      },
                      por favor aguarde a realização do checklist.`,
          });
        });

      // Close the modal
      setIsOpen();

      // Reset the form
      reset();
    } catch (err: any) {
      // Close the modal
      setIsOpen();

      // Show Toast for failure
      addToast({
        type: 'error',
        title: 'Erro ao confirmar chegada.',
        // description: 'Confirme se existe agendamento para esta data e cpf.',
        description: `${err.response.data.message}`,
      });

      // Reset the form
      reset();
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <h1>Chegada Motorista</h1>

        <Stack gap={2} padding={2} mt={2}>
          <MaskedInput
            {...register('data', { required: 'Data obrigatória.' })}
            control={control}
            type="date"
            name="data"
            label="Data agendamento"
            onBlur={() => {}}
            error={!!errors.data}
          />

          <MaskedInput
            {...register('cpf', { required: 'CPF obrigatório.' })}
            control={control}
            type="cpf"
            name="cpf"
            label="CPF Motorista"
            onBlur={() => {}}
            error={!!errors.cpf}
          />
        </Stack>

        <ButtonGroup>
          <button type="submit" name="SaveButton">
            <div className="text">Confirmar</div>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>
        </ButtonGroup>
      </form>
    </Modal>
  );
}

export default ModalChegadaMotoristas;
