/* eslint-disable no-nested-ternary */
import React, { ReactElement, useEffect, useState } from 'react';

import format from 'date-fns/format';
import { Container, ContainerHolder, TableContainer } from './styles';

import api from '../../services/api';
import { formatDateBr } from '../../utils/formatDate';
import moment from 'moment';

interface Fila {
  filaid: number;
  produto: number;
  data: Date;
  placa: string;
  placa2: string;
  cpf: string;
  motorista: string;
  volume: number;
  status: string;
  spot: number;
  agendamentoid: number;
}

function FilaOutrosProdutos(): ReactElement {
  const [filaOutros, setFilaOutros] = useState<Fila[]>([]);

  const filaDate = new Date(Date.now());
  const formattedDate = format(new Date(filaDate), 'yyyy-MM-dd');
  const group = 'Outros';

  useEffect(() => {
    async function loadFila(): Promise<void> {
      await api.get(`/fila/byprodutogroup/${group}`).then(response => {
        setFilaOutros(response.data);
      });
    }
    loadFila();
  }, [formattedDate]);

  // eslint-disable-next-line func-names
  setInterval(function () {
    // window.location.replace('/fila');
  }, 25000);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortedFilaBagaco = filaOutros
    .filter(produto => produto.produto === 3)
    .sort((a, b) => {
      const formatDateA = moment(a.data).format('YYYY-MM-DD');
      const formatDateB = moment(b.data).format('YYYY-MM-DD');

      // First, compare the dates
      if (formatDateA > formatDateB) {
        return 1;
      } else if (formatDateA < formatDateB) {
        return -1;
      } else {
        // If dates are the same, compare the spots
        // if (a.spot > b.spot) {
        //   return 1;
        // } else if (a.spot < b.spot) {
        //   return -1;
        // } else {
        //   return 1;
        // }

        // If dates are the same, compare the filaid
        if (a.filaid > b.filaid) {
          return 1;
        } else if (a.filaid < b.filaid) {
          return -1;
        } else {
          return 1;
        }
      }
    });

  const sortedFilaVhp = filaOutros
    .filter(produto => produto.produto === 4)
    .sort((a, b) => {
      const formatDateA = moment(a.data).format('YYYY-MM-DD');
      const formatDateB = moment(b.data).format('YYYY-MM-DD');

      // First, compare the dates
      if (formatDateA > formatDateB) {
        return 1;
      } else if (formatDateA < formatDateB) {
        return -1;
      } else {
        // If dates are the same, compare the spots
        // if (a.spot > b.spot) {
        //   return 1;
        // } else if (a.spot < b.spot) {
        //   return -1;
        // } else {
        //   return 1;
        // }

        // If dates are the same, compare the filaid
        if (a.filaid > b.filaid) {
          return 1;
        } else if (a.filaid < b.filaid) {
          return -1;
        } else {
          return 1;
        }
      }
    });

  const bagacoCarregando = filaOutros.filter(
    veiculosInFila =>
      (veiculosInFila.status === 'PCAR' || veiculosInFila.status === 'ELIB') &&
      veiculosInFila.produto === 3,
  );

  const vhpCarregando = filaOutros.filter(
    veiculosInFila =>
      (veiculosInFila.status === 'PCAR' || veiculosInFila.status === 'ELIB') &&
      veiculosInFila.produto === 4,
  );

  console.log('VHP - carregando', vhpCarregando.length);
  console.log('Bagaco - carregando', bagacoCarregando.length);

  // Verificar quantos veículos estão no carregamento de VHP
  if (vhpCarregando.length < 3) {
    const getNextInLineVhp = filaOutros.find(
      nextInLine => nextInLine.status === 'AENT' && nextInLine.produto === 4,
    );

    if (getNextInLineVhp) {
      api.post('fila/status', {
        data: getNextInLineVhp.data,
        agendamentoid: getNextInLineVhp.agendamentoid,
        placa: getNextInLineVhp.placa,
        statusnew: 'ELIB',
        motivo: 'Liberação de entrada VHP pelo número de vagas < 3.',
      });
    }
  }

  // Verificar quantos veículos estão no carregamento de Bagaço
  if (bagacoCarregando.length < 3) {
    const getNextInLineBagaco = filaOutros.find(
      nextInLine => nextInLine.status === 'AENT' && nextInLine.produto === 3,
    );

    if (getNextInLineBagaco) {
      api.post('fila/status', {
        data: getNextInLineBagaco.data,
        agendamentoid: getNextInLineBagaco.agendamentoid,
        placa: getNextInLineBagaco.placa,
        statusnew: 'ELIB',
        motivo: 'Liberação de entrada Bagaço pelo número de vagas < 3.',
      });
    }
  }

  return (
    <ContainerHolder>
      <Container>
        <header>
          {/* <h1>Fila Carregamento - Outros Produtos</h1> */}
          <h1>Fila Carregamento - Bagaço</h1>
        </header>

        {/* Table */}
        <TableContainer>
          <table id="tableDataRegistries">
            {/* This header are being used for displaying header on the report */}
            <thead className="headTable">
              <tr>
                <th>Fila Id</th>
                <th>Agend Certif</th>
                {/* <th>Produto</th> */}
                <th>Data</th>
                <th>Placa Carreta</th>
                <th>Status</th>

                <th
                  style={{
                    paddingLeft: '20px',
                    textAlign: 'left',
                  }}
                >
                  Nome Motorista
                </th>
              </tr>
            </thead>

            {sortedFilaVhp
              .filter(data => data.produto === 4)
              .map(filaData => (
                <tbody key={filaData.filaid}>
                  <tr>
                    <td>{filaData.filaid}</td>
                    <td>{filaData.agendamentoid}</td>
                    {/* <td>
                      {filaData.produto === 3
                        ? 'Bagaço'
                        : filaData.produto === 4
                        ? 'VHP'
                        : 'Error'}
                    </td> */}
                    <td>{formatDateBr(filaData.data)}</td>
                    <td>{filaData.placa}</td>

                    <td
                      style={
                        filaData.status === 'AGPE'
                          ? { color: 'red' }
                          : filaData.status === 'APPE'
                          ? { color: 'red' }
                          : { color: '#171412' }
                      }
                    >
                      {filaData.status === 'AGPE'
                        ? 'Aguardando Pedido'
                        : filaData.status === 'APPE'
                        ? 'Aguardando Pag. Pedido'
                        : filaData.status === 'ACKL'
                        ? 'Aguardando Checklist'
                        : filaData.status === 'AENT'
                        ? 'Aguardando Liberação Entrada'
                        : filaData.status === 'ELIB'
                        ? 'Pesagem Liberada'
                        : filaData.status === 'PCAR'
                        ? 'Processo Carregamento'
                        : filaData.status === 'AFAT'
                        ? 'Aguard. Faturamento'
                        : filaData.status === 'ADOC'
                        ? 'Aguard. Docs Transportadora'
                        : filaData.status === 'NLIB'
                        ? 'Nota Fiscal Liberada Coleta'
                        : filaData.status === 'CAFI'
                        ? 'Carregamento Finalizado'
                        : 'Erro'}
                    </td>
                    <td
                      style={{
                        textAlign: 'left',
                        paddingLeft: '20px',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {filaData.motorista}
                    </td>
                  </tr>
                </tbody>
              ))}
          </table>
        </TableContainer>
      </Container>
      <Container>
        <header>
          <h1>Fila Carregamento - VHP</h1>
        </header>

        {/* Table */}
        <TableContainer>
          <table id="tableDataRegistries">
            {/* This header are being used for displaying header on the report */}
            <thead className="headTable">
              <tr>
                <th>Fila ID</th>
                <th>Agend Certif</th>
                {/* <th>Prod.</th> */}
                <th>Data</th>
                <th>Placa Carreta</th>

                <th>Status</th>
                <th style={{ paddingLeft: '20px', textAlign: 'left' }}>
                  Nome Motorista
                </th>
              </tr>
            </thead>

            {sortedFilaVhp
              .filter(fila => fila.produto === 4)
              .map(filaData => (
                <tbody key={filaData.filaid}>
                  <tr>
                    <td>{filaData.filaid}</td>
                    <td>{filaData.agendamentoid}</td>
                    {/* <td>
                      {filaData.produto === 3
                        ? 'Bagaço'
                        : filaData.produto === 4
                        ? 'VHP'
                        : 'Error'}
                    </td> */}
                    <td>{formatDateBr(filaData.data)}</td>
                    <td>{filaData.placa}</td>

                    <td
                      style={
                        filaData.status === 'AGPE'
                          ? { color: 'red' }
                          : filaData.status === 'APPE'
                          ? { color: 'red' }
                          : { color: '#171412' }
                      }
                    >
                      {filaData.status === 'AGPE'
                        ? 'Aguardando Pedido'
                        : filaData.status === 'APPE'
                        ? 'Aguardando Pag. Pedido'
                        : filaData.status === 'ACKL'
                        ? 'Aguardando Checklist'
                        : filaData.status === 'AENT'
                        ? 'Aguardando Liberação Entrada'
                        : filaData.status === 'ELIB'
                        ? 'Pesagem Liberada'
                        : filaData.status === 'PCAR'
                        ? 'Processo Carregamento'
                        : filaData.status === 'AFAT'
                        ? 'Aguard. Faturamento'
                        : filaData.status === 'ADOC'
                        ? 'Aguard. Docs Transportadora'
                        : filaData.status === 'NLIB'
                        ? 'Nota Fiscal Liberada Coleta'
                        : filaData.status === 'CAFI'
                        ? 'Carregamento Finalizado'
                        : 'Erro'}
                    </td>
                    <td
                      style={{
                        textAlign: 'left',
                        paddingLeft: '20px',
                      }}
                    >
                      {filaData.motorista}
                    </td>
                  </tr>
                </tbody>
              ))}
          </table>
        </TableContainer>
      </Container>
    </ContainerHolder>
  );
}

export default FilaOutrosProdutos;
