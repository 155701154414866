import React, { ReactElement, useEffect, useState } from 'react';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import 'react-day-picker/dist/style.css';

import moment from 'moment';
import 'moment/locale/pt-br';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

import { Container, Calendar, TableContainer } from './styles';
import api from '../../services/api';
import { formatDate } from '../../utils/formatDate';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {
  Box,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { format, startOfDay } from 'date-fns';

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  weekdays: [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ],
  weekdaysShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
});

interface IPesagens {
  id: string;
  pesagemid: number;
  carregamentoid: number;
  data: string;
  placa: string;
  placa2: string;
  cpf: string;
  motorista: string;
  volumecarregartotal: number;
  volumecarregado: number;
  tara: number;
  bruto: number;
  liquido: number;
  status: string;
  produto: number;
}

interface ICarregamentoData {
  carregamentoid: number;
  senhacarregamento: number;
  data: Date;
  placa: string;
  compartimento: number;
  dnit: boolean;
  volume: number;
  volumecarregar: number;
  pedido: number;
  status: string;
  lacres: string;
  agendamentoid: number;
  produto: number;
}

function PainelCarregamentos(): ReactElement {
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const [pesagens, setPesagens] = useState<IPesagens[]>([]);
  const theme = useTheme();

  // DATE PICKER
  const [selectedDate, setSelectedDate] = useState(new Date());
  const dateNow = moment(selectedDate).format('YYYY-MM-DD');

  const [selectedDay, setSelectedDay] = useState();
  const [loading, setLoading] = useState(false);

  const [statusFilter, setStatusFilter] = useState<string | null>('all');
  const [productsFilter, setProductsFilter] = useState<string | null>(
    'allproducts',
  );
  const [filteredPesagens, setFilteredPesagens] = useState(pesagens);
  const [carregamentosData, setCarregamentosData] = useState<
    ICarregamentoData[] | null
  >(null);

  // Load Agendamentos Information
  useEffect(() => {
    async function loadPesagens(): Promise<void> {
      setLoading(true);
      await api
        .get(`/pesagens`, {
          params: {
            data: dateNow,
          },
        })
        .then(async response => {
          const pesagensData = response.data;

          const beginOfDate = startOfDay(selectedDate);
          const formattedDate = format(beginOfDate, 'yyyy-MM-dd');
          const carregamentoids = pesagensData.map(pes => pes.carregamentoid);

          const loadedCarregamentosData = await api
            .get(`/carregamentos/byids`, {
              params: {
                // carregamentoids: carregamentoids,
                carregamentoids: carregamentoids.join(','),
                data: formattedDate,
              },
            })
            .then(response => {
              return response.data;
            });

          if (loadedCarregamentosData === null) {
            setPesagens(pesagensData);
            setFilteredPesagens(pesagensData);
            return;
          }

          // Create a mapping of carregamentoData by carregamentoid
          const carregamentosMap = new Map<number, number>();
          loadedCarregamentosData.forEach(item => {
            carregamentosMap.set(item.carregamentoid, item.produto);
          });

          // Add product to each pesagem
          const updatedPesagensData = pesagensData.map(pesagem => ({
            ...pesagem,
            produto: carregamentosMap.get(pesagem.carregamentoid) || null, // Add product or null if not found
          }));

          setCarregamentosData(loadedCarregamentosData);
          setPesagens(updatedPesagensData);
          setFilteredPesagens(updatedPesagensData);

          setLoading(false);
        });
    }
    loadPesagens();
  }, [dateNow]);

  function handleReduceTotal() {
    const newArray = filteredPesagens.map(agn => agn.volumecarregado);

    return new Intl.NumberFormat('pt-BR').format(
      newArray.reduce((prev, curr) => prev + curr, 0),
    );
  }

  // Generate pdf report
  function handleReportGenerate() {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF({
      orientation: 'landscape',
    });

    // eslint-disable-next-line func-names
    const reportHeader = function (data) {
      doc.setFontSize(18);
      doc.setFont('Helvetica', 'bold');
      doc.setTextColor(0, 168, 90);
      doc.text(
        `Bom Sucesso Agroindústria - Carregamentos realizados em ${moment(
          selectedDate,
        ).format('DD/MM/YYYY')}`,
        data.settings.margin.left,
        15,
      );
    };

    // define an empty array of rows
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tableRows: any = [];

    // for each registry pass all its data into an array

    pesagens.forEach(pesagem => {
      const pesagensData = [
        moment(pesagem.data).format('DD/MM/YYYY'),
        pesagem.placa,
        pesagem.placa2,
        pesagem.motorista,
        pesagem.tara,
        pesagem.bruto,
        pesagem.liquido,
        pesagem.status,
        pesagem.volumecarregartotal,
        pesagem.volumecarregado,
      ];
      // push each record info into a row
      tableRows.push(pesagensData);
    });

    // generate the table based in the table below
    autoTable(doc, {
      html: '#tableDataPesagens',
      startY: 20,
      margin: 20,
      showFoot: 'lastPage',
      showHead: 'everyPage',
      rowPageBreak: 'avoid',
      didDrawPage: reportHeader,
      headStyles: {
        fillColor: [0, 168, 90],
        valign: 'middle',
        halign: 'center',
      },
      footStyles: {
        fillColor: [0, 168, 90],
      },
      bodyStyles: {
        fontSize: 8,
        valign: 'middle',
        halign: 'center',
      },
    });

    const date = Date().split(' ');
    // we use a date string to generate our filename.
    const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];

    // name of file to be saved
    doc.save(`RelatorioCarregamentos${dateStr}.pdf`);
  }

  const handleCalendarDayChange = date => {
    setStatusFilter('all');
    setProductsFilter('allproducts');
    const formattedDate = dayjs(date).add(1, 'd').format('YYYY-MM-DD');
    setSelectedDay(date);
    setSelectedDate(new Date(formattedDate));
  };

  // Function to fetch filaData on demand when the user selects "open"
  // const fetchCarregamentoData = async () => {
  //   const beginOfDate = startOfDay(selectedDate);
  //   const formattedDate = format(beginOfDate, 'yyyy-MM-dd');

  //   const carregamentoids = pesagens.map(pes => pes.carregamentoid);

  //   setLoading(true);
  //   const carregamentosData = await api
  //     .get(`/carregamentos/byids`, {
  //       params: {
  //         carregamentoids: carregamentoids,
  //         data: formattedDate,
  //       },
  //     })
  //     .then(response => {
  //       return response.data;
  //     });

  //   setCarregamentosData(carregamentosData);
  //   setLoading(false);
  //   return carregamentosData;
  // };

  // Function to filter carregamentos based on the filters
  const filterData = async () => {
    let filtered = [...pesagens];

    // Filter by status first
    if (statusFilter === 'open' && carregamentosData) {
      // const openedStatuses = ['ACKL', 'AENT', 'ELIB', 'PCAR'];
      const filteredPesagens = pesagens.filter(
        pesagem => pesagem.status === 'Aguardando Carregamento',
      );

      filtered = filteredPesagens;
    }

    if (statusFilter === 'done' && carregamentosData) {
      const filteredPesagens = pesagens.filter(
        pesagem => pesagem.status === 'Carregamento Finalizado',
      );

      filtered = filteredPesagens;
    }

    // Filter by product next
    if (productsFilter !== 'allproducts') {
      const filteredByProduto = carregamentosData
        ?.filter(
          carregamento => carregamento.produto === Number(productsFilter),
        )
        .map(carregamento => carregamento.carregamentoid);

      filtered = filtered.filter(
        pesagem => filteredByProduto?.includes(pesagem.carregamentoid),
      );
    }

    // Update filtered agendamentos
    setFilteredPesagens(filtered);
  };

  // Handle changes to status filter
  const handleStatusFilterChange = async (
    event: React.MouseEvent<HTMLElement>,
    filter: string | null,
  ) => {
    if (filter) {
      setStatusFilter(filter);

      if (filter === 'open') {
        // Only fetch filaData if the status is 'open' and it's not already fetched
        // await fetchCarregamentoData();
        filterData();
      } else if (filter === 'done') {
        // Only fetch filaData if the status is 'open' and it's not already fetched
        // await fetchCarregamentoData();
        filterData();
      } else if (filter === 'all') {
        // await fetchCarregamentoData();
        filterData();
      }
    }
  };

  // Handle changes to product filter
  const handleProductsFilterChange = async (
    event: React.MouseEvent<HTMLElement>,
    produto: string,
  ) => {
    setProductsFilter(produto);
    // await fetchCarregamentoData();
    filterData();
  };

  // Re-filter data whenever any filter changes or filaData is fetched
  useEffect(() => {
    filterData();
  }, [statusFilter, productsFilter, carregamentosData]); // Re-filter when any of these change

  const labelFiltrosStyles = {
    width: '100%',
    marginBottom: '2px',
    color: theme.palette.primary.main,
    textAlign: 'center',
    opacity: 0.8,
  };

  return (
    <Container>
      <header>
        <h1>Bom Sucesso Agroindústria S.A</h1>

        <nav>
          <button type="submit" onClick={() => navigate('/')}>
            Página Inicial
          </button>

          <button type="submit" onClick={() => navigate('/painellogistica')}>
            Voltar
          </button>

          <button type="submit" onClick={signOut}>
            Logout
          </button>
        </nav>
      </header>

      <Box
        sx={{
          height: '64px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingRight: '10px',
          paddingLeft: '10px',
        }}
      >
        <Box
          sx={{
            justifyContent: 'space-evenly',
            display: 'flex',
            alignContent: 'center',
            flex: 1,
          }}
        >
          <div id="buttonReportHolder">
            {/* <h4>Agendamentos</h4> */}
            {pesagens.length > 0 && (
              <button
                type="submit"
                style={{
                  position: 'absolute',
                  backgroundColor: '#00a859',
                  color: '#fff',
                  fontWeight: 'bold',
                  border: 'none',
                  fontFamily: 'Poppins',
                  height: '40px',
                  // marginTop: '30px',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  borderRadius: '4px',
                  // right: 10,
                  left: 10,
                }}
                onClick={() => handleReportGenerate()}
              >
                Gerar Relatório
              </button>
            )}
          </div>

          <h2 id="pageTitle">Consulta Carregamentos</h2>
        </Box>

        <Calendar>
          <Box
            justifyContent="flex-end"
            display="flex"
            alignItems="center"
            width="27rem"
          >
            <Typography variant="h5" color="#00a859" fontWeight={600} mr={1}>
              {!selectedDay && 'Escolha uma data'}
              {selectedDay && 'Data base'}
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DesktopDatePicker
                  onChange={chosenDate => handleCalendarDayChange(chosenDate)}
                  format="DD/MM/YYYY"
                  closeOnSelect
                  views={['year', 'month', 'day']}
                  sx={{
                    width: '10rem',
                    textAlign: 'center',
                    '&.MuiOutlinedInput-input': {
                      width: '6rem !important',
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        </Calendar>
      </Box>

      {/* Table Carregamentos */}

      {loading && (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          marginTop="10rem"
        >
          <CircularProgress sx={{ color: '#00a859' }} />
        </Box>
      )}

      {pesagens.length === 0 && !loading && (
        <h4 style={{ color: '#333' }}>
          Não foram encontrados carregamentos para esta data
        </h4>
      )}

      {pesagens.length > 0 && !loading && (
        <Box
          sx={{
            paddingLeft: '20px',
            paddingRight: '30px',
            marginTop: '20px',
            marginBottom: '4px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography sx={labelFiltrosStyles}>Status Fila</Typography>
            <ToggleButtonGroup
              value={statusFilter}
              exclusive
              onChange={handleStatusFilterChange}
              aria-label="status filter"
              color="primary"
            >
              <ToggleButton defaultChecked value="all" aria-label="all">
                Todos
              </ToggleButton>
              <ToggleButton value="open" aria-label="open">
                Abertos
              </ToggleButton>
              <ToggleButton value="done" aria-label="done">
                Finalizados
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box>
            <Typography sx={labelFiltrosStyles}>Filtro Produtos</Typography>
            <ToggleButtonGroup
              value={productsFilter}
              exclusive
              onChange={handleProductsFilterChange}
              aria-label="status filter"
              color="primary"
            >
              <ToggleButton
                defaultChecked
                value="allproducts"
                aria-label="hidratado"
              >
                Todos
              </ToggleButton>
              <ToggleButton value="1" aria-label="hidratado">
                1 - Hidratado
              </ToggleButton>
              <ToggleButton value="2" aria-label="outrosfins">
                2 - Outros Fins
              </ToggleButton>
              <ToggleButton value="3" aria-label="bagaco">
                3 - Bagaço
              </ToggleButton>
              <ToggleButton value="4" aria-label="vhp">
                4 - VHP
              </ToggleButton>
              <ToggleButton value="5" aria-label="anidro">
                5 - Anidro
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      )}

      {/* Table */}
      <TableContainer>
        <table id="tableDataPesagens">
          <thead style={{ visibility: 'hidden' }}>
            <tr>
              <th>Data</th>
              <th>Carreta</th>
              <th>Carreta2</th>
              <th>Motorista</th>
              <th>Tara</th>
              <th>Bruto</th>
              <th>Liquido</th>
              <th>Status</th>
              <th>Produto</th>
              <th>Volume Carregar</th>
              <th>Volume Carregado20</th>
            </tr>
          </thead>

          {filteredPesagens.length > 0 && !loading ? (
            <thead>
              <th>Data</th>
              <th>Carreta</th>
              <th>Carreta2</th>
              <th>Motorista</th>
              <th>Tara</th>
              <th>Bruto</th>
              <th>Liquido</th>
              <th>Status</th>
              <th>Produto</th>
              <th>Volume Carregar</th>
              <th>Volume Carregado20</th>
            </thead>
          ) : null}

          {filteredPesagens.length > 0 && !loading
            ? filteredPesagens
                .sort((a, b) => (a.data > b.data ? 1 : -1))
                // Mostrar apenas pedidos com saldos > 0
                // .filter(sld => sld.saldo > 0)
                .map(pesagensData => (
                  <tbody key={pesagensData.pesagemid}>
                    <tr>
                      <td className="cnDtEmissao">
                        {formatDate(new Date(pesagensData.data))}
                      </td>
                      <td className="cnPlacas">{pesagensData.placa}</td>
                      <td className="cnPlacas">{pesagensData.placa2}</td>
                      <td className="cnMotorista">{pesagensData.motorista}</td>
                      <td className="cnPesos">{pesagensData.tara}</td>
                      <td className="cnPesos">{pesagensData.bruto}</td>
                      <td className="cnPesos">{pesagensData.liquido}</td>
                      <td className="cnStatus">{pesagensData.status}</td>
                      <td className="cnProduto">
                        {pesagensData.produto === 1
                          ? 'Hidratado'
                          : pesagensData.produto === 2
                          ? 'Etanol - Outros Fins'
                          : pesagensData.produto === 3
                          ? 'Bagaço'
                          : pesagensData.produto === 4
                          ? 'Açúcar VHP'
                          : pesagensData.produto === 5
                          ? 'Anidro'
                          : null}
                      </td>
                      <td className="cnVolume">
                        {new Intl.NumberFormat('pt-BR').format(
                          pesagensData.volumecarregartotal,
                        )}
                      </td>
                      <td className="cnVolume">
                        {new Intl.NumberFormat('pt-BR').format(
                          pesagensData.volumecarregado,
                        )}
                      </td>
                    </tr>
                  </tbody>
                ))
            : null}
          <tfoot>
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th>
                {filteredPesagens.length > 0 && !loading ? 'Total' : null}
              </th>
              <th>
                {filteredPesagens.length > 0 && !loading
                  ? handleReduceTotal()
                  : null}
              </th>
            </tr>
          </tfoot>
        </table>
      </TableContainer>
    </Container>
  );
}

export default PainelCarregamentos;
