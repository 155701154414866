/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';

import { FiCheckSquare } from 'react-icons/fi';

import moment from 'moment';
import isWeekend from 'date-fns/isWeekend';
import { ButtonGroup } from '../../styles/customButtonGroup';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import Modal from '../Modal';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MaskedInput } from '../Material/MaskedInput';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

interface IFormData {
  data: Date;
}

function ModalCarregamentoFds({
  isOpen,
  setIsOpen,
}: IModalProps): ReactElement {
  const { addToast } = useToast();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormData>();

  const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
    // EXTRACT DATE
    const toFormatString = String(data.data);
    const day = toFormatString.substring(0, 2);
    const month = toFormatString.substring(3, 5);
    const year = toFormatString.substring(6, 10);

    try {
      const checkDate = isWeekend(
        new Date(
          moment(`${year}-${month}-${day}`).format('YYYY-MM-DD 00:00:00.000'),
        ),
      );

      if (!checkDate) {
        // Show Toast for success
        addToast({
          type: 'error',
          title: 'Erro cadastrar agendamento final de semana.',
          description: `Data informada não é final de semana.`,
        });
        return;
      }

      // POST PARA CRIAR CARREGAMENTO FDS
      await api.post('/agendamentofds', {
        data: `${year}-${month}-${day}`,
        carregamento: true,
      });

      // Close the modal
      setIsOpen();

      // Show Toast for success
      addToast({
        type: 'success',
        title: 'Agendado Carregamento final de semana.',
        description: `Carregamento aberto para o dia ${day}/${month}/${year}.`,
      });

      // reset form
      reset();
    } catch (err: any) {
      // Close the modal
      setIsOpen();

      // Show Toast for failure
      addToast({
        type: 'error',
        title: 'Erro cadastrar agendamento final de semana.',
        // description: 'Verifique a data informada.',
        description: `${err.response.data.message}`,
      });

      // reset form
      reset();
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
          gap: '1rem',
        }}
      >
        <h1
          style={{
            fontWeight: '500',
            fontSize: '36px',
            lineHeight: '36px',
            marginBottom: '40px',
            color: '#00a859',
            textAlign: 'center',
          }}
        >
          Cadastro Carregamento Final de Semana
        </h1>

        <MaskedInput
          {...register('data', { required: 'Data obrigatória.' })}
          control={control}
          type="date"
          name="data"
          label="Data"
          onBlur={() => {}}
          error={!!errors.data}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <ButtonGroup>
          <button type="submit" name="SaveButton">
            <div className="text">Salvar</div>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>
        </ButtonGroup>
      </form>
    </Modal>
  );
}

export default ModalCarregamentoFds;
