/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useCallback, useState, ReactElement } from 'react';

import { FiCheckSquare } from 'react-icons/fi';
import { FormHandles } from '@unform/core';

import { AiOutlineSelect } from 'react-icons/ai';
import { Form } from './styles';
import { ButtonGroup } from '../../styles/customButtonGroup';
import Modal from '../Modal';
import Input from '../Input';
import Select from '../Select';
import TextArea from '../TextArea';

interface IJobsData {
  id: string;
  jobid: number;
  jobtitle: string;
  shift: string;
  education: string;
  contract: string;
  description: string;
  open: boolean;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleUpdateJobs: (jobs: IJobsData) => void;
  editingJobs: IEditJobsData;
}

interface IEditJobsData {
  id: string;
  jobid: number;
  jobtitle: string;
  shift: string;
  education: string;
  contract: string;
  description: string;
  open: boolean;
}

function ModalEditJobs({
  isOpen,
  setIsOpen,
  editingJobs,
  handleUpdateJobs,
}: IModalProps): ReactElement {
  const formRef = useRef<FormHandles>(null);
  const selectOptions = [
    'Status da Vaga: Aberta / Fechada',
    'Aberta',
    'Fechada',
  ];

  const [selectedOption, setSelectedOption] = useState(selectOptions[0]);

  const handleSubmit = useCallback(
    async (data: IEditJobsData) => {
      handleUpdateJobs(data);
      setIsOpen();
      window.location.reload();
    },
    [handleUpdateJobs, setIsOpen],
  );

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function handleSelect(data: string) {
    setSelectedOption(data);
  }

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={editingJobs}>
        <h1>Editar Vaga</h1>

        <label htmlFor="jobTitle">Título</label>
        <Input name="jobTitle" placeholder="Name" />

        <label htmlFor="shift">Turno</label>
        <Input name="shift" placeholder="Turno (Adm/5x1/6x1)" />

        <label htmlFor="education">Formação Acadêmica</label>
        <Input name="education" placeholder="Formação" />

        <label htmlFor="contract">Tipo Contrato</label>
        <Input name="contract" placeholder="CLT / Safra" />

        <label htmlFor="description">Descrição</label>
        <TextArea
          name="description"
          placeholder="Descrição da Vaga"
          cols={10}
          rows={5}
        />

        <label htmlFor="open">Status</label>
        <Select
          name="open"
          icon={AiOutlineSelect}
          style={{
            color:
              // eslint-disable-next-line no-nested-ternary
              selectedOption === 'Aberta'
                ? '#fff'
                : selectedOption === 'Fechada'
                ? '#fff'
                : '#666360',
          }}
          value={selectedOption}
          onChange={e => handleSelect(e.target.value)}
        >
          {selectOptions.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>

        <ButtonGroup>
          <button type="submit" name="SaveButton">
            <div className="text">Salvar</div>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>
        </ButtonGroup>
      </Form>
    </Modal>
  );
}

export default ModalEditJobs;
