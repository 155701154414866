/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ReactElement, useEffect } from 'react';

import { FiCheckSquare } from 'react-icons/fi';
import { ButtonGroup } from '../../styles/customButtonGroup';

import Modal from '../Modal';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MaskedInput } from '../Material/MaskedInput';
import { formatDateBr } from '../../utils/formatDate';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  filaData: any;
}

interface IFormData {
  data: Date | string;
  agendamentoid: number;
  placa: string;
  placa2: string;
  cpf: string;
  user: string;
}

function ModalFinalizaCarregamento({
  isOpen,
  setIsOpen,
  filaData,
}: IModalProps): ReactElement {
  const { user } = useAuth();
  const { addToast } = useToast();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm<IFormData>();

  useEffect(() => {
    if (filaData) {
      setValue('data', formatDateBr(filaData.data));
      setValue('agendamentoid', filaData.agendamentoid);
      setValue('placa', filaData.placa);
      setValue('cpf', filaData.cpf);
    }
  }, [filaData]);

  const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
    // Formatting the data
    const toFormatString = String(data.data);
    const day = toFormatString.substring(0, 2);
    const month = toFormatString.substring(3, 5);
    const year = toFormatString.substring(6, 10);

    // Trim trailing slashes on InputMask
    let cpfFormatted = data.cpf;

    if (cpfFormatted.endsWith('_')) {
      cpfFormatted = cpfFormatted.slice(0, -1);
    }

    try {
      await api.post('/fila/finalizacarregamento', {
        data: `${year}-${month}-${day}`,
        agendamentoid: data.agendamentoid,
        cpf: data.cpf,
        user: user.name,
      });

      // Close the modal
      setIsOpen();

      // Show Toast for success
      addToast({
        type: 'success',
        title: 'Carregamento Finalizado',
        description: `Carregamento ref. Placa ${data.placa} foi finalizado com sucesso!`,
      });

      // Reset the form
      reset();
    } catch (err: any) {
      // Close the modal
      setIsOpen();

      // Show Toast for failure
      addToast({
        type: 'error',
        title: 'Erro ao finalizar carregamento.',
        // description: 'Verifique se os dados informados estão corretos.',
        description: `${err.response.data.message}`,
      });

      // Reset the form
      reset();
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
          gap: '1rem',
        }}
      >
        <h1
          style={{
            fontWeight: '500',
            fontSize: '36px',
            lineHeight: '36px',
            marginBottom: '40px',
            color: '#00a859',
            textAlign: 'center',
          }}
        >
          Finaliza Carregamento
        </h1>

        <MaskedInput
          {...register('data', { required: 'Data obrigatória.' })}
          control={control}
          type="date"
          name="data"
          label="Data"
          onBlur={() => {}}
          error={!!errors.data}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MaskedInput
          {...register('agendamentoid', {
            required: 'Agendamento ID obrigatório.',
          })}
          control={control}
          type="none"
          name="agendamentoid"
          label="Agend. ID"
          onBlur={() => {}}
          error={!!errors.agendamentoid}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MaskedInput
          {...register('cpf', { required: 'CPF obrigatório.' })}
          control={control}
          type="cpf"
          name="cpf"
          label="CPF"
          onBlur={() => {}}
          error={!!errors.cpf}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MaskedInput
          {...register('placa', { required: 'Placa obrigatória.' })}
          control={control}
          type="placa"
          name="placa"
          label="Placa"
          onBlur={() => {}}
          error={!!errors.placa}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <ButtonGroup>
          <button type="submit" name="SaveButton">
            <div className="text">Salvar</div>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>
        </ButtonGroup>
      </form>
    </Modal>
  );
}

export default ModalFinalizaCarregamento;
