/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';

import { ButtonGroup } from '../../../styles/customButtonGroup';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import Modal from '../../Modal';
import { MaskedInput } from '../../Material/MaskedInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import MuiSelect from '../../Material/Select';
import { FiCheckSquare } from 'react-icons/fi';
import moment from 'moment';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

interface IFormData {
  data: string;
  produto: string;
  tanque: string;
  certificado: string;
  deposito: string;
}

const ModalCertificadoQualidade = React.forwardRef<
  HTMLInputElement,
  IModalProps
>(({ isOpen, setIsOpen }, ref) => {
  const { addToast } = useToast();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormData>();

  const produtosOptions = ['Etanol Hidratado', 'Anidro'];
  const depositoOptions = ['IT01', 'IT02', 'IT03', 'IT04', 'IT05'];

  const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
    // EXTRACT DATE
    const formatDataAgendamento = String(data.data);
    const day = formatDataAgendamento.substring(0, 2);
    const month = formatDataAgendamento.substring(3, 5);
    const year = formatDataAgendamento.substring(6, 10);
    const timeNow = moment().format('HH:mm:ss');

    const formattedDate = `${year}-${month}-${day} ${timeNow}`;

    try {
      await api.post('/certificadoqualidade', {
        data: formattedDate,
        produto: data.produto === 'Etanol Hidratado' ? 1 : 5,
        tanque: data.tanque,
        certificado: data.certificado,
        deposito: data.deposito,
      });

      // Close the modal
      setIsOpen();

      // Show Toast for success
      addToast({
        type: 'success',
        title: 'Sucesso.',
        description: `Certificado qualidade salvo com sucesso!`,
      });

      // reset form
      reset();
    } catch (err: any) {
      // Close the modal
      setIsOpen();

      // Show Toast for failure
      addToast({
        type: 'error',
        title: 'Erro ao salvar certificado qualidade.',
        description: `${err.response.data.message}`,
      });

      // reset form
      reset();
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
          gap: '1rem',
        }}
      >
        <h1
          style={{
            fontWeight: '500',
            fontSize: '36px',
            lineHeight: '36px',
            marginBottom: '40px',
            color: '#00a859',
            textAlign: 'center',
          }}
        >
          Certificado Qualidade
        </h1>

        <MaskedInput
          {...register('data', { required: 'Data obrigatória.' })}
          control={control}
          type="date"
          name="data"
          label="Data"
          onBlur={() => {}}
          error={!!errors.data}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MuiSelect
          {...register('produto', {
            required: 'Produto obrigatório.',
          })}
          control={control}
          name="produto"
          label="Produto"
          variant="outlined"
          options={produtosOptions}
          error={Boolean(errors.produto)}
          ref={ref}
          sx={{ width: '237px' }}
        />

        <MaskedInput
          {...register('tanque', {
            required: 'Tanque obrigatório.',
          })}
          control={control}
          type="none"
          name="tanque"
          label="Número Tanque"
          onBlur={() => {}}
          error={!!errors.tanque}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MaskedInput
          {...register('certificado', {
            required: 'Certificado obrigatório.',
          })}
          control={control}
          type="none"
          name="certificado"
          label="Número Certificado"
          onBlur={() => {}}
          error={!!errors.certificado}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MuiSelect
          {...register('deposito', {
            required: 'Deposito obrigatório.',
          })}
          control={control}
          name="deposito"
          label="Deposito"
          variant="outlined"
          options={depositoOptions}
          error={Boolean(errors.deposito)}
          ref={ref}
          sx={{ width: '237px' }}
        />

        <ButtonGroup>
          <button type="submit" name="SaveButton">
            <div className="text">Salvar</div>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>
        </ButtonGroup>
      </form>
    </Modal>
  );
});

ModalCertificadoQualidade.displayName = 'ModalCertificadoQualidade';

export default ModalCertificadoQualidade;
