import React, { ReactElement, useState } from 'react';

import { PageTitle, CardContainer, Card } from './styles';

import Modal from '../../Modal';
import ModalResetPasswordTransportadora from '../ModalResetPasswordTransportadora';
import { FaLock } from 'react-icons/fa';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

function ModalTransportadoraProfile({
  isOpen,
  setIsOpen,
}: IModalProps): ReactElement {
  const [
    resetPasswordTransportadoraModalOpen,
    setResetPasswordTransportadoraModalOpen,
  ] = useState(false);

  // Toogle modal reset password transportadora
  function toogleResetPasswordTransportadoraModal(): void {
    setResetPasswordTransportadoraModalOpen(
      !resetPasswordTransportadoraModalOpen,
    );
  }

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <ModalResetPasswordTransportadora
        isOpen={resetPasswordTransportadoraModalOpen}
        setIsOpen={() => toogleResetPasswordTransportadoraModal()}
      />

      <PageTitle>Transportadora Profile</PageTitle>

      <CardContainer>
        {/* Update Login */}
        {/* <Card onClick={() => toogleUpdateLoginTransportadoraModal()}>
          <h1
            className="updateTransportadoraLogin"
            id="updateTransportadoraLogin"
            data-testid="updateTransportadoraLogin"
          >
            Update Login
          </h1>
          <RiUserAddLine size={42} color="#fff" />
        </Card> */}

        {/* Change password */}
        <Card onClick={() => toogleResetPasswordTransportadoraModal()}>
          <h1
            className="changePasswordTransportadora"
            id="changePasswordTransportadora"
            data-testid="changePasswordTransportadora"
          >
            Reset password
          </h1>
          <FaLock size={38} color="#fff" />
        </Card>
      </CardContainer>
    </Modal>
  );
}

export default ModalTransportadoraProfile;
