import styled from 'styled-components';
// import { shade } from 'polished';

export const Container = styled.div`
  display: block;
  width: 100vw !important;
  height: 100vmin !important;
  background: #fff;
  position: absolute;

  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 10px;
    z-index: 99;
    position: relative;

    h1 {
      color: #00a859;
      font-weight: 600;
    }

    nav {
      button {
        border: 1px solid #00a859;
        background-color: transparent;
        border-radius: 4px;
        color: #171412;
        font-weight: 600;
        font-family: 'Poppins';
        height: 40px;
        padding-left: 8px;
        padding-right: 8px;
      }

      > button {
        margin-left: 10px;
      }

      & :hover {
        cursor: pointer;
        color: #00a859;
      }
    }
  } /* END HEADER */

  #pageTitle {
    justify-content: center;
    text-align: center;
    color: #00a859;
    font-weight: 600;
    margin: 0 auto;

    :after {
      display: block;
      text-align: center;
      margin: 0 auto;
      content: '';
      width: 40%;
      border-bottom: 3px solid #00a859;
      animation: 1s ease-in 0s 1 slideInFromLeft;
    }

    @keyframes slideInFromLeft {
      0% {
        transform: translateX(-40%);
      }
      100% {
        transform: translateX(0);
      }
    }
  }

  #selectOption {
    color: #363f5f;
    padding-top: 10rem;
    justify-content: center;
    text-align: center;
  }
`;

export const CardContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 30px;
  margin: 4rem auto;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  background: #00a859;
  padding: 22px 32px;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }

  h1 {
    margin-top: 14px;
    font-size: 24px;
    font-weight: 600;
    line-height: 54px;
    text-align: center;

    color: #fff;
  }

  svg {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-self: auto;
  }
`;
