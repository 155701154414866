/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';

import { FiCheckSquare } from 'react-icons/fi';

import { ButtonGroup } from '../../styles/customButtonGroup';

import Modal from '../Modal';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';

import { Box, Stack } from '@mui/material';
import { MaskedInput } from '../Material/MaskedInput';
import { SubmitHandler, useForm } from 'react-hook-form';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

interface IFormData {
  cnh: number;
  nome: string;
  cpf: string;
}

function ModalInsertMotoristas({
  isOpen,
  setIsOpen,
}: IModalProps): ReactElement {
  const { addToast } = useToast();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormData>();

  const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
    try {
      await api.post('/motoristas', {
        cnh: data.cnh,
        nome: data.nome
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase(),
        cpf: data.cpf,
      });

      // Close the modal
      setIsOpen();

      // Show Toast for success
      addToast({
        type: 'success',
        title: 'Motorista Cadastrado',
        description: 'Seu registro foi salvo com sucesso!',
      });

      // reset the form
      reset();
    } catch (err: any) {
      // Close the modal
      setIsOpen();

      // Show Toast for failure
      addToast({
        type: 'error',
        title: 'Erro ao cadastrar motorista.',
        description: `${err.response.data.message}`,
      });

      // reset the form
      reset();
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <h1>Cadastro Motorista</h1>
        <Stack gap={2} padding={2} mt={2}>
          <MaskedInput
            {...register('nome', { required: 'Nome obrigatório.' })}
            control={control}
            type="none"
            name="nome"
            label="Nome Completo"
            onBlur={() => {}}
            error={!!errors.nome}
            autoComplete={'off'}
            sx={{ width: '408px' }}
          />
          <Box display="flex" gap={1}>
            <MaskedInput
              {...register('cpf', { required: 'CPF obrigatório.' })}
              control={control}
              type="cpf"
              name="cpf"
              label="CPF"
              onBlur={() => {}}
              error={!!errors.nome}
              autoComplete={'off'}
              sx={{ width: '200px' }}
            />

            <MaskedInput
              {...register('cnh', { required: 'CNH obrigatória.' })}
              control={control}
              type="none"
              name="cnh"
              label="CNH"
              onBlur={() => {}}
              error={!!errors.cnh}
              autoComplete={'off'}
              sx={{ width: '200px' }}
            />
          </Box>
        </Stack>
        <ButtonGroup>
          <button type="submit" name="SaveButton">
            <div className="text">Salvar</div>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>
        </ButtonGroup>
      </form>
    </Modal>
  );
}

export default ModalInsertMotoristas;
