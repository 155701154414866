/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useCallback, useRef, useState } from 'react';

import * as Yup from 'yup';

import { Link, useNavigate } from 'react-router-dom';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel1 from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {
  Menu,
  MenuItem,
  Avatar,
  Box,
  IconButton,
  Divider,
  Typography,
  ButtonBase,
} from '@mui/material';
import {
  Logout,
  LockOpen,
  MoreVert,
  Login,
  PictureAsPdfOutlined,
} from '@mui/icons-material';

import { FiUser, FiMail, FiMessageSquare, FiInfo } from 'react-icons/fi';
import {
  FaRegCopyright,
  FaLinkedin,
  FaFacebook,
  FaWheelchair,
  FaInstagram,
} from 'react-icons/fa';
import { ListItemIcon, Tooltip } from '@mui/material';
import { useToast } from '../../hooks/toast';

import Input from '../../components/Input';
import Button from '../../components/Button';
import TextAreaMessage from '../../components/TextArea';

import getValidationErrors from '../../utils/getValidationErrors';

import { Container, FormContainer, MaintenanceH1 } from './styles';

import bsa1 from '../../assets/bsa1.jpg';
import bsa2 from '../../assets/bsa2.jpeg';
import bsa3 from '../../assets/bsa3.jpeg';
import bsa4 from '../../assets/bsa4.jpeg';
import bsa5 from '../../assets/bsa5.jpg';
import bsa6 from '../../assets/bsa6.jpeg';
import bsa7 from '../../assets/bsa7.jpeg';
import bsa8 from '../../assets/bsa8.jpeg';
import bsa9 from '../../assets/bsa9.jpg';
import bsa10 from '../../assets/bsa10.jpg';
import logo200 from '../../assets/logo200.png';
import sugarcane from '../../assets/sugarcane.jpg';
import logobsawhite from '../../assets/logobsawhite.png';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import rel1 from '../../assets/docs/relatorio-transparencia-bsa.pdf';
import rel2 from '../../assets/docs/termo-de-responsabilidade-conduta-bsa.pdf';

interface Jobs {
  id: string;
  jobId: number;
  jobTitle: string;
  shift: string;
  education: string;
  contract: string;
  description: string;
  open: boolean;
}

interface ContactFormData {
  name: string;
  email: string;
  subject: string;
  message: string;
}

interface IDocumentProps {
  title: string;
  link: string;
  downloadName: string;
}

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
    partialVisibilityGutter: 40,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const menuOption = [
  'Home',
  'Trabalhe Conosco',
  'Contato',
  'Portal Transportadoras',
  'Admin',
];

const documents = [
  {
    title: 'Relatório Transparência BSA',
    link: rel1,
    downloadName: 'Relatório Transparência BSA.pdf',
  },
  {
    title: 'Termo de Responsabilidade e Conduta BSA',
    link: rel2,
    downloadName: 'Termo de Responsabilidade e Conduta BSA.rev.12112023.pdf',
  },
];

const ITEM_HEIGHT = 48;

function InitialPage(): ReactElement {
  const formRef = useRef<FormHandles>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [jobs, setJobs] = useState<Jobs[]>([]);
  const { addToast } = useToast();
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  // Menu for small screens
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    signOut();
    navigate('/');
  };

  // User Button
  const [anchorUserMenu, setAnchorUserMenu] = useState<null | HTMLElement>(
    null,
  );
  const openUserMenu = Boolean(anchorUserMenu);

  const handleClickUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorUserMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorUserMenu(null);
  };

  // Load job inside the jobsCarousel
  // useEffect(() => {
  //   async function loadJobs(): Promise<void> {
  //     await api.get('/jobs/bystatus/true').then(response => {
  //       setJobs(response.data);
  //     });
  //   }
  //   loadJobs();
  // }, [jobs]);

  // Method for handleling the email sent from the contact page form
  const handleSendEmail = useCallback(
    async (data: ContactFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Name is required'),
          email: Yup.string()
            .required('Email is required')
            .email('Please enter a valid email'),
          subject: Yup.string().required('Subject is required'),
          message: Yup.string().required('An message is required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/contact', {
          name: data.name,
          email: data.email,
          subject: data.subject,
          message: data.message,
        });

        addToast({
          type: 'success',
          title: 'Mensagem enviada.',
          description: 'Sua mensagem foi enviada com sucesso!',
        });

        formRef.current?.reset();
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
        addToast({
          type: 'error',
          title: 'Sending Error',
          description: `${err.response.data.message}`,
        });
      }
    },
    [addToast],
  );

  // Method for prevent wrong access to menu Transportadoras
  async function handleErrorAccessTransp(userinfo: any): Promise<void> {
    if (userinfo === undefined) {
      navigate('/login');
      return;
    }

    if (userinfo.transportadora === false) {
      addToast({
        type: 'error',
        title: 'Acesso Restrito.',
        description: 'Disponível apenas para transportadoras cadastradas.',
      });
    }

    if (userinfo.transportadora === true) {
      navigate('/portal');
    }
  }

  // Method for prevent wrong access to menu Admin
  async function handleErrorAccessAdmin(userinfo: any): Promise<void> {
    if (userinfo === undefined) {
      navigate('/login');
      return;
    }

    if (userinfo.transportadora === true) {
      addToast({
        type: 'error',
        title: 'Acesso Restrito.',
        description: 'Disponível apenas para Usuários Bom Sucesso.',
      });
    }

    if (userinfo.faturamento === true && userinfo.admin === false) {
      navigate('/painelfaturamento');
    }

    if (userinfo.rh === true && userinfo.admin === false) {
      navigate('/painelrh');
    }

    if (userinfo.logistica === true && userinfo.admin === false) {
      navigate('/painellogistica');
    }

    if (userinfo.laboratorio === true && userinfo.admin === false) {
      navigate('/painellaboratorio');
    }

    if (userinfo.admin === true) {
      navigate('/admin');
    }
  }

  const progressCircle = useRef<any>(null);
  const progressContent = useRef<any>(null);
  const [timeLeft, setTimeLeft] = useState('');
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current &&
      progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current &&
      progressContent.current.textContent === `${Math.ceil(time / 1000)}s`;
    setTimeLeft(`${Math.ceil(time / 1000)}s`);
  };

  const handleScrollToElement = (element: string) => {
    const elementToNavigate = document.getElementById(element);

    elementToNavigate?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <Container>
      <MaintenanceH1 className="maintenance">SITE EM MANUTENÇÃ0</MaintenanceH1>
      <div className="section" id="section1" data-anchor="sectionHome">
        <header>
          <h1>
            <img src={logobsawhite} alt="logo" />
            Bom Sucesso Agroindústria S.A
          </h1>

          <nav id="navnormal">
            <Link to="/">Home</Link>

            <button
              type="submit"
              onClick={() => handleScrollToElement('section3')}
            >
              Trabalhe Conosco
            </button>

            <button
              type="submit"
              onClick={() => handleScrollToElement('section4')}
            >
              Contato
            </button>

            <Button onClick={() => handleErrorAccessTransp(user)}>
              Portal Transportadoras
            </Button>

            {user && user.transportadora === false ? (
              <Button onClick={() => handleErrorAccessAdmin(user)}>
                Admin
              </Button>
            ) : (
              <Button hidden />
            )}

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                marginLeft: '10px',
              }}
            >
              <Tooltip title="Informações Usuário">
                <IconButton
                  onClick={handleClickUserMenu}
                  size="small"
                  sx={{ ml: 2 }}
                >
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      color: '#066abd',
                      background: '#fff',
                    }}
                  >
                    {/* If user is logged show the first letter of name, else null */}
                    {user ? user.name.charAt(0).toUpperCase() : null}
                  </Avatar>
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              id="userMenu"
              anchorEl={anchorUserMenu}
              open={openUserMenu}
              onClose={handleCloseUserMenu}
              onClick={handleCloseUserMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: '16px',
                    height: '16px',
                    alignItems: 'center',
                    display: 'flex',
                  },
                  '& .MuiTouchRipple-root': {
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'space-between',
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%), rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {user ? (
                <div>
                  <Typography
                    style={{
                      color: '#00a859',
                      textAlign: 'center',
                      marginBottom: '12px',
                      padding: '10px',
                    }}
                  >
                    {user.name}
                  </Typography>

                  <MenuItem>
                    <ListItemIcon
                      onClick={() =>
                        navigate({
                          pathname: '/resetpassword',
                          search: user.id,
                        })
                      }
                    >
                      <LockOpen
                        fontSize="small"
                        style={{
                          marginLeft: '-7px',
                          marginRight: '5px',
                        }}
                      />
                      Alterar Senha
                    </ListItemIcon>
                  </MenuItem>

                  <Divider />
                </div>
              ) : null}

              {user ? (
                <MenuItem onClick={handleLogOut}>
                  <ListItemIcon>
                    <Logout
                      fontSize="small"
                      style={{
                        marginLeft: '-5px',
                        marginRight: '5px',
                      }}
                    />
                    Logout
                  </ListItemIcon>
                </MenuItem>
              ) : (
                <MenuItem>
                  <ListItemIcon onClick={() => navigate('/login')}>
                    <Login
                      fontSize="small"
                      style={{
                        marginLeft: '-5px',
                        marginRight: '5px',
                      }}
                    />
                    Login
                  </ListItemIcon>
                </MenuItem>
              )}
            </Menu>
          </nav>
          <nav id="navsmallscreens">
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVert style={{ zIndex: -1 }} />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '20ch',
                },
              }}
            >
              {menuOption.map(option => (
                <MenuItem
                  key={option}
                  selected={option === 'choose'}
                  onMouseUp={handleClose}
                  onClick={() =>
                    option === 'Home'
                      ? handleScrollToElement('section1')
                      : option === 'Trabalhe Conosco'
                      ? handleScrollToElement('section3')
                      : option === 'Contato'
                      ? handleScrollToElement('section4')
                      : option === 'Portal Transportadoras'
                      ? navigate('/portal')
                      : option === 'Admin'
                      ? navigate('/admin')
                      : null
                  }
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </nav>
        </header>
      </div>
      <Swiper
        slidesPerView={1}
        centeredSlides={false}
        loop={true}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        direction="horizontal"
        spaceBetween={0}
        pagination={{
          type: 'fraction',
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="swiperInitialPage"
        onAutoplayTimeLeft={onAutoplayTimeLeft}
      >
        <SwiperSlide>
          <img src={bsa1} alt="bsa1" loading="lazy" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={bsa2} alt="bsa2" loading="lazy" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={bsa3} alt="bsa3" loading="lazy" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={bsa4} alt="bsa4" loading="lazy" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={bsa5} alt="bsa5" loading="lazy" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={bsa6} alt="bsa6" loading="lazy" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={bsa7} alt="bsa7" loading="lazy" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={bsa8} alt="bsa8" loading="lazy" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={bsa9} alt="bsa9" loading="lazy" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={bsa10} alt="bsa10" loading="lazy" />
        </SwiperSlide>
        <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}>{timeLeft}</span>
        </div>
      </Swiper>
      {/* SECTION 2 - ABOUT */}
      <div className="section" id="section2" data-anchor="sectionAbout">
        <div className="slide">
          <div className="menuHolder">
            <h2>Conheça mais sobre a nossa história.</h2>

            {/* <Link to="/about">Sobre Nós</Link> */}
            <Link to="/">Em Breve</Link>
          </div>
          <div className="imgHolder">
            <img src={sugarcane} alt="sugarcane" />
          </div>
        </div>
      </div>
      {/* END SECTION 2 */}
      {/* SECTION 3 - JOBS */}
      <div className="section" id="section3" data-anchor="sectionJobs">
        <div id="jobsHeader">
          <h1>Oportunidades</h1>
          <div className="separator" />
          <h3>Venha fazer parte desta equipe.</h3>
        </div>

        <div className="logoHolder">
          <img src={logo200} alt="LogoBsa" />
        </div>

        <div id="notificationContainer">
          <p id="jobsTitle">Confira nossas vagas em aberto</p>

          <p
            id="disableNotification"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <FaWheelchair
              size={20}
              color="#171412"
              style={{ marginRight: '5px' }}
            />
            Temos vagas para pessoas com defiência!
          </p>
        </div>

        <div className="wrapper">
          {/* In case of no jobs available, render a warning to the user */}
          {jobs.length === 0 ? (
            <h4 id="noJobs">Sem vagas disponíveis no momento.</h4>
          ) : (
            <Carousel1
              autoPlay
              responsive={responsive}
              infinite
              slidesToSlide={1}
              renderButtonGroupOutside
              additionalTransfrom={0}
              itemClass=""
              sliderClass=""
              partialVisible
              arrows={false}
            >
              {jobs.length >= 1 &&
                jobs.map(item => (
                  <div className="carouselItems" key={item.id}>
                    <span>{item.jobTitle}</span>
                    <h6>
                      <strong>Formação: </strong> {item.education}
                    </h6>
                    <h6>
                      <strong>Turno: </strong>
                      {item.shift}
                    </h6>

                    <h6 id="beneficios">
                      <strong>Benefícios em Grupo</strong>
                    </h6>

                    <Link
                      id="link"
                      to="/jobs"
                      state={item.id}
                      placeholder="Informações"
                    >
                      Informações
                    </Link>
                  </div>
                ))}
            </Carousel1>
          )}
        </div>
      </div>
      {/* END SECTION 3 */}
      {/* SECTION 4 - CONTACT */}
      <div className="section" id="section4" data-anchor="sectionHome">
        <div id="contactHeader">
          <h1>Contato</h1>
          <div className="separator" />
        </div>
        <div
          id="containerWrapper"
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <div id="container">
            {/* Contact Cards - Left Side */}
            <div id="contactsContainer">
              <h2>Departamentos</h2>

              <div className="card">
                <div className="cardItems">
                  <p id="sector">
                    <b>Financeiro</b>
                  </p>
                  <p id="phone">(64) 3051-5090</p>
                  <a href="mailto:financeiro@bsabioenergia.com.br">
                    financeiro@bsabioenergia.com.br
                  </a>
                </div>
              </div>

              <div className="card">
                <div className="cardItems">
                  <p id="sector">
                    <b>Compras</b>
                  </p>
                  <p id="phone">(64) 3051-5090</p>
                  <a href="mailto:compras1@bsabioenergia.com.br">
                    compras1@bsabioenergia.com.br
                  </a>
                </div>
              </div>

              <div className="card">
                <div className="cardItems">
                  <p id="sector">
                    <b>Recursos Humanos</b>
                  </p>
                  <p id="phone">(64) 3051-5090</p>
                  <a href="mailto:rh@bsabioenergia.com.br">
                    rh@bsabioenergia.com.br
                  </a>
                </div>
              </div>

              <div className="card">
                <div className="cardItems">
                  <p id="sector">
                    <b>Logística</b>
                  </p>
                  <p id="phone">(64) 3051-5090</p>
                  <a href="mailto:logistica@bsabioenergia.com.br">
                    logistica@bsabioenergia.com.br
                  </a>
                </div>
              </div>
            </div>

            {/* Holder for mail contact form */}
            <div id="formContainer">
              <h2>Envie uma mensagem para nossa equipe</h2>

              <FormContainer>
                <Form ref={formRef} onSubmit={handleSendEmail} id="form">
                  <Input name="name" icon={FiUser} placeholder="Nome" />
                  <Input name="email" icon={FiMail} placeholder="Email" />
                  <Input
                    name="subject"
                    icon={FiMessageSquare}
                    placeholder="Assunto"
                  />

                  <TextAreaMessage
                    name="message"
                    icon={FiInfo}
                    placeholder="Mensagem"
                    cols={30}
                    rows={10}
                  />

                  <Button type="submit">Enviar</Button>
                </Form>
              </FormContainer>
            </div>
          </div>
          {/* Holder for downloads section */}
          <div className="downloadsHolder">
            <div
              id="contactHeader"
              style={{ marginTop: '2rem', marginBottom: '2rem' }}
            >
              <h1>Downloads</h1>
              <div className="separator" />
            </div>

            <div className="documentsHolder">
              {documents.map(document => (
                <ButtonBase
                  key={document.title}
                  href={document.link}
                  download={document.downloadName}
                  sx={{
                    display: 'flex',
                    width: '170px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      top: '0',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <PictureAsPdfOutlined
                      sx={{ width: '5rem', height: '5rem', opacity: '0.7' }}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: '100%',
                      mt: '1rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '5rem',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#00a859',
                        textAlign: 'center',
                        fontSize: '1rem',
                        fontWeight: '500',
                      }}
                    >
                      {document.title}
                    </Typography>
                  </Box>
                </ButtonBase>
              ))}
            </div>
          </div>

          {/* Holder for GoogleMaps container */}
          <div className="mapHolder">
            {/* <h2>Localização</h2> */}
            <iframe
              title="Bom Sucesso Agroindustria S.A"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d144423.4163231557!2d-49.83021160432529!3d-17.971272549594243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94a01827534fce5f%3A0x670d5e6767e44029!2sUsina%20Bom%20Sucesso!5e0!3m2!1spt-BR!2sbr!4v1616267692003!5m2!1spt-BR!2sbr"
              allowFullScreen
              loading="lazy"
            />
          </div>
        </div>

        {/* FOOTER Contact section */}
        <div id="footerHolder">
          <div className="footerMenuHolder">
            <button
              type="submit"
              onClick={() => handleScrollToElement('section1')}
            >
              Inicio
            </button>

            <button
              type="submit"
              onClick={() => handleScrollToElement('section2')}
            >
              Sobre
            </button>
            <button
              type="submit"
              onClick={() => handleScrollToElement('section3')}
            >
              Trabalhe Conosco
            </button>
            <button
              type="submit"
              onClick={() => handleScrollToElement('section4')}
            >
              Portal Transportadoras
            </button>
          </div>

          <div className="footerMediaHolder">
            <div id="wrapper">
              <p>Siga-nos nas redes sociais</p>

              <div id="socialMediaContainer">
                <FaFacebook
                  size={38}
                  onClick={() =>
                    window.open(
                      'https://m.facebook.com/bomsucessoagroindustria-103683245332130/?ref=content_filter',
                      '_blank',
                    )
                  }
                />
                <FaInstagram
                  size={38}
                  onClick={() =>
                    window.open(
                      'https://www.instagram.com/bomsucessoagroindustria/',
                      '_blank',
                    )
                  }
                />
                <FaLinkedin
                  size={38}
                  onClick={() =>
                    window.open(
                      'https://br.linkedin.com/in/bom-sucesso-agroind%C3%BAstria-a26614180',
                      '_blank',
                    )
                  }
                />
              </div>

              <div id="copyrightContainer">
                <p>
                  Copyright <FaRegCopyright size={11} /> 2021 - Bom Sucesso
                  Agroindústria S.A
                </p>
              </div>
            </div>
          </div>

          <div className="footerCompanyInfoHolder">
            <div className="companyWrapper">
              <div id="imageContainer">
                <img src={logobsawhite} alt="logobsa" />
              </div>

              <div id="infoContainer">
                <p>Bom Sucesso Agroindústria S.A</p>
                <p>Rod. GO-210 KM 335,1 S/N</p>
                <p>Zona Rural - Goiatuba/GO</p>
                <p>75.600-000 - Caixa Postal: 127</p>
              </div>
            </div>
          </div>
          <p id="developedBy">Developed by GBorges</p>
        </div>
      </div>
      {/* END SECTION 4 */}
    </Container>
  );
}

export default InitialPage;
