/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { useTransition } from '@react-spring/web';

import Toast from './Toast';

import { ToastMessage } from '../../hooks/toast';
import { Container } from './styles';

interface ToastContainerProps {
  messages: ToastMessage[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ToastContainer({ messages }: ToastContainerProps): ReactElement {
  // const messagesWithTransitions = useTransition(messages, message => message.id  {
  //   from: { right: '-120%' },
  //   enter: { right: '0%' },
  //   leave: { right: '-120%' },
  // });

  const messagesWithTransitions = useTransition(messages, {
    from: { right: '-120%' },
    enter: { right: '0%' },
    leave: { right: '-120%' },
  });

  return (
    <Container>
      {messagesWithTransitions((style, item) => (
        <Toast key={item.id} style={style.right} message={item} />
      ))}

      {/* {messagesWithTransitions.map((toast: any) => (
        <Toast key={toast.key} style={toast.props} message={toast.item} />
      ))} */}
    </Container>
  );
}

export default ToastContainer;
