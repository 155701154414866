/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState } from 'react';

import { FiCheckSquare } from 'react-icons/fi';

import { ButtonGroup } from '../../styles/customButtonGroup';

import Modal from '../Modal';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import { cnpjJustNumbers } from '../../utils/cnpjUtils';
import { cpfJustNumbers } from '../../utils/cpfUtils';

import { SubmitHandler, useForm } from 'react-hook-form';
import { MaskedInput } from '../Material/MaskedInput';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

interface IFormData {
  codtransportadora: number | undefined;
  cnpj: string;
  nome: string;
  fantasia: string;
  email: string;
}

function ModalInsertTransportadora({
  isOpen,
  setIsOpen,
}: IModalProps): ReactElement {
  const { addToast } = useToast();
  const [codTransportadoraIntegration, setCodTransportadoraIntegration] =
    useState('');
  const [tipo, setTipo] = useState('cnpj');

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    resetField,
    formState: { errors },
  } = useForm<IFormData>({
    defaultValues: {
      codtransportadora: undefined,
      cnpj: '',
      nome: '',
      fantasia: '',
      email: '',
    },
  });

  async function handleTransportadora(value: string): Promise<void> {
    const onlyNumbersValue =
      tipo === 'cnpj' ? cnpjJustNumbers(value) : cpfJustNumbers(value);

    if (tipo === 'cnpj' && (!value || onlyNumbersValue.length < 14)) {
      return;
    }

    if (tipo === 'cpf' && (!value || onlyNumbersValue.length < 11)) {
      return;
    }

    const formattedCnpj = cnpjJustNumbers(value);

    const foundCodIntegration = await api
      .get('/transportadoras/transportadora-sap', {
        params: {
          cnpj: formattedCnpj,
          tipo: tipo,
        },
      })
      .then(response => {
        if (response.data.error && response.data.error.statusCode === 400) {
          // Show Toast for failure
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar transportadora.',
            description: `${response.data.error.message}`,
          });
        }

        return response.data;
      });

    setValue('codtransportadora', foundCodIntegration.data.businessPartner, {
      shouldTouch: true,
    });
    setCodTransportadoraIntegration(foundCodIntegration.data.businessPartner);
  }

  const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
    try {
      await api.post('/transportadoras', {
        codtransportadora: data.codtransportadora,
        cnpj: data.cnpj,
        nome: data.nome.toUpperCase(),
        fantasia: data.fantasia.toUpperCase(),
        email: data.email,
      });

      // Close the modal
      setIsOpen();

      // Show Toast for success
      addToast({
        type: 'success',
        title: 'Transportadora Cadastrada',
        description: 'Seu registro foi salvo com sucesso!',
      });

      // reset form
      reset();
    } catch (err: any) {
      // Close the modal
      setIsOpen();

      // Show Toast for failure
      addToast({
        type: 'error',
        title: 'Erro ao cadastrar transportadora.',
        description: `${err.response.data.message}`,
      });

      // reset form
      reset();
    }
  };

  const radioStyle = {
    '&.Mui-checked': {
      color: '#00a859',
    },
    '&.Mui-checked + .MuiFormControlLabel-label': {
      color: '#00a859',
    },
  };

  const handleChangeTipo = (value: string) => {
    setTipo(value);
    resetField('codtransportadora', {
      defaultValue: undefined,
    });
    setCodTransportadoraIntegration('');
    // reset form
    reset();
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
          gap: '1rem',
        }}
      >
        <h1>Cadastro Transportadora</h1>

        <FormControl>
          <FormLabel
            id="useRadioGroup"
            sx={{
              textAlign: 'center',
              color: '#00a859',
              fontSize: '1rem',
              fontWeight: 'bold',
            }}
          >
            Tipo
          </FormLabel>
          <RadioGroup row name="useRadioGroup" defaultValue="cnpj">
            <FormControlLabel
              value="cnpj"
              defaultChecked
              control={<Radio sx={radioStyle} />}
              label="CNPJ"
              onChange={() => handleChangeTipo('cnpj')}
            />
            <FormControlLabel
              value="cpf"
              control={<Radio sx={radioStyle} />}
              label="CPF"
              onChange={() => handleChangeTipo('cpf')}
            />
          </RadioGroup>
        </FormControl>

        {tipo === 'cnpj' ? (
          <MaskedInput
            {...register('cnpj', { required: 'CNPJ obrigatório.' })}
            control={control}
            type="cnpj"
            name="cnpj"
            label="CNPJ"
            onBlur={e => handleTransportadora(e.currentTarget.value)}
            error={!!errors.cnpj}
            autoComplete="off"
          />
        ) : (
          <MaskedInput
            {...register('cnpj', { required: 'CNPJ/CPF obrigatório.' })}
            control={control}
            type="cpf"
            name="cnpj"
            label="CPF"
            onBlur={e => handleTransportadora(e.currentTarget.value)}
            error={!!errors.cnpj}
            autoComplete="off"
          />
        )}

        <MaskedInput
          {...register('codtransportadora', {
            required: 'Código transportadora obrigatório.',
          })}
          control={control}
          type="none"
          name="codtransportadora"
          label="Código Transportadora"
          onBlur={() => {}}
          error={!!errors.codtransportadora}
          autoComplete="off"
          disabled={codTransportadoraIntegration === ''}
          shouldShrink={codTransportadoraIntegration === '' ? false : true}
        />

        <MaskedInput
          {...register('nome', { required: 'Nome obrigatório.' })}
          control={control}
          type="none"
          name="nome"
          label="Nome Transportadora"
          onBlur={() => {}}
          error={!!errors.nome}
          autoComplete="off"
        />

        <MaskedInput
          {...register('fantasia', { required: 'Nome fantasia obrigatório.' })}
          control={control}
          type="none"
          name="fantasia"
          label="Nome Fantasia"
          onBlur={() => {}}
          error={!!errors.fantasia}
          autoComplete="off"
        />

        <MaskedInput
          {...register('email', { required: 'Email obrigatório.' })}
          control={control}
          type="none"
          name="email"
          label="Email"
          onBlur={() => {}}
          error={!!errors.email}
          autoComplete="off"
        />

        <ButtonGroup>
          <button type="submit" name="SaveButton">
            <div className="text">Salvar</div>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>
        </ButtonGroup>
      </form>
    </Modal>
  );
}

export default ModalInsertTransportadora;
