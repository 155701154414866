/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unused-prop-types */
import React, { ReactElement } from 'react';

import { Container } from './styles';

interface TooltipProps {
  title: string;
  className?: string;
  error?: any;
  children: any;
}

function Tooltip(
  { title, className }: TooltipProps,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  children: ReactElement,
): ReactElement {
  return (
    <Container className={className}>
      {children}
      <span>{title}</span>
    </Container>
  );
}

export default Tooltip;

// Tooltip.defaultProps = {
//   className: '',
//   error: '',
// };
