/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { format, parseISO } from 'date-fns';
import isAfter from 'date-fns/isAfter';
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  ReactElement,
} from 'react';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';

interface User {
  id: string;
  name: string;
  login: string;
  avatarUrl: string;
  transportadora: boolean;
  admin: boolean;
  rh: boolean;
  logistica: boolean;
  faturamento: boolean;
  laboratorio: boolean;
  portaria: boolean;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  login: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

function AuthProvider({ children }): ReactElement {
  const navigate = useNavigate();

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Bsa:token');
    const refreshToken = localStorage.getItem('@Bsa:refreshToken');
    const user = localStorage.getItem('@Bsa:user');

    if (refreshToken && user && token) {
      // For auto handling token to be used in routes that is token is required
      // After login and also after refreshing the page
      // api.defaults.headers.authorization = `Bearer ${token}`;
      async (): Promise<any> => {
        const isTokenExpired = await api.get(`/sessions/refreshToken`, {
          params: {
            refreshToken,
          },
        });

        const formatExpiryDate = parseISO(isTokenExpired.data[0].expirydate);
        const formattedExpiryDate = parseISO(
          format(formatExpiryDate, 'yyyy-MM-dd HH:mm:ss'),
        );

        if (isAfter(Date.now(), formattedExpiryDate)) {
          localStorage.removeItem('@Bsa:token');
          localStorage.removeItem('@Bsa:user');
          localStorage.removeItem('@Bsa:refreshToken');
          signOut();
        }
      };

      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ login, password }) => {
    // If user type Carrier then check if both user and carrier register was made
    const foundUserType = await api.get(`/users/login`, {
      params: {
        login,
      },
    });

    if (foundUserType.data.transportadora === true) {
      try {
        // Find if carrier register exists
        const foundTransportadora = await api.get(`/transportadoras/cnpj/`, {
          params: {
            cnpj: login,
          },
        });

        if (foundTransportadora.data.length === 0) {
          throw new Error(
            'Cadastro tipo transportadora não encontrado. Entrar em contato com o Departamento de T.I/Logística da Bom Sucesso.',
          );
        }
      } catch (error) {
        throw new Error(
          'Cadastro tipo transportadora não encontrado. Entrar em contato com o Departamento de T.I/Logística da Bom Sucesso.',
        );
      }
    }

    const response = await api.post('sessions', {
      login,
      password,
    });

    const { token, user, refreshToken } = response.data;

    localStorage.setItem('@Bsa:token', token);
    localStorage.setItem('@Bsa:refreshToken', refreshToken);
    localStorage.setItem('@Bsa:user', JSON.stringify(user));

    // For auto handling token to be used in routes that is token is required
    // After login and also after refreshing the page
    // api.defaults.headers.authorization = `Bearer ${token}`;
    api.defaults.headers.authorization = `Bearer ${token}`;
    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Bsa:token');
    localStorage.removeItem('@Bsa:user');
    localStorage.removeItem('@Bsa:refreshToken');

    setData({} as AuthState);
    navigate('/login');
    // window.location.reload();
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem('@Bsa:user', JSON.stringify(user));

      setData({
        token: data.token,
        user: {
          id: data.user.id,
          name: data.user.name,
          login: data.user.login,
          admin: data.user.admin,
          transportadora: data.user.transportadora,
          faturamento: data.user.faturamento,
          logistica: data.user.logistica,
          rh: data.user.rh,
          laboratorio: data.user.laboratorio,
          portaria: data.user.portaria,
          avatarUrl: data.user.avatarUrl,
        },
      });
    },
    [data.token, data.user],
  );

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        user: data.user,
        signIn,
        signOut,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
