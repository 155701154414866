/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FiEdit, FiTrash2 } from 'react-icons/fi';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Container, TableContainer } from './styles';
import { useAuth } from '../../hooks/auth';

import ModalEditVeiculos from '../../components/ModalEditVeiculos';

import api from '../../services/api';

interface IVeiculosData {
  veiculoid: number;
  tipoveiculo: number;
  tipocarreta: string;
  placa: string;
  uf: string;
  compartimentos: number;
  capacidadetotal: number;
}

interface IEditVeiculosData {
  veiculoid: number;
  tipoveiculo: number;
  tipocarreta: string;
  placa: string;
  uf: string;
  compartimentos: number;
  capacidadetotal: number;
  compart: [
    {
      veiculoid: number;
      compartimento: number;
      volume: number;
      seta1: number;
      seta2: number;
      seta3: number;
      seta4: number;
      seta5: number;
    },
  ];
}

interface IDeleteVeiculo {
  veiculoid: number;
  placa: string;
}

function ManutencaoVeiculos(): ReactElement {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const [reloadVeiculosData, setReloadVeiculosData] = useState(false);

  const [veiculos, setVeiculos] = React.useState<IVeiculosData[]>([]);

  // MODAL States
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingVeiculos, setEditingVeiculos] = useState<IEditVeiculosData>(
    {} as IEditVeiculosData,
  );

  const [veiculoToDelete, setVeiculoToDelete] = React.useState<IDeleteVeiculo>(
    {} as IDeleteVeiculo,
  );

  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
  const [openCantDeleteVeiculo, setOpenCantDeleteVeiculo] = useState(false);

  const screenHeight = `${window.innerHeight - 150}px `;
  const screenWidth = `${window.innerWidth}px`;

  // Toogle modal edit
  function toggleEditModal(): void {
    setEditModalOpen(!editModalOpen);

    // Reload veiculos data after modal close
    if (!editModalOpen === false) {
      setReloadVeiculosData(true);
    }
  }

  // Load Veiculos Information
  useEffect(() => {
    async function loadVeiculos(): Promise<void> {
      await api.get(`/veiculos`).then(response => {
        setVeiculos(response.data);
      });
    }
    loadVeiculos();

    if (reloadVeiculosData) {
      loadVeiculos();
      setReloadVeiculosData(false);
    }
  }, [reloadVeiculosData]);

  // Handle the sending data to edit registries
  async function handleEditVeiculos(
    veiculosData: IVeiculosData,
  ): Promise<void> {
    let formmattedEditingData;

    try {
      const result1 = await api.get(
        `/compartimentos/${veiculosData.veiculoid}`,
      );

      formmattedEditingData = {
        ...veiculosData,
        compart: result1.data,
      };
    } catch (error) {
      formmattedEditingData = {
        ...veiculosData,
      };
    }

    setEditingVeiculos(formmattedEditingData);
    toggleEditModal();
  }

  async function handleConfirmationDeleteVeiculos(
    veiculoid: number,
    placa: string,
  ): Promise<void> {
    const foundVeiculo = await api.get(`/veiculos/byveiculoid/${veiculoid}`);

    if (!foundVeiculo) {
      throw new Error('Veículo Id Não encontrado');
    }

    const foundAgendamentos = await api.get(`/agendamentos/byplaca/${placa}`);

    // IN CASE OF SCHEDULE FOUND THEN CONFIRM DELETE BY DIALOG
    if (foundAgendamentos.data.length > 0) {
      setVeiculoToDelete({ veiculoid, placa });
      setOpenCantDeleteVeiculo(true);
    }

    // DIRECT DELETE IN CASE NO SCHEDULE FOUND
    if (foundAgendamentos.data.length === 0) {
      setVeiculoToDelete({ veiculoid, placa });
      setOpenConfirmDelete(true); // OPEN DIALOG CONFIRMATION
    }
  }

  const handleConfirmDeleteClose = () => {
    setOpenConfirmDelete(false);
  };

  const handleCantDeleteVeiculoClose = () => {
    setOpenCantDeleteVeiculo(false);
  };

  async function handleDeleteVeiculo(veiculoid: number) {
    setOpenConfirmDelete(false);
    await api.delete(`/veiculos/${veiculoid}`);
    window.location.reload();
  }

  return (
    <Container>
      <ModalEditVeiculos
        isOpen={editModalOpen}
        setIsOpen={toggleEditModal}
        editingVeiculos={editingVeiculos}
      />

      {/* CONFIRM DELETE VEICULO */}
      <Dialog
        open={openConfirmDelete}
        onClose={handleConfirmDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirmar Exclusão do Veículo?
        </DialogTitle>{' '}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja confirmar a exclusão do Veículo{' '}
            <strong style={{ color: 'red' }}>
              {veiculoToDelete.veiculoid} - {veiculoToDelete.placa}
            </strong>{' '}
            ? Após a exclusão deste cadastro não será possível realizar
            agendamentos/carregamentos para este veículo.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteClose}>Cancelar</Button>
          <Button
            style={{ color: 'red', fontWeight: '600' }}
            onClick={() => handleDeleteVeiculo(veiculoToDelete.veiculoid)}
            autoFocus
          >
            Deletar
          </Button>
        </DialogActions>
      </Dialog>

      {/* DIALOG CANT DELETE MOTORISTA */}
      <Dialog
        open={openCantDeleteVeiculo}
        onClose={handleCantDeleteVeiculoClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <strong style={{ color: 'red' }}>
            {' '}
            Impossível Excluir Veículo !
          </strong>
        </DialogTitle>{' '}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Encontramos agendamentos realizados para o veículo{' '}
            <strong style={{ color: 'black' }}>
              {' '}
              {veiculoToDelete.veiculoid} - {veiculoToDelete.placa}
            </strong>
            , a exclusão deste cadastro poderá gerar problemas em registros
            futuros. Sendo assim{' '}
            <strong style={{ color: 'red' }}>não permitiremos</strong> a
            exclusão deste cadastro.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCantDeleteVeiculoClose}>Cancelar</Button>
        </DialogActions>
      </Dialog>

      <header>
        <h1>Bom Sucesso Agroindústria S.A</h1>

        <nav>
          <button type="submit" onClick={() => navigate('/')}>
            Página Inicial
          </button>

          <button type="submit" onClick={() => navigate('/paineladmin')}>
            Voltar
          </button>

          <button type="submit" onClick={signOut}>
            Logout
          </button>
        </nav>
      </header>
      <h2 id="pageTitle">Manutenção Veículos</h2>

      {/* Table */}
      <TableContainer
        style={{ maxHeight: screenHeight, maxWidth: screenWidth }}
      >
        <table className="table table-hover table-borderless">
          <thead>
            <tr>
              <th>Veiculo Id</th>
              <th>Tipo Veiculos</th>
              <th>Tipo Carreta</th>
              <th>Placa</th>
              <th>UF</th>
              <th>Compartimentos</th>
              <th>Capacidade Total</th>
              <th>Ações</th>
            </tr>
          </thead>

          {veiculos.map(veiculosData => (
            <tbody key={veiculosData.veiculoid}>
              <tr>
                <td>{veiculosData.veiculoid}</td>
                <td>{veiculosData.tipoveiculo}</td>
                <td>{veiculosData.tipocarreta}</td>
                <td>{veiculosData.placa}</td>
                <td>{veiculosData.uf}</td>
                <td>{veiculosData.compartimentos}</td>
                <td>{veiculosData.capacidadetotal}</td>

                <td className="actionButtons">
                  {/* Edit registry button */}
                  <button
                    type="button"
                    onClick={() => handleEditVeiculos(veiculosData)}
                  >
                    <FiEdit size={20} color="#08a818" />{' '}
                  </button>

                  {/* Delete veículos button */}
                  <button
                    type="button"
                    onClick={() =>
                      handleConfirmationDeleteVeiculos(
                        veiculosData.veiculoid,
                        veiculosData.placa,
                      )
                    }
                  >
                    <FiTrash2 size={20} color="#c2180c" />{' '}
                  </button>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </TableContainer>
    </Container>
  );
}

export default ManutencaoVeiculos;
