/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, ReactElement } from 'react';

import { FiCheckSquare, FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import { useToast } from '../../hooks/toast';

import { ButtonGroup } from '../../styles/customButtonGroup';
import Modal from '../Modal';

import api from '../../services/api';
import { RiCloseCircleLine } from 'react-icons/ri';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MaskedInput } from '../Material/MaskedInput';
import { Grid } from '@mui/material';

interface ICompartData {
  veiculoid: number;
  compartimento: number;
  volume: number;
  seta1: number;
  seta2: number;
  seta3: number;
  seta4: number;
  seta5: number;
}

interface IEditVeiculosData {
  veiculoid: number;
  tipoveiculo: number;
  tipocarreta: string;
  placa: string;
  uf: string;
  compartimentos: number;
  capacidadetotal: number;
  compart: [
    {
      veiculoid: number;
      compartimento: number;
      volume: number;
      seta1: number;
      seta2: number;
      seta3: number;
      seta4: number;
      seta5: number;
    },
  ];
}

interface IFormData {
  veiculoid: number;
  tipoveiculo: number;
  tipocarreta: string;
  placa: string;
  uf: string;
  compartimentos: number;
  capacidadetotal: number;
  compart: [
    {
      veiculoid: number;
      compartimento: number;
      volume: number;
      seta1: number;
      seta2: number;
      seta3: number;
      seta4: number;
      seta5: number;
    },
  ];
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  editingVeiculos: IEditVeiculosData;
}

function ModalEditVeiculos({
  isOpen,
  setIsOpen,
  editingVeiculos,
}: IModalProps): ReactElement {
  const { addToast } = useToast();
  const [compartData, setCompartData] = useState<ICompartData[]>([]);
  const [inputCompartimentos, setInputCompartimentos] = useState([0]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormData>({
    values: {
      ...editingVeiculos,
    },
  });

  const closeModal = () => {
    window.location.reload();
  };

  const compartTableStyles = {
    width: '100px',
  };

  const formMaskedInputStyles = {
    width: '245px',
  };

  const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
    try {
      if (!data) {
        throw new Error('Dados do veículo não encontrados');
      }

      const totalCapacidade = Number(data.capacidadetotal);

      if (data.tipoveiculo === 1 || data.tipoveiculo === 3) {
        // update veículo CAVALO
        await api.put(`/veiculos/${Number(data.veiculoid)}`, {
          tipoveiculo: Number(data.tipoveiculo),
          tipocarreta: data.tipocarreta,
          placa: data.placa,
          uf: data.uf,
          compartimentos: 0,
          capacidadetotal: 0,
        });
      }

      if (data.tipoveiculo === 2 || data.tipoveiculo === 4) {
        // update veículo
        await api.put(`/veiculos/${Number(data.veiculoid)}`, {
          tipoveiculo: Number(data.tipoveiculo),
          tipocarreta: data.tipocarreta,
          placa: data.placa,
          uf: data.uf,
          compartimentos: data.compartimentos,
          capacidadetotal: totalCapacidade,
        });
      }

      // eslint-disable-next-line no-console
      if (
        data.compart &&
        data.compart.length > 0 &&
        (data.tipoveiculo === 2 || data.tipoveiculo === 4)
      ) {
        // Delete compartimentos before updating
        await api.delete(
          `/compartimentos/${Number(editingVeiculos.veiculoid)}`,
        );

        data.compart.map(async compartimento => {
          // update compartimentos
          await api.put(
            `/compartimentos/${Number(editingVeiculos.veiculoid)}`,
            {
              compartimento: compartimento.compartimento,
              volume: compartimento.volume,
              seta1: compartimento.seta1,
              seta2: !compartimento.seta2 ? 0 : compartimento.seta2,
              seta3: !compartimento.seta3 ? 0 : compartimento.seta3,
              seta4: !compartimento.seta4 ? 0 : compartimento.seta4,
              seta5: !compartimento.seta5 ? 0 : compartimento.seta5,
            },
          );
        });
      }

      setIsOpen();

      // reset form
      reset();

      // Show Toast for success
      addToast({
        type: 'success',
        title: 'Veiculo Atualizado',
        description: `O registro foi atualizado com sucesso!`,
      });
    } catch (err: any) {
      addToast({
        type: 'error',
        title: 'Erro update Veiculos',
        description: err.response.data.message,
      });

      // reset form
      reset();
    }
  };

  // Load Compartimentos Information
  useEffect(() => {
    async function loadCompartimentos(): Promise<void> {
      if (
        editingVeiculos &&
        (editingVeiculos.tipoveiculo === 2 || editingVeiculos.tipoveiculo === 4)
      ) {
        await api
          .get(`/compartimentos/${editingVeiculos.veiculoid}`)
          .then(response => {
            setCompartData(response.data);
            setInputCompartimentos(response.data.length);
          });
      }
    }
    if (
      editingVeiculos.tipoveiculo === 2 ||
      editingVeiculos.tipoveiculo === 4
    ) {
      loadCompartimentos();
    }
  }, [editingVeiculos]);

  async function handleAddCompart(compartsNumber: number): Promise<void> {
    if (Number(inputCompartimentos) === 8) {
      return;
    }
    const newCompartNumber = compartsNumber;

    // Set new compart number
    setValue(
      `compart[${newCompartNumber - 1}].compartimento` as keyof IFormData,
      newCompartNumber,
    );
    // Set new compart volume
    setValue(`compart[${newCompartNumber - 1}].volume` as keyof IFormData, 0);
    // Set new compart seta1
    setValue(`compart[${newCompartNumber - 1}].seta1` as keyof IFormData, 0);
    // Set new compart seta2
    setValue(`compart[${newCompartNumber - 1}].seta2` as keyof IFormData, 0);
    // Set new compart seta3
    setValue(`compart[${newCompartNumber - 1}].seta3` as keyof IFormData, 0);
    // Set new compart seta4
    setValue(`compart[${newCompartNumber - 1}].seta4` as keyof IFormData, 0);
    // Set new compart seta5
    setValue(`compart[${newCompartNumber - 1}].seta5` as keyof IFormData, 0);

    if (newCompartNumber > Number(inputCompartimentos)) {
      setCompartData([
        ...compartData,
        {
          veiculoid: editingVeiculos.veiculoid,
          compartimento: compartsNumber,
          volume: 0,
          seta1: 0,
          seta2: 0,
          seta3: 0,
          seta4: 0,
          seta5: 0,
        },
      ]);
    }

    if (newCompartNumber < Number(inputCompartimentos)) {
      compartData.pop();
    }

    setInputCompartimentos([newCompartNumber]);
  }

  async function handleRemoveCompart(compartsNumber: number): Promise<void> {
    if (Number(inputCompartimentos) === 1) {
      return;
    }

    const newCompartNumber = compartsNumber;

    if (newCompartNumber < Number(inputCompartimentos)) {
      compartData.pop();
    }

    setInputCompartimentos([newCompartNumber]);
  }

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size={'840px'}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
          gap: '1rem',
        }}
      >
        <h1>Editar Veiculos</h1>

        <Grid
          container
          direction="row"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          columns={4}
          columnSpacing={1}
          rowSpacing={2}
          maxWidth="760px"
          width="100%"
          mt={1}
        >
          <Grid item>
            <MaskedInput
              {...register('veiculoid', {
                required: 'Veiculo ID obrigatório.',
              })}
              control={control}
              type="numbers"
              name="veiculoid"
              label="Veiculo ID"
              onBlur={() => {}}
              error={!!errors.veiculoid}
              autoComplete="off"
              sx={formMaskedInputStyles}
            />
          </Grid>

          <Grid item>
            <MaskedInput
              {...register('tipoveiculo', {
                required: 'Tipo veículo obrigatório.',
              })}
              control={control}
              type="none"
              name="tipoveiculo"
              label="Tipo Veículo"
              onBlur={() => {}}
              error={!!errors.tipoveiculo}
              autoComplete="off"
              sx={formMaskedInputStyles}
            />
          </Grid>

          <Grid item>
            <MaskedInput
              {...register('tipocarreta', {
                required: 'Tipo carreta obrigatório.',
              })}
              control={control}
              type="none"
              name="tipocarreta"
              label="Tipo Carreta"
              onBlur={() => {}}
              error={!!errors.tipocarreta}
              autoComplete="off"
              sx={formMaskedInputStyles}
            />
          </Grid>
          <Grid item>
            <MaskedInput
              {...register('placa', {
                required: 'Placa obrigatória.',
              })}
              control={control}
              type="placa"
              name="placa"
              label="Placa"
              onBlur={() => {}}
              error={!!errors.placa}
              autoComplete="off"
              sx={formMaskedInputStyles}
            />
          </Grid>
          <Grid item>
            <MaskedInput
              {...register('uf', {
                required: 'UF obrigatória.',
              })}
              control={control}
              type="none"
              name="uf"
              label="UF"
              onBlur={() => {}}
              error={!!errors.uf}
              autoComplete="off"
              sx={formMaskedInputStyles}
            />
          </Grid>
          {(editingVeiculos.tipoveiculo === 2 ||
            editingVeiculos.tipoveiculo === 4) && (
            <>
              <Grid item>
                <MaskedInput
                  {...register('capacidadetotal', {
                    required: 'Capacidade Total obrigatória.',
                  })}
                  control={control}
                  type="numbers"
                  name="capacidadetotal"
                  label="Capacidade Total"
                  onBlur={() => {}}
                  error={!!errors.capacidadetotal}
                  autoComplete="off"
                  sx={formMaskedInputStyles}
                />
              </Grid>

              <Grid item>
                <MaskedInput
                  {...register('compartimentos', {
                    required: 'Compartimentos obrigatório.',
                  })}
                  control={control}
                  type="numbers"
                  name="compartimentos"
                  label="Compartimentos"
                  onBlur={() => {}}
                  error={!!errors.compartimentos}
                  autoComplete="off"
                  sx={formMaskedInputStyles}
                  inputProps={{ min: 0, max: 8 }}
                />
              </Grid>
              <Grid item sx={formMaskedInputStyles}>
                {/* Button to Add Compartimento */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: '0.5rem',
                  }}
                >
                  <button
                    type="button"
                    name="AddCompart"
                    id="addCompart"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: '#00a859',
                      color: '#fff',
                      borderColor: '#00a859',
                      width: '40px',
                      height: '40px',
                      borderRadius: '10px',
                      outline: 'none',
                    }}
                    onClick={() =>
                      Number(inputCompartimentos) <= 8
                        ? handleAddCompart(Number(inputCompartimentos) + 1)
                        : null
                    }
                  >
                    <FiPlusCircle size={22} />
                  </button>
                  {/* Button to Remove Compartimento */}
                  <button
                    type="button"
                    name="RemoveCompart"
                    id="removeCompart"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: '#bf1319',
                      color: '#fff',
                      borderColor: '#bf1319',
                      width: '40px',
                      height: '40px',
                      borderRadius: '10px',
                      outline: 'none',
                    }}
                    onClick={() =>
                      Number(inputCompartimentos) <= 8
                        ? handleRemoveCompart(Number(inputCompartimentos) - 1)
                        : null
                    }
                  >
                    <FiMinusCircle size={22} />
                  </button>
                </div>
              </Grid>
            </>
          )}
        </Grid>

        {/* Grid */}
        {editingVeiculos.tipoveiculo === 2 ||
        editingVeiculos.tipoveiculo === 4 ? (
          <>
            <h5
              style={{
                marginTop: '0.5rem',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                marginBottom: '0',
              }}
            >
              Compartimentos
            </h5>

            {/* Compartimentos Grid */}
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              columns={7}
              columnSpacing={1}
              rowSpacing={1}
              maxWidth="760px"
              width="100%"
              // mt={1}
            >
              {compartData.map(veiCompart => (
                <>
                  <Grid item key={`comapart-${veiCompart.compartimento}`}>
                    <MaskedInput
                      {...register(
                        `compart[${
                          veiCompart.compartimento - 1
                        }].compartimento` as keyof IFormData,
                        {
                          required: false,
                        },
                      )}
                      control={control}
                      type="none"
                      name={`compart[${
                        veiCompart.compartimento - 1
                      }].compartimento`}
                      label="Compart."
                      onBlur={() => {}}
                      // error={!!errors.compartimentos}
                      autoComplete="off"
                      sx={compartTableStyles}
                    />
                  </Grid>
                  <Grid item>
                    <MaskedInput
                      {...register(
                        `compart[${
                          veiCompart.compartimento - 1
                        }].volume` as keyof IFormData,
                        {
                          required: false,
                        },
                      )}
                      control={control}
                      type="none"
                      name={`compart[${veiCompart.compartimento - 1}].volume`}
                      label="Vol."
                      onBlur={() => {}}
                      // error={
                      //   errors.compart[$veiCompart.compartimento - 1].volume
                      //     ? !!errors.compart[$veiCompart.compartimento - 1]
                      //         .volume
                      //     : false
                      // }
                      autoComplete="off"
                      sx={compartTableStyles}
                    />
                  </Grid>
                  <Grid item>
                    <MaskedInput
                      {...register(
                        `compart[${
                          veiCompart.compartimento - 1
                        }].seta1` as keyof IFormData,
                        {
                          required: false,
                        },
                      )}
                      control={control}
                      type="none"
                      name={`compart[${veiCompart.compartimento - 1}].seta1`}
                      label="Seta 1"
                      onBlur={() => {}}
                      // error={
                      //   errors.compart[$veiCompart.compartimento - 1].volume
                      //     ? !!errors.compart[$veiCompart.compartimento - 1]
                      //         .volume
                      //     : false
                      // }
                      autoComplete="off"
                      sx={compartTableStyles}
                    />
                  </Grid>
                  <Grid item>
                    <MaskedInput
                      {...register(
                        `compart[${
                          veiCompart.compartimento - 1
                        }].seta2` as keyof IFormData,
                        {
                          required: false,
                        },
                      )}
                      control={control}
                      type="none"
                      name={`compart[${veiCompart.compartimento - 1}].seta2`}
                      label="Seta 2"
                      onBlur={() => {}}
                      // error={
                      //   errors.compart[$veiCompart.compartimento - 1].volume
                      //     ? !!errors.compart[$veiCompart.compartimento - 1]
                      //         .volume
                      //     : false
                      // }
                      autoComplete="off"
                      sx={compartTableStyles}
                    />
                  </Grid>
                  <Grid item>
                    <MaskedInput
                      {...register(
                        `compart[${
                          veiCompart.compartimento - 1
                        }].seta3` as keyof IFormData,
                        {
                          required: false,
                        },
                      )}
                      control={control}
                      type="none"
                      name={`compart[${veiCompart.compartimento - 1}].seta3`}
                      label="Seta 3"
                      onBlur={() => {}}
                      // error={
                      //   errors.compart[$veiCompart.compartimento - 1].volume
                      //     ? !!errors.compart[$veiCompart.compartimento - 1]
                      //         .volume
                      //     : false
                      // }
                      autoComplete="off"
                      sx={compartTableStyles}
                    />
                  </Grid>
                  <Grid item>
                    <MaskedInput
                      {...register(
                        `compart[${
                          veiCompart.compartimento - 1
                        }].seta4` as keyof IFormData,
                        {
                          required: false,
                        },
                      )}
                      control={control}
                      type="none"
                      name={`compart[${veiCompart.compartimento - 1}].seta4`}
                      label="Seta 4"
                      onBlur={() => {}}
                      // error={
                      //   errors.compart[$veiCompart.compartimento - 1].volume
                      //     ? !!errors.compart[$veiCompart.compartimento - 1]
                      //         .volume
                      //     : false
                      // }
                      autoComplete="off"
                      sx={compartTableStyles}
                    />
                  </Grid>
                  <Grid item>
                    <MaskedInput
                      {...register(
                        `compart[${
                          veiCompart.compartimento - 1
                        }].seta5` as keyof IFormData,
                        {
                          required: false,
                        },
                      )}
                      control={control}
                      type="none"
                      name={`compart[${veiCompart.compartimento - 1}].seta5`}
                      label="Seta 5"
                      onBlur={() => {}}
                      // error={
                      //   errors.compart[$veiCompart.compartimento - 1].volume
                      //     ? !!errors.compart[$veiCompart.compartimento - 1]
                      //         .volume
                      //     : false
                      // }
                      autoComplete="off"
                      sx={compartTableStyles}
                    />
                  </Grid>
                </>
              ))}
            </Grid>
          </>
        ) : null}

        <ButtonGroup
          style={{ justifyContent: 'space-around', paddingBottom: '5px' }}
        >
          <button type="submit" name="SaveButton">
            <div className="text">Salvar</div>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>

          <button
            type="button"
            name="CancelButton"
            className="CancelButton"
            onClick={closeModal}
          >
            <div className="text">Cancelar</div>
            <div className="icon">
              <RiCloseCircleLine size={24} />
            </div>
          </button>
        </ButtonGroup>
      </form>
    </Modal>
  );
}

export default ModalEditVeiculos;
