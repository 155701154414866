/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

interface ProtectedRouteProps {
  requirement: string;
  redirectPath: string;
  children?: any;
}

const ProtectedRoute = ({
  requirement,
  redirectPath,
  children,
}: ProtectedRouteProps) => {
  const user = useAuth();

  const isTransportadora = user && user.user.transportadora;
  const isAdmin = user && user.user.admin;
  const isLogistica = user && user.user.logistica;
  const isRh = user && user.user.rh;
  const isFaturamento = user && user.user.faturamento;
  const isLaboratorio = user && user.user.laboratorio;

  if (requirement === 'transportadora' && !isTransportadora) {
    return <Navigate to={redirectPath} replace />;
  }

  if (requirement === 'admin' && !isAdmin) {
    return <Navigate to={redirectPath} replace />;
  }

  if (requirement === 'logistica' && !isLogistica) {
    return <Navigate to={redirectPath} replace />;
  }

  if (requirement === 'rh' && !isRh) {
    return <Navigate to={redirectPath} replace />;
  }

  if (requirement === 'faturamento' && !isFaturamento) {
    return <Navigate to={redirectPath} replace />;
  }

  if (requirement === 'laboratorio' && !isLaboratorio) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
