import React, { ReactElement, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { FiEdit, FiEye, FiTrash2 } from 'react-icons/fi';

import { FaPlus } from 'react-icons/fa';
import { Container, TableContainer } from './styles';
import api from '../../services/api';
import ModalEditJobs from '../../components/ModalEditJobs';
import ModalViewJobs from '../../components/ModalViewJobs';
import ModalInsert from '../../components/ModalInsert';
import { useAuth } from '../../hooks/auth';

interface Jobs {
  id: string;
  jobid: number;
  jobtitle: string;
  shift: string;
  education: string;
  contract: string;
  description: string;
  open: boolean;
}

function AdminCli(): ReactElement {
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const [jobs, setJobs] = useState<Jobs[]>([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [insertModalOpen, setInsertModalOpen] = useState(false);

  const [editingJobs, setEditingJobs] = useState<Jobs>({} as Jobs);

  const [viewingJobs, setViewingJobs] = useState<Jobs>({} as Jobs);

  useEffect(() => {
    async function loadJobs(): Promise<void> {
      await api.get('/jobs').then(response => {
        setJobs(response.data);
      });
    }
    loadJobs();
  }, []);

  // Function to handle registry deletion
  async function handleDeleteRegistry(id: string): Promise<void> {
    await api.delete(`/jobs/${id}`);

    window.location.reload();
  }

  // Toogle modal open or not
  function toggleModal(): void {
    setModalOpen(!modalOpen);
  }

  // Toogle modal edit
  const toggleEditModal = (): void => {
    setEditModalOpen(!editModalOpen);
  };

  // Toogle modal view
  const toggleViewModal = (): void => {
    setViewModalOpen(!viewModalOpen);
  };

  // Toogle modal insert
  function toogleInsertModal(): void {
    setInsertModalOpen(!insertModalOpen);
  }

  // Handle the sending data to edit registries
  function handleEditJobs(jobsData: Jobs): void {
    setEditingJobs(jobsData);
    toggleEditModal();
    toggleModal();
  }

  // Handle the view of registries
  function handleViewJobs(jobsData: Jobs): void {
    setViewingJobs(jobsData);
    toggleViewModal();
    toggleModal();
  }

  // Handle update registry
  async function handleUpdateJobs(jobsData: Jobs): Promise<void> {
    const { jobid } = editingJobs;

    const formatOpen = String(jobsData.open) === 'Aberta';

    const formattedData = {
      ...jobsData,
      open: formatOpen,
    };

    const updateJobs = await api.put(`/jobs/${jobid}`, formattedData);

    setJobs([...jobs, updateJobs.data]);

    window.location.reload();
  }

  return (
    <Container>
      <header>
        <h1>Bom Sucesso Agroindústria S.A</h1>

        <nav>
          <button type="submit" onClick={() => navigate('/')}>
            Página Inicial
          </button>

          <button type="submit" onClick={() => navigate('/admin')}>
            Voltar
          </button>

          <button type="submit" onClick={signOut}>
            Logout
          </button>
        </nav>
      </header>
      <h2 id="pageTitle">Admin RH - Cadastrar nova vaga</h2>

      <button
        id="newJobButton"
        type="submit"
        onClick={() => toogleInsertModal()}
      >
        <FaPlus size={20} style={{ float: 'left' }} />
        Cadastrar Vaga
      </button>

      <ModalEditJobs
        isOpen={editModalOpen}
        setIsOpen={toggleEditModal}
        editingJobs={editingJobs}
        handleUpdateJobs={() => handleUpdateJobs}
      />

      <ModalViewJobs
        isOpen={viewModalOpen}
        setIsOpen={toggleViewModal}
        editingJobs={viewingJobs}
        handleUpdateJobs={() => handleUpdateJobs}
      />

      <ModalInsert
        isOpen={insertModalOpen}
        setIsOpen={() => toogleInsertModal()}
      />

      {/* Table */}
      <TableContainer>
        <table id="tableDataRegistries">
          {/* This header are being used for displaying header on the report */}
          <thead className="headTable">
            <tr>
              <th>ID</th>
              <th>Título</th>
              <th>Turno</th>
              <th>Formação</th>
              <th>Contrato</th>
              <th>Descrição</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>

          {jobs.map(jobsData => (
            <tbody key={jobsData.id}>
              <tr>
                <td className="jobId">{jobsData.jobid}</td>
                <td className="jobTitle">{jobsData.jobtitle}</td>
                <td>{jobsData.shift}</td>
                <td>{jobsData.education}</td>
                <td>{jobsData.contract}</td>
                <td className="jobDescription">{jobsData.description}</td>
                <td>{jobsData.open === true ? 'Aberta' : 'Fechada'}</td>

                <td className="jobButtons">
                  {/* View registry button */}
                  <button
                    id="viewButton"
                    type="button"
                    data-toogle="tooltip"
                    data-placement="top"
                    title="Visualizar"
                    onClick={() => handleViewJobs(jobsData)}
                  >
                    <FiEye size={20} color="#0352fc" />
                  </button>

                  {/* Edit registry button */}
                  <button
                    type="button"
                    data-toogle="tooltip"
                    data-placement="top"
                    title="Editar"
                    onClick={() => handleEditJobs(jobsData)}
                  >
                    <FiEdit size={20} color="#08a818" />{' '}
                  </button>

                  {/* Delete registry button */}
                  <button
                    type="button"
                    data-toogle="tooltip"
                    data-placement="top"
                    title="Deletar"
                    onClick={() => handleDeleteRegistry(jobsData.id)}
                  >
                    <FiTrash2 size={20} color="#c2180c" />{' '}
                  </button>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </TableContainer>
    </Container>
  );
}

export default AdminCli;
