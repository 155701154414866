/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';

import { FiCheckSquare } from 'react-icons/fi';
import { ButtonGroup } from '../../styles/customButtonGroup';

import Modal from '../Modal';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MaskedInput } from '../Material/MaskedInput';
import MuiSelect from '../Material/Select';
import { formatDateBr } from '../../utils/formatDate';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  filaData: any;
}

interface IFormData {
  data: Date | string;
  agendamentoid: number;
  placa: string;
  status: string;
  motivo: string;
}

const ModalChangeStatusFaturamento = React.forwardRef<
  HTMLInputElement,
  IModalProps
>(({ isOpen, setIsOpen, filaData }, ref) => {
  const { addToast } = useToast();

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormData>();

  useEffect(() => {
    if (filaData) {
      setValue('data', formatDateBr(filaData.data));
      setValue('agendamentoid', filaData.agendamentoid);
      setValue('placa', filaData.placa);
    }
  }, [filaData]);

  const selectOptions = [
    '1 - Aguardando Documentos Transportadora',
    '2 - Nota Fiscal Liberada para Coleta',
  ];

  const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
    // Formatting the data
    const toFormatString = String(data.data);
    const day = toFormatString.substring(0, 2);
    const month = toFormatString.substring(3, 5);
    const year = toFormatString.substring(6, 10);

    try {
      await api.post('/fila/status', {
        data: `${year}-${month}-${day}`,
        agendamentoid: data.agendamentoid,
        placa: data.placa.toUpperCase(),
        statusnew:
          data.status === '1 - Aguardando Documentos Transportadora'
            ? 'ADOC'
            : data.status === '2 - Nota Fiscal Liberada para Coleta'
            ? 'NLIB'
            : null,
        motivo:
          data.status === '1 - Aguardando Documentos Transportadora'
            ? 'Nota Fiscal Faturada, aguardando docs transportadora'
            : data.status === '2 - Nota Fiscal Liberada para Coleta'
            ? 'Documentos Ok, nota fiscal liberada para coleta'
            : 'Error',
      });

      // Close the modal
      setIsOpen();

      // Show Toast for success
      addToast({
        type: 'success',
        title: 'Status Atualizado',
        description: `O registro foi inserido com sucesso!`,
      });

      // reset Form
      reset();
    } catch (err: any) {
      // Close the modal
      setIsOpen();

      // Show Toast for failure
      addToast({
        type: 'error',
        title: 'Erro ao mudar o status',
        // description: 'Verifique se os dados informados estão corretos.',
        description: `${err.response.data.message}`,
      });

      // reset Form
      reset();
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
          gap: '1rem',
        }}
      >
        <h1
          style={{
            fontWeight: '500',
            fontSize: '36px',
            lineHeight: '36px',
            marginBottom: '40px',
            color: '#00a859',
            textAlign: 'center',
          }}
        >
          Alterar Status Faturamento
        </h1>

        <MaskedInput
          {...register('data', { required: 'Data obrigatória.' })}
          control={control}
          type="date"
          name="data"
          label="Data"
          onBlur={() => {}}
          error={!!errors.data}
          autoComplete="off"
          sx={{ width: '400px' }}
        />

        <MaskedInput
          {...register('agendamentoid', {
            required: 'Agendamento id obrigatório.',
          })}
          control={control}
          type="none"
          name="agendamentoid"
          label="Agendamento ID"
          onBlur={() => {}}
          error={!!errors.agendamentoid}
          autoComplete="off"
          sx={{ width: '400px' }}
        />

        <MaskedInput
          {...register('placa', { required: 'Placa obrigatória.' })}
          control={control}
          type="placa"
          name="placa"
          label="Placa"
          onBlur={() => {}}
          error={!!errors.placa}
          autoComplete="off"
          sx={{ width: '400px' }}
        />

        <MuiSelect
          {...register(
            'status',

            { required: 'Status obrigatório.' },
          )}
          control={control}
          label="Status"
          name="status"
          options={selectOptions}
          variant="outlined"
          sx={{ width: '400px' }}
          error={!!errors.status}
          ref={ref}
        />

        <ButtonGroup>
          <button type="submit" name="SaveButton">
            <div className="text">Salvar</div>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>
        </ButtonGroup>
      </form>
    </Modal>
  );
});

ModalChangeStatusFaturamento.displayName = 'ModalChangeStatusFaturamento';

export default ModalChangeStatusFaturamento;
