/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useCallback, useState, ReactElement } from 'react';

import { FiCheckSquare } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FiAward, FiClock, FiFileText, FiInfo } from 'react-icons/fi';
import { FaFileContract } from 'react-icons/fa';
import { AiOutlineSelect } from 'react-icons/ai';
import { Form, ButtonGroup } from './styles';

import getValidationErrors from '../../utils/getValidationErrors';

import Modal from '../Modal';
import Input from '../Input';
import Select from '../Select';
import TextAreaMessage from '../TextArea';

import api from '../../services/api';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

interface IInsertData {
  jobTitle: string;
  shift: string;
  education: string;
  contract: string;
  description: string;
  open: boolean;
}

function ModalInsert({ isOpen, setIsOpen }: IModalProps): ReactElement {
  const formRef = useRef<FormHandles>(null);

  const selectOptions = ['Status (Aberta/Fechada)', 'Aberta', 'Fechada'];

  const [selectedOption, setSelectedOption] = useState(selectOptions[0]);

  const handleSubmit = useCallback(async (data: IInsertData) => {
    try {
      // eslint-disable-next-line no-unused-expressions
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        jobTitle: Yup.string().required('Título é obrigatório'),
        shift: Yup.string().required('Turno é obrigatório'),
        education: Yup.string().required('Formação é obrigatória'),
        contract: Yup.string().required('Tipo de contrato é obrigatório'),
        description: Yup.string().required('Descrição da Vaga é obrigatória'),
        open: Yup.string()
          .oneOf(['Aberta', 'Fechada'])
          .required('Selecione um status (Aberta ou Fechada)'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('/jobs', {
        jobTitle: data.jobTitle,
        shift: data.shift,
        education: data.education,
        contract: data.contract,
        description: data.description,
        open: data.open,
      });

      window.location.reload();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        // eslint-disable-next-line no-unused-expressions
        formRef.current?.setErrors(errors);
      }
    }
  }, []);

  function handleSelect(data: string) {
    setSelectedOption(data);
  }

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <h1>Criar nova vaga</h1>

        <Input name="jobTitle" icon={FiFileText} placeholder="Título" />
        <Input name="shift" icon={FiClock} placeholder="Turno" />

        <Input name="education" icon={FiAward} placeholder="Formação" />
        <Input
          name="contract"
          icon={FaFileContract}
          placeholder="Contrato (CLT/SAFRA)"
        />

        <TextAreaMessage
          name="description"
          icon={FiInfo}
          placeholder="Descrição da Vaga"
          cols={10}
          rows={5}
        />

        <Select
          name="open"
          icon={AiOutlineSelect}
          style={{
            color:
              selectedOption !== 'Status (Aberta/Fechada)' ? '#fff' : '#c2c0ba',
          }}
          value={String(selectedOption)}
          onChange={e => handleSelect(e.target.value)}
        >
          {selectOptions.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>

        <ButtonGroup>
          <button type="submit" name="SaveButton">
            <div className="text">Salvar</div>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>
        </ButtonGroup>
      </Form>
    </Modal>
  );
}

export default ModalInsert;
