import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: block;
  height: 100vmin !important;
  background: #fff;
  position: absolute;

  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 10px;
    z-index: 99;
    position: relative;

    h1 {
      color: #00a859;
      font-weight: 600;
    }

    button {
      border: 1px solid #00a859;
      background-color: transparent;
      border-radius: 4px;
      color: #171412;
      font-weight: 600;
      font-family: 'Poppins';
      height: 40px;
      padding: 8px;
    }
    > button {
      margin-left: 10px;
    }

    & :hover {
      cursor: pointer;
      color: #00a859;
    }
  } /* END HEADER */

  #pageTitle {
    justify-content: center;
    text-align: center;
    color: #00a859;
    font-weight: 600;
    margin: 10px auto;

    :after {
      display: block;
      text-align: center;
      margin: 0 auto;
      content: '';
      width: 20%;
      border-bottom: 3px solid #00a859;
      animation: 1s ease-in 0s 1 slideInFromLeft;
    }

    @keyframes slideInFromLeft {
      0% {
        transform: translateX(-40%);
      }
      100% {
        transform: translateX(0);
      }
    }
  }

  .aboutUsText {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40%;
    position: relative;

    p {
      font-family: 'Poppins';
      text-align: justify;

      overflow: auto;
      width: 50%;
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .logosHolder {
    display: flex;
    flex: 1;
    width: 100%;
    height: 45%;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    bottom: 0;

    img {
      object-fit: contain;
    }
  }

  .companyValues {
    display: flex;
    justify-content: space-between;
    width: auto;
    margin-top: 8%;
    position: relative;
    margin-left: 1rem;
    margin-right: 1rem;

    .card {
      width: 29%;
      border: 1px solid #00a859;
      bottom: 20px;

      .cardItems {
        margin: 10px auto;
        text-align: center;
        height: 150px;
        width: 100%;
        padding: 0;

        #type {
          color: #00a859;
          font-weight: 600;
          font-size: 1.6rem;
          padding: 0;
          margin: 0;
          height: auto;
        }

        #description {
          color: #171412;
          font-weight: 500;
          font-size: 0.9rem;
          padding: 0;
          margin: 0;
          height: auto;
          margin-top: 1rem;
          text-align: center;
          justify-content: center;
          display: flex;
        }
      }

      & > .card {
      }
    }
  } /* End Company Values */

  .workWithUs {
    position: relative;
    margin-top: 2rem;
    width: 100%;
    flex: 1;
    bottom: 0;
    background: #171412;

    h2 {
      padding-top: 10px;
      font-weight: 600;
      color: #00a859;
      text-align: center;
    }

    .buttonHolder {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80%;

      a {
        font-family: 'Poppins';
        text-align: center;
        text-decoration: none;
        color: #fff;
        font-weight: 600;
        outline: none;
        border: transparent;
        background: #00a859;
        width: 250px;
        height: 40px;
        border-radius: 5px;
        margin-top: 2rem;
        margin-bottom: 2rem;
        justify-content: center;
        align-items: center;
        display: flex;

        &:hover {
          background: ${shade(0.3, '#00A859')};
        }
      }
    }
  }
`;
