import { Localization, ptBR } from '@mui/material/locale';
import {
  PaletteOptions,
  ThemeOptions,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles';
import { ptBR as datePickerPtBR } from '@mui/x-date-pickers';

const palette: PaletteOptions = {
  primary: {
    main: '#00a859',
  },
  secondary: {
    main: '#9de7d7',
  },
  tertiary: {
    main: '#c2c0ba',
  },
  accents: {
    red: {
      main: '#c53030',
    },
    black: {
      main: '#272727',
    },
  },
  neutral: {
    black: '#000000',
    white: '#FFFFFF',
    veryDarkGrey: '#555552',
    darkGrey: '#959593',
    midGrey: '#B5B5B2',
    grey: '#D9D9D6',
    lightGrey: '#F2F2EF',
    veryLightGrey: '#F9F9F6',
  },
  outlines: {
    midGrey: '#B5B5B2',
    grey: '#D9D9D6',
    lightGrey: '#F2F2EF',
  },
  success: {
    main: '#008060',
  },
  error: {
    main: '#BA1A1A',
  },
  text: {
    primary: '#151515',
    secondary: '#555552',
    disabled: '#959593',
  },
  textExtended: {
    default: '#000000',
    tertiary: '#959593',
    error: '#BA1A1A',
    success: '#008060',
  },
};

const themeOptions: ThemeOptions = {
  palette,
  typography: {
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: 40,
      fontFamily: 'Poppins',
      fontWeight: 500,
      // Because we use `responsiveFontSizes`, line height must be unitless, otherwise it throws this error:
      // Unsupported non-unitless line height with grid alignment. Use unitless line heights instead.
      lineHeight: '1.2',
      // This line height comes from Figma but is to huge
      // lineHeight: 48,
      letterSpacing: 0.4,
      wordWrap: 'break-word',
    },
    h2: {
      fontSize: 32,
      fontFamily: 'Poppins',
      fontWeight: 500,
      lineHeight: '1.0625',
      // This line height comes from Figma but is to huge
      // lineHeight: 34,
      letterSpacing: 0.32,
      wordWrap: 'break-word',
    },
    h3: {
      fontSize: 24,
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '1.333',
      // This line height comes from Figma but is to huge
      // lineHeight: 32,
      letterSpacing: 0.24,
      wordWrap: 'break-word',
    },
    h4: {
      fontFamily: 'Poppins',
      fontSize: 20,
      fontWeight: 500,
      lineHeight: '1.1',
      // This line height comes from Figma but is to huge
      // lineHeight: 22,
      letterSpacing: 0.4,
      wordWrap: 'break-word',
    },
    h5: {
      fontFamily: 'Poppins',
      fontSize: 18,
      fontWeight: 600,
      lineHeight: '1.111',
      // This line height comes from Figma but is to huge
      // lineHeight: 20,
      letterSpacing: 0.18,
      // wordWrap: 'break-word',
    },
    h6: {
      fontFamily: 'Poppins',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '1.142',
      // This line height comes from Figma but is to huge
      // lineHeight: 16,
      letterSpacing: 0.28,
      wordWrap: 'break-word',
    },
    titleLarge: {
      fontFamily: 'Poppins',
      fontSize: 22,
      fontWeight: 500,
      lineHeight: '1.142',
      // This line height comes from Figma but is to huge
      // lineHeight: 26,
      letterSpacing: 0.28,
      wordWrap: 'break-word',
    },
    titleMedium: {
      fontFamily: 'Poppins',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '1.142',
      // This line height comes from Figma but is to huge
      // lineHeight: 20,
      letterSpacing: 0.16,
      wordWrap: 'break-word',
    },
    titleSmall: {
      fontFamily: 'Poppins',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '1.142',
      // This line height comes from Figma but is to huge
      // lineHeight: 20,
      letterSpacing: 0.28,
      wordWrap: 'break-word',
    },
    logo1: {
      fontFamily: 'Ivar Fine',
      fontSize: '3.75rem',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '1',
    },
    logo2: {
      fontFamily: 'Ivar Fine',
      fontSize: '1.75rem',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '1.114286',
    },
    logo3: {
      fontFamily: 'Ivar Fine',
      fontSize: '1.25rem',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '1.2',
    },
    labelLarge: {
      fontFamily: 'Poppins',
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '1.33333',
      // This line height comes from Figma but is to huge
      // lineHeight: 20,
      letterSpacing: 0.18,
      wordWrap: 'break-word',
    },
    labelMedium: {
      fontFamily: 'Poppins',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '1.33333',
      // This line height comes from Figma but is to huge
      // lineHeight: 20,
      letterSpacing: 0.8,
      wordWrap: 'break-word',
    },
    labelSmall: {
      fontFamily: 'Poppins',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '1.33333',
      // This line height comes from Figma but is to huge
      // lineHeight: 16,
      letterSpacing: '0.0375rem',
      wordWrap: 'break-word',
      color: palette.neutral.veryDarkGrey,
    },
    bodyXLarge: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: '1.33333',
      // This line height comes from Figma but is to huge
      // lineHeight: 24,
      wordWrap: 'break-word',
      letterSpacing: 0,
    },
    bodyLarge: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '1.33333',
      // This line height comes from Figma but is to huge
      // lineHeight: 22,
      letterSpacing: 0,
      wordWrap: 'break-word',
    },
    bodyMedium: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '1.33333',
      // This line height comes from Figma but is to huge
      // lineHeight: 20,
      letterSpacing: 0,
      wordWrap: 'break-word',
    },
    bodySmall: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '1.33333',
      // This line height comes from Figma but is to huge
      // lineHeight: 16,
      letterSpacing: 0,
      wordWrap: 'break-word',
    },
    bodyXSmall: {
      fontSize: 10,
      fontWeight: 400,
      lineHeight: '1.33333',
      // This line height comes from Figma but is to huge
      // lineHeight: 14,
      letterSpacing: 0,
      wordWrap: 'break-word',
    },
    button: {
      textTransform: 'none',
      outline: 'none',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          logo1: 'h1',
          logo2: 'h2',
          logo3: 'h3',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': { color: palette.neutral.veryDarkGrey },
          '&.Mui-focused': { borderColor: 'red' },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { '&.Mui-disabled': { color: palette.neutral.veryDarkGrey } },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: { '&.Mui-disabled': { color: palette.neutral.veryDarkGrey } },
      },
    },
    MuiButton: {
      styleOverrides: {
        // see https://mui.com/material-ui/api/button/#css for all the available class names
        root: {
          borderRadius: 28,
          textTransform: 'none',
          fontWeight: 700,
        },
        outlined: {
          ':hover': {
            backgroundColor: 'rgb(176, 235, 223, 0.5)', // palette.secondary.light
            borderColor: palette.neutral.grey,
          },
          ':focus': {
            backgroundColor: palette.neutral.grey,
            borderColor: '#004045', // palette.primary.main
          },
        },
        text: {
          ':hover': {
            backgroundColor: 'rgb(176, 235, 223, 0.5)', // theme.palette.secondary.light
          },
          ':focus': {
            backgroundColor: palette.neutral.grey,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontWeight: 'normal',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          textTransform: 'capitalize',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'tertiary',
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'tertiary',
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: '1rem',
        },
      },
    },
    MuiStepper: {
      defaultProps: {
        alternativeLabel: true,
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: '#000000',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'tertiary',
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: palette.tertiary[20],
            backgroundColor: palette.tertiary[20],
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          background: palette.secondary?.[50],
          color: '#004045', //palette.primary.main
          fontSize: '1.125rem', //18px
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '1.25rem', //20px
          letterSpacing: '0.0112rem', //0.18px
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff', //palette.neutral.white
          boxShadow: 'none',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          maxWidth: '100%',
          padding: '0.5rem 1rem',
          justifyContent: 'flex-start',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: palette.textExtended.default,
          },
          '&.Mui-selected .MuiTab-iconWrapper': {
            color: palette.tertiary.main,
          },
          display: 'flex',
          padding: '0rem 0.25rem 0.5rem 0.25rem',
          alignItems: 'center',
          gap: '0.375rem',
          fontSize: '1.125rem',
          fontWeight: '500',
          lineHeight: '1.25rem',
          letterSpacing: '0.01125rem',
          minWidth: 'unset',
          minHeight: 'unset',
          textTransform: 'capitalize',
        },
        labelIcon: {
          '.MuiTab-iconWrapper': {
            width: '1rem',
            height: '1rem',
            margin: '0',
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          overflowX: 'initial', // Allows scrolling with sticky header
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderSpacing: '0 1rem', // Spacing between items vertically
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '.MuiTableCell-head': {
            fontWeight: '500',
            fontSize: '0.75rem',
            border: 'none',
            padding: '0',
          },
          '.MuiTableCell-head:first-of-type': {
            paddingLeft: '1rem',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '.MuiTableCell-body': {
            padding: '0.5rem 0',
            border: `1px solid ${palette.neutral.grey}`,
            borderLeft: 'none',
            borderRight: 'none',
          },
          '.MuiTableCell-body:first-of-type': {
            padding: '0.5rem 0 0.5rem 1rem',
            borderTopLeftRadius: '0.25rem',
            borderBottomLeftRadius: '0.25rem',
            borderLeft: `1px solid ${palette.neutral.grey}`,
          },
          '.MuiTableCell-body:last-of-type': {
            padding: '0.5rem 1rem 0.5rem 0',
            borderTopRightRadius: '0.25rem',
            borderBottomRightRadius: '0.25rem',
            borderRight: `1px solid ${palette.neutral.grey}`,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: '24.375rem',
          padding: '1.5rem',
          backgroundColor: '#FFFFFF',
          border: 'none',
          borderRadius: '0.5rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1.38rem',
          boxShadow:
            '0px 8px 20px -4px rgba(23, 24, 24, 0.12), 0px 3px 6px -3px rgba(23, 24, 24, 0.08)',
        },
        root: {
          '.MuiDialogTitle-root': {
            fontSize: '1.375rem',
            fontWeight: '500',
            lineHeight: '1.625rem' /* 118.182% */,
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '.MuiIconButton-root': {
              color: palette.neutral.black,
            },
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontSize: '1.125rem',
          fontWeight: 400,
          lineHeight: '1.375rem' /* 122.222% */,
          color: palette.text?.secondary,
          leadingTrim: 'both',
          textEdge: 'cap',
          padding: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
};

export const getLocaleTheme = () => {
  const themeLocale: Localization = ptBR;
  const datePickerLocale = datePickerPtBR;

  // see https://mui.com/material-ui/customization/theming/
  // and https://mui.com/material-ui/customization/typography/#responsive-font-sizes
  const theme = responsiveFontSizes(
    createTheme(themeOptions, datePickerLocale, themeLocale),
  );

  return theme;
};
