/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  ReactElement,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import { useField } from '@unform/core';

import { Container, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLSelectElement> {
  // overwrite the requirement of having a name in input, which was not required
  name: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  containerStyle?: object;
  // Icon was a custom prop defined in index.tsx SignIn
  icon?: React.ComponentType<IconBaseProps>;
}

function Select({
  name,
  containerStyle = {},
  icon: Icon,
  ...rest
}: InputProps): ReactElement {
  const selectRef = useRef<HTMLSelectElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!selectRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container
      style={containerStyle}
      iserrored={!!error}
      isfilled={isFilled}
      isfocused={isFocused}
      data-testid="select-container"
    >
      {Icon && <Icon size={19} />}
      <select
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={selectRef}
        {...rest}
      />
      {/* If the error exists than show the icon for the error */}
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={18} />
        </Error>
      )}
    </Container>
  );
}

export default Select;

// Select.defaultProps = {
//   containerStyle: {},
//   icon: React.Component,
// };
