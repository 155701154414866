/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';
import 'react-day-picker/dist/style.css';

import 'moment/locale/pt-br';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

import { Container, TableContainer } from './styles';
import api from '../../services/api';

import {
  Box,
  ButtonGroup,
  CircularProgress,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import { MaskedInput } from '../../components/Material/MaskedInput';
import CustomLoadingButton from '../../components/Material/CustomLoadingButton';
import { useForm } from 'react-hook-form';
import { RiAddCircleFill } from 'react-icons/ri';
import { FiSave, FiTrash2 } from 'react-icons/fi';
import ModalVinculoPedidosComVolume from '../../components/Modals/ModalVinculoPedidosComVolume';
import { useToast } from '../../hooks/toast';

interface IFormData {
  pedido: number;
  transportadora: string;
  volume: number;
  tipo: string;
  desvincular: string;
  saldoPedido: number;
}

export type SaldoProps = {
  pedido: number;
  date: string;
  orderType: string;
  material: number;
  unidadeControle: string;
  quantidade: number;
  quantidadeFaturada: number;
  saldoOrder: number;
  volumeAgendado: number;
  saldoCalculado: number;
  status: string;
  cliente: {
    businessPartner: string;
    cnpj: string;
    name: string;
    uf: string;
  };
  refPedidoSca: string;
};

type VinculoPedido = {
  pedido: number;
  transportadora: string;
  tipo: string;
  volume: number;
  ativo: boolean;
};

export type VinculosPedidosProps = {
  pedido: number;
  saldoPedido: number;
  volumeVinculado: number;
  volumeVincular: number;
  vinculos: VinculoPedido[];
};

function PainelVinculoPedidos(): ReactElement {
  const { addToast } = useToast();
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  if (!theme) {
    throw new Error('Theme not found');
  }

  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useForm<IFormData>();

  const [loading, setLoading] = useState(false);
  const [loadingVinculos, setLoadingVinculos] = useState(false);

  const [saldoPedidos, setSaldoPedidos] = useState<SaldoProps[]>([]);
  const [vinculosPedido, setVinculoPedidos] = useState<VinculosPedidosProps>();
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);

  const [updatedVolumes, setUpdatedVolumes] = useState<{
    [key: string]: number;
  }>({});

  const [modalVinculoPedidosModalOpen, setModalVinculoPedidosModalOpen] =
    useState(false);

  // Toogle modal Ordem Contingencia
  async function toogleModalVinculoPedidos(): Promise<void> {
    setModalVinculoPedidosModalOpen(!modalVinculoPedidosModalOpen);
  }

  // Load Saldos Pedidos
  const loadSaldoPedidos = async () => {
    if (isRequestInProgress) return; // Prevent multiple requests
    setIsRequestInProgress(true);
    setLoading(true);
    await api
      .get('/agendamentos/saldoAllPedidos')
      .then(response => {
        setSaldoPedidos(response.data);
        setLoading(false);
        setIsRequestInProgress(false);
      })
      .catch((error: any) => {
        addToast({
          type: 'error',
          title: 'Erro get saldosAllPedidos.',
          description: error,
        });
        setLoading(false);
        setIsRequestInProgress(false);
      });
  };

  useEffect(() => {
    if (saldoPedidos.length === 0) {
      loadSaldoPedidos();
    }
  }, []);

  const handleFilterPedido = () => {
    setVinculoPedidos(undefined);
    const pedido = watch('pedido');

    filterSaldoPedido(pedido);
  };

  const filterSaldoPedido = async (pedido: number) => {
    const saldoPedido = saldoPedidos.filter(
      saldo => saldo.pedido === Number(pedido),
    );

    if (saldoPedido.length === 0) {
      addToast({
        type: 'error',
        title: 'Erro.',
        description: 'Saldo não encontrado para este pedido .',
      });

      return;
    }

    // find vinculos existentes para o pedido
    setLoadingVinculos(true);
    await api
      .get('/vinculopedidos', {
        params: {
          pedido: pedido,
        },
      })
      .then(response => {
        const volumeVinculado = response.data.reduce((total, item) => {
          return total + item.volume;
        }, 0);

        const saldoCalculado = saldoPedido[0].saldoCalculado;

        const volumeAVincular = saldoPedido[0].quantidade - volumeVinculado;

        const vinculoPedido = {
          pedido: pedido,
          saldoPedido: saldoCalculado,
          volumeVinculado: Number(volumeVinculado),
          volumeVincular: volumeAVincular,
          vinculos: [...response.data] as VinculoPedido[],
        };

        setVinculoPedidos(vinculoPedido as VinculosPedidosProps);
        setLoadingVinculos(false);
      })
      .catch((error: any) => {
        addToast({
          type: 'error',
          title: 'Erro get vinculoPedidos.',
          description: error,
        });
        setLoadingVinculos(false);
      });

    return saldoPedido;
  };

  const handleUpdateVinculo = async ({
    pedido,
    transportadora,
    volume,
    tipo,
  }: VinculoPedido) => {
    const oldVolume = vinculosPedido?.vinculos.find(
      vinculo => vinculo.transportadora === transportadora,
    )?.volume;

    if (!oldVolume) {
      addToast({
        type: 'error',
        title: 'Erro update vinculoPedidos.',
        description: 'Quantidade inicial não encontrada, favor atualizar.',
      });
      return;
    }

    const totalAVincular = volume - oldVolume;

    if (vinculosPedido && totalAVincular > vinculosPedido?.volumeVincular) {
      addToast({
        type: 'error',
        title: 'Erro update vinculoPedidos.',
        description: 'Quantidade maior que volume disponivel.',
      });
      return;
    }

    // Update vinculo
    await api
      .put('/vinculopedidos', {
        pedido: pedido,
        transportadora: transportadora,
        volume: volume,
        tipo: tipo,
      })
      .then(async () => {
        setUpdatedVolumes(prev => ({
          ...prev,
          [transportadora]: volume,
        }));

        await loadSaldoPedidos();
        filterSaldoPedido(pedido);
        handleFilterPedido();

        setUpdatedVolumes(prev => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [transportadora]: _, ...rest } = prev; // `_` ignores the value of the removed entry
          return rest;
        });
      })
      .catch((error: any) => {
        addToast({
          type: 'error',
          title: 'Erro update vinculoPedidos.',
          description: error,
        });
        setLoadingVinculos(false);
      });
  };

  const handleVolumeChange = (transportadora: string, newVolume: string) => {
    const parsedVolume = newVolume === '' ? null : Number(newVolume);

    // If the new volume is null (empty input), remove the entry from updatedVolumes
    if (parsedVolume === null) {
      setUpdatedVolumes(prev => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [transportadora]: _, ...rest } = prev; // `_` ignores the value of the removed entry
        return rest;
      });
    } else {
      // Otherwise, update the volume
      setUpdatedVolumes(prev => ({
        ...prev,
        [transportadora]: parsedVolume, // Update or add the volume for the given pedido
      }));
    }
  };

  const handleDeleteVinculo = async (
    pedido: number,
    transportadora: string,
  ) => {
    // Delete vinculo
    await api
      .delete('/vinculopedidos', {
        params: {
          pedido: pedido,
          transportadora: transportadora,
        },
      })
      .then(async () => {
        setUpdatedVolumes(prev => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [transportadora]: _, ...rest } = prev; // `_` ignores the value of the removed entry
          return rest;
        });

        await loadSaldoPedidos();
        filterSaldoPedido(pedido);
        handleFilterPedido();
      })
      .catch((error: any) => {
        addToast({
          type: 'error',
          title: 'Erro ao deletar vinculo pedido.',
          description: error,
        });
      });
  };

  const handleUpdateData = async () => {
    if (vinculosPedido) {
      // Refresh after modal insert is closed
      await loadSaldoPedidos();
      filterSaldoPedido(vinculosPedido?.pedido);
      handleFilterPedido();
    }
  };

  const pedidoInfoStyles = {
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 1rem',
    width: '100%',
    gap: '1.5rem',
    minHeight: '4rem',
    ...theme.typography.h2,
  };

  return (
    <Container>
      <ModalVinculoPedidosComVolume
        isOpen={modalVinculoPedidosModalOpen}
        setIsOpen={() => toogleModalVinculoPedidos()}
        onClose={handleUpdateData}
      />

      <header>
        <h1>Bom Sucesso Agroindústria S.A</h1>

        <nav>
          <button type="submit" onClick={() => navigate('/')}>
            Página Inicial
          </button>

          <button type="submit" onClick={() => navigate('/painellogistica')}>
            Voltar
          </button>

          <button type="submit" onClick={signOut}>
            Logout
          </button>
        </nav>
      </header>

      <h2 id="pageTitle">Vinculo pedidos</h2>

      {(loading || loadingVinculos) && (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          marginTop="10rem"
        >
          <CircularProgress sx={{ color: '#00a859' }} />
        </Box>
      )}

      {!loading && !loadingVinculos && saldoPedidos.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingX: '10px',
          }}
        >
          <ButtonGroup>
            <CustomLoadingButton
              icon={<RiAddCircleFill size={24} />}
              text="Inserir"
              loading={false}
              onClick={() => toogleModalVinculoPedidos()}
              customStyles={{
                width: '8rem',
                fontWeight: 600,
              }}
            />
          </ButtonGroup>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <MaskedInput
              {...register('pedido', { required: 'Pedido obrigatório.' })}
              control={control}
              type="none"
              name="pedido"
              label="Pedido"
              onBlur={() => {}}
              error={!!errors.pedido}
              autoComplete="off"
              sx={{ width: '237px' }}
            />

            <CustomLoadingButton
              icon={<></>}
              text="Ok"
              loading={false}
              disabled={watch('pedido') === undefined}
              onClick={() => handleFilterPedido()}
              customStyles={{
                width: '4rem',
              }}
            />
          </Box>
        </Box>
      ) : !loading && saldoPedidos.length === 0 ? (
        <h4 style={{ color: '#333' }}>
          Não foram encontrados pedidos com saldo em aberto.
        </h4>
      ) : null}

      {/* Table */}
      {!loadingVinculos && !loading && vinculosPedido && (
        <TableContainer>
          <Box
            sx={{
              boxShadow: 2,
            }}
          >
            <Box
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                boxShadow: 1,
              }}
            >
              <Box
                sx={{
                  ...pedidoInfoStyles,
                }}
              >
                <Typography sx={{ ...theme.typography.h5 }}>
                  Pedido: {vinculosPedido?.pedido}
                </Typography>
                <Typography sx={{ ...theme.typography.h5 }}>
                  Saldo Pedido: {vinculosPedido?.saldoPedido}
                </Typography>

                <Typography sx={{ ...theme.typography.h5 }}>
                  Volume Vinculado: {vinculosPedido?.volumeVinculado}
                </Typography>

                <Typography sx={{ ...theme.typography.h5 }}>
                  Volume Total: {vinculosPedido?.volumeVincular}
                </Typography>
              </Box>
            </Box>
            <Box>
              {!loadingVinculos &&
              !loading &&
              vinculosPedido.vinculos.length > 0 ? (
                <table id="tableVinculosPedido">
                  <thead>
                    <th className="vPedTransp">Transportadora</th>
                    <th className="vPedTipo">Tipo</th>
                    <th className="vPedVolMax">Volume Max.</th>
                    <th className="vPedStatus">Status</th>
                    <th className="vPedActions">Actions</th>
                  </thead>

                  {vinculosPedido.vinculos.map(vinculo => (
                    <tbody key={vinculo.transportadora}>
                      <tr>
                        <td className="vPedTransp">{vinculo.transportadora}</td>
                        <td className="vPedTipo">{vinculo.tipo}</td>

                        <td className="vPedVolMax">
                          <TextField
                            name="volume"
                            variant="standard"
                            size="small"
                            type="number" // Numeric input
                            value={
                              updatedVolumes[vinculo.transportadora] !==
                              undefined
                                ? updatedVolumes[vinculo.transportadora]
                                : vinculo.volume
                            }
                            onChange={(e: any) =>
                              handleVolumeChange(
                                vinculo.transportadora,
                                e.currentTarget.value,
                              )
                            }
                            inputProps={{
                              min: 0, // Ensure the number is non-negative
                              step: 1, // Only allow integer steps (no decimals)
                            }}
                            sx={{
                              width: '100px',
                            }}
                          />
                        </td>
                        <td className="vPedStatus">
                          {vinculo.ativo ? 'Ativo' : 'Inativo'}
                        </td>
                        <td className="vPedActions">
                          <button
                            type="button"
                            onClick={() =>
                              handleUpdateVinculo({
                                ...vinculo,
                                volume: updatedVolumes[vinculo.transportadora],
                              })
                            }
                            disabled={!updatedVolumes[vinculo.transportadora]}
                          >
                            <FiSave
                              size={20}
                              color={
                                !updatedVolumes[vinculo.transportadora]
                                  ? theme.palette.grey[400]
                                  : theme.palette.primary.main
                              }
                            />{' '}
                          </button>

                          <button
                            type="button"
                            onClick={() =>
                              handleDeleteVinculo(
                                vinculo.pedido,
                                vinculo.transportadora,
                              )
                            }
                          >
                            <FiTrash2 size={20} color="#c2180c" />{' '}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              ) : (
                <h4 style={{ color: '#333', margin: 0, padding: '1.5rem 0' }}>
                  Não foram encontrados vinculos para este pedido
                </h4>
              )}
            </Box>
          </Box>
        </TableContainer>
      )}
    </Container>
  );
}

export default PainelVinculoPedidos;
