import React from 'react';
import { createRoot } from 'react-dom/client';
import { setupReactAppOverlayErrorHandler } from 'react-app-error-boundary';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

import { getLocaleTheme } from './styles/theme';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@emotion/react';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);
const localeTheme = getLocaleTheme();

root.render(
  <ThemeProvider theme={localeTheme}>
    <BrowserRouter>
      <React.StrictMode>
        <CssBaseline />
        <App />
      </React.StrictMode>
    </BrowserRouter>
  </ThemeProvider>,
);
setupReactAppOverlayErrorHandler();
