import moment from 'moment-timezone';

export const formatDate = (value: Date): string =>
  Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'GMT',
  }).format(value);

/**
 * @param date string
 * @returns format 'DD/MM/YYYY HH:mm:ss'
 */
export const formatDateTimeBr = (date: string | Date) => {
  return moment(date).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm:ss');
};

/**
 * @param date string
 * @returns format 'DD/MM/YYYY'
 */
export const formatDateBr = (date: string | Date) => {
  return moment(date).tz('America/Sao_Paulo').format('DD/MM/YYYY');
};
